import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'

// COMMON
import backgroundImage from '../../assets/images/genomyou/background.png'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import RightBottomNotification from '../RightBottomNotification/RightBottomNotification'
import Notify from '../Notify/Notify'
import { useAppSelector } from '../../utils/useAppSelector'

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 100px)',
    backgroundColor: '#f3f8f9',
    [theme.breakpoints.up('tablet')]: {
      padding: theme.spacing(4),
      paddingTop: `calc(80px + ${theme.spacing(4)}px)`,
      '& > *': {
        maxWidth: '85%',
        margin: '0 auto'
      }
    }
  },
  genomYouPageWrapper: {
    minHeight: 'calc(100vh - 100px)',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.up('tablet')]: {
      padding: theme.spacing(4),
      paddingTop: `calc(80px + ${theme.spacing(4)}px)`,
      '& > *': {
        maxWidth: '85%',
        margin: '0 auto'
      }
    }
  }
}))

const Layout: React.FC = ({ children }) => {
  const classes = useStyles()
  const isAuth = useAppSelector(state => state.auth.login.isAuth)
  // TODO save admin flag here
  // const isAdmin = useAppSelector(state => state.auth.login.isAdmin)
  const is2faRequired = useAppSelector(state => state.auth.login.is2faRequired)

  return (
    <>
      {isAuth && !is2faRequired ? (
        <>
          {process.env.REACT_APP_GENOMYOU !== 'true' ? (
            <>
              <Header />
              <div className={classes.pageWrapper}>{children}</div>
              <Footer />
            </>
          ) : (
            <>
              <Header />
              <div className={classes.genomYouPageWrapper}>{children}</div>
              <Footer />
            </>
          )}
        </>
      ) : (
        <div style={{ padding: 0 }}>{children}</div>
      )}
      <Notify />
      <RightBottomNotification />
    </>
  )
}

export default Layout
