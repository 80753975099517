// @flow
export const UPDATE_DETAILS: 'profile/UPDATE_DETAILS' =
  'profile/UPDATE_DETAILS';
export const UPDATE_DETAILS_SUCCESS: 'profile/UPDATE_DETAILS_SUCCESS' =
  'profile/UPDATE_DETAILS_SUCCESS';
export const UPDATE_DETAILS_FAILURE: 'profile/UPDATE_DETAILS_FAILURE' =
  'profile/UPDATE_DETAILS_FAILURE';

type UpdateDetailsT = {
  id: number,
  details: Object,
};

type MeUpdateDetails = {
  type: typeof UPDATE_DETAILS,
};

export type MeUpdateDetailsSuccess = {
  type: typeof UPDATE_DETAILS_SUCCESS,
  payload: any,
};

type MeUpdateDetailsFailure = {
  type: typeof UPDATE_DETAILS_FAILURE,
  payload: any,
};

type ActionType =
  | MeUpdateDetails
  | MeUpdateDetailsSuccess
  | MeUpdateDetailsFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  error: null,
};

export default function profileDetailsReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case UPDATE_DETAILS:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case UPDATE_DETAILS_SUCCESS:
      return {
        ...initialState,
        loading: false,
        error: null,
      };

    case UPDATE_DETAILS_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function updateDetails(data: UpdateDetailsT) {
  return {
    type: UPDATE_DETAILS,
    payload: data,
  };
}
