import { gql } from '@apollo/client'

const UPDATE_FILE_STATUS = gql`
  mutation UpdateFileStatus($inUse: Boolean!, $partial: Boolean!, $uuid: UUID!) {
    updateFileStatus(inUse: $inUse, partial: $partial, uuid: $uuid) {
      resFile {
        uuid
      }
    }
  }
`

const UPDATE_MULTIPART_FILE_STATUS = gql`
  mutation UpdateMultipartFileStatus(
    $inUse: Boolean!
    $partial: Boolean!
    $uuid: UUID!
    $uploadId: String
    $etags: [String!]
  ) {
    updateFileStatus(inUse: $inUse, partial: $partial, uuid: $uuid, uploadId: $uploadId, etags: $etags) {
      resFile {
        uuid
      }
    }
  }
`

const UPLOAD_FILE = gql`
  mutation UploadFile($fileData: FileInput!, $forceOverride: Boolean) {
    uploadFile(fileData: $fileData, override: $forceOverride) {
      resFile {
        uuid
      }
      uploadUrl
    }
  }
`

const UPLOAD_MULTIPART_FILE = gql`
  mutation UploadMultiFile($fileData: FileInput!, $forceOverride: Boolean, $numberOfParts: Int) {
    uploadFile(fileData: $fileData, override: $forceOverride, numberOfParts: $numberOfParts) {
      resFile {
        uuid
      }
      uploadUrls
      uploadId
    }
  }
`

const RUN_ANALYSIS = gql`
  mutation CreateAnalysisRun($data: AnalysisRunInput!) {
    createAnalysisRun(data: $data) {
      uuid
    }
  }
`
const GY_CREATE_ANALYSYS_RUN = gql`
  mutation GenomyouCreateAnalysisRunWithGenomicSequencing(
    $analysisUuid: UUID!
    $fileUuidList: [UUID]!
    $genomicSequencingUuid: UUID
    $name: String
    $description: String
    $fileType: String
    $sampleType: String
    $referenceGenome: String
    $analysisTypes: [String]
  ) {
    genomyouCreateAnalysisRunWithGenomicSequencing(
      analysisUuid: $analysisUuid
      fileUuidList: $fileUuidList
      genomicSequencingUuid: $genomicSequencingUuid
      name: $name
      fileType: $fileType
      description: $description
      sampleType: $sampleType
      referenceGenome: $referenceGenome
      analysisTypes: $analysisTypes
    ) {
      result {
        analysisRunUuid
      }
    }
  }
`

export {
  UPLOAD_FILE,
  UPDATE_FILE_STATUS,
  RUN_ANALYSIS,
  UPLOAD_MULTIPART_FILE,
  UPDATE_MULTIPART_FILE_STATUS,
  GY_CREATE_ANALYSYS_RUN
}
