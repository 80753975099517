import { takeEvery, put } from 'redux-saga/effects';

import {
  UPLOAD_USER_PROFILE_PICTURE,
  UPLOAD_USER_PROFILE_PICTURE_FAILURE,
  UPLOAD_USER_PROFILE_PICTURE_SUCCESS,
} from '../../redux/upload/uploadUserProfilePicture';
function* uploadProfilePicture({ payload }) {
  try {
    yield put({
      type: UPLOAD_USER_PROFILE_PICTURE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPLOAD_USER_PROFILE_PICTURE_FAILURE,
      payload: {
        error: error,
      },
    });
  }
}

export default function* watchUploadUSerProfilePicture() {
  yield takeEvery(UPLOAD_USER_PROFILE_PICTURE, uploadProfilePicture);
}
