import axios from 'axios';
import { Dispatch } from 'redux';

import { loginUserAuthClean } from '../store/redux/auth/login';

export const setDefaultAxios = (dispatch: Dispatch): void => {
  axios.defaults.baseURL = process.env.REACT_APP_API || process.env.REACT_APP_API;
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers['Content-Type'] = 'application/json';

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        dispatch(loginUserAuthClean());
      }
      return Promise.reject(error);
    },
  );
};

export const setAuthorizationHeader = (token: string | null): void => {
  token !== null &&
    token.length > 0 &&
    (axios.defaults.headers.Authorization = `Bearer ${token}`);
};

export const removeAuthorizationHeader = (): void => {
  delete axios.defaults.headers.Authorization;
};
