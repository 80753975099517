import { gql } from '@apollo/client';
const GET_EVENTS = gql`
  query Events(
    $filter: EventTypeFilter
    $offset: Int
    $first: Int
    $orderBy: String
  ) {
    events(filter: $filter, offset: $offset, first: $first, orderBy: $orderBy) {
      edges {
        node {
          name
          source
          subjectUuid
          subjectType
          targetUuid
          targetType
          description
          creationTime
        }
      }
      totalCount
    }
  }
`;

export { GET_EVENTS };
