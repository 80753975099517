import axios, { AxiosResponse } from 'axios'
import { takeEvery, put, call, StrictEffect, take } from 'redux-saga/effects'

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_CLEAN,
  LOGIN_USER_CLEAN_FAILURE,
  LOGIN_USER_BY_2FA,
  LOGIN_USER_BY_2FA_SUCCESS,
  TOKEN_EXPIRED,
  TLoginUserData,
  TLoginUserSaga,
  TLoginUserBy2Fa,
  TLoginUserSuccess,
  TLoginUserClean,
  TLoginUserBy2FaSuccess,
  TLoginUserCleanFailure,
  TLoginUserFailure,
  TUser
} from '../../redux/auth/login'
import * as endpoints from '../../../constants/api'
import { setAuthorizationHeader, removeAuthorizationHeader } from '../../../helpers/axios'
import apolloClientProfile from '../../../apolloClient/userProfile'
import { GET_USER_IS_ACTIVE_2FA } from '../../../graphql/queries/user'
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USER } from '../../../constants/localStorage'
import { ApolloQueryResult, QueryOptions } from '@apollo/client'
import { GetUserIsActive2fa, GetUserIsActive2faVariables } from '../../../types/generated/GetUserIsActive2fa'
import { notifyError, TAShowNotify } from '../../redux/notify/notify'
import i18n from '../../../locales/i18next'
import { USER_DETAILS } from '../../redux/userManagement/userDetails'

type TTokenResponse = {
  access_token: string
  uuid: string
}

function* getToken(values: TLoginUserData): Generator<StrictEffect, TTokenResponse, AxiosResponse<TTokenResponse>> {
  const data = new URLSearchParams()
  data.append('grant_type', 'password')
  data.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '')
  data.append('client_id', process.env.REACT_APP_CLIENT_ID || '')
  data.append('username', values.username)
  data.append('password', values.password)

  try {
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_GS_AUTH || process.env.REACT_APP_BASE_API_URL}${endpoints.OAUTH_TOKEN}/`,
      data,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    )
    setAuthorizationHeader(response.data.access_token)
    localStorage.setItem(LOCALSTORAGE_TOKEN, response.data.access_token)
    return response.data
  } catch (e) {
    if (e.response.status === 403)
      yield put<TAShowNotify>(notifyError(`${i18n.t('pages.loginPage.notifications.credentialsError')}`))
    throw e
  }
}

function* login(action: TLoginUserSaga): Generator<StrictEffect, void, any> {
  const values = action.payload

  try {
    const data: TTokenResponse = yield call(getToken, values)
    const authData = {
      token: data.access_token,
      uuid: data.uuid
    }

    const profileResponse: ApolloQueryResult<GetUserIsActive2fa> = yield call(apolloClientProfile.query, {
      query: GET_USER_IS_ACTIVE_2FA,
      variables: {
        userUuid: data.uuid
      }
    } as QueryOptions<GetUserIsActive2faVariables, GetUserIsActive2fa>)

    const userData: TUser = {
      ...authData,
      isActive2fa: profileResponse.data.isActive2fa || false
    }
    if (profileResponse.data.isActive2fa && process.env.REACT_APP_2FA === 'true') {
      yield put<TLoginUserBy2Fa>({
        type: LOGIN_USER_BY_2FA,
        payload: userData
      })
    } else {
      yield put<TLoginUserSuccess>({
        type: LOGIN_USER_SUCCESS,
        payload: {
          user: { ...authData, isActive2fa: profileResponse.data.isActive2fa }
        }
      })
      localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(userData))

      yield put({ type: USER_DETAILS, payload: { userUuid: userData.uuid } })
    }
  } catch (error) {
    console.error(error.response.status)
    console.log(error)
    const errormessage =
      error.response.status === 403 ? `${i18n.t('pages.loginPage.notifications.credentialsError')}` : 'Login failed'

    yield put<TLoginUserFailure>({
      type: LOGIN_USER_FAILURE,
      payload: {
        error: errormessage
      }
    })
  }
}

function* loginBy2faSuccess({ payload }: TLoginUserBy2FaSuccess) {
  localStorage.setItem(LOCALSTORAGE_TOKEN, payload.token)
  localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(payload))
  yield put({ type: USER_DETAILS, payload: { userUuid: payload.uuid } })
  yield put<TLoginUserSuccess>({
    type: LOGIN_USER_SUCCESS,
    payload: {
      user: payload
    }
  })
}

export function* authCheckLocalStorageSaga(): Generator<StrictEffect, void, undefined> {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN)
  const user: TUser | null = localStorage.getItem(LOCALSTORAGE_USER)
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE_USER) as string)
    : null
  if (token && user && user.uuid) {
    yield put({ type: USER_DETAILS, payload: { userUuid: user.uuid } })
    yield put<TLoginUserSuccess>({
      type: LOGIN_USER_SUCCESS,
      payload: {
        user
      }
    })
  } else {
    yield put<TLoginUserClean>({ type: LOGIN_USER_CLEAN })
  }
}

function* clearLocalStorageSaga() {
  try {
    localStorage.removeItem(LOCALSTORAGE_TOKEN)
    localStorage.removeItem(LOCALSTORAGE_USER)
    removeAuthorizationHeader()
  } catch (error) {
    yield put<TLoginUserCleanFailure>({
      type: LOGIN_USER_CLEAN_FAILURE,
      payload: {
        error: error.response.data.non_field_errors[0]
      }
    })
  }
}

export default function* watchLogin(): Generator<StrictEffect, void, any> {
  yield call(authCheckLocalStorageSaga)
  yield takeEvery(LOGIN_USER, login)
  yield takeEvery(LOGIN_USER_CLEAN, clearLocalStorageSaga)
  yield takeEvery(LOGIN_USER_BY_2FA_SUCCESS, loginBy2faSuccess)
}

export function* watchSessionExpired() {
  yield take(TOKEN_EXPIRED)
  yield put({ type: LOGIN_USER_CLEAN })
  yield put(notifyError('Session expired'))
}
