import myData from './pages/myData'
import profileDetails from './pages/profileDetails'
import userList from './pages/userList'
import userAdd from './pages/userAdd'
import profile from './pages/profile'
import patient from './pages/patient'
import login from './pages/login'
import resendEmail from './pages/resendEmail'
import register from './pages/register'
import successfulRegistration from './pages/successfulRegistration'
import userEdit from './pages/userEdit'
import notifications from './pages/notifications'
import formik from './pages/formik'
import genomYou from './pages/genomYou'

export default {
  translation: {
    components: {
      uploader: {
        cancelAll: 'Cancel all',
        item: {
          cancel: 'Cancel'
        }
      },
      confirmModal: {
        cancel: 'CANCEL',
        confirm: 'CONFIRM'
      }
    },
    button: {
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      confirm: 'Confirm',
      choose: 'Choose',
      open: 'Open',
      move: 'Move',
      download: 'Download',
      startAnalyses: 'Start analysis',
      uploadFiles: 'Upload files',
      contactUs: 'Contact us'
    },
    menu: {
      data: 'My data',
      admin: 'Admin',
      patients: 'My patients',
      about: 'About us',
      contacts: 'Contacts',
      users: 'Users',
      events: 'Events',
      logout: 'Log out',
      profile: 'Profile',
      notifications: 'Notifications'
    },
    rightBottomNotification: {
      uploadFile: {
        title: 'Uploading files',
        confirmTitle: 'Are you sure?',
        confirmSubtitle: 'All current file uploads will be canceled!'
      }
    },
    pages: {
      registerPage: register,
      successfulRegistration: successfulRegistration,
      loginPage: login,
      resendEmailPage: resendEmail,
      patientPage: patient,
      profilePage: profile,
      profileDetailsPage: profileDetails,
      userListPage: userList,
      userAddPage: userAdd,
      userEditPage: userEdit,
      myData: myData,
      notifications: notifications,
      genomYou: genomYou
    },
    formik: formik
  }
}
