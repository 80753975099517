import React, { Suspense } from 'react'

// import * as routes from './constants/routes'
import GenomYouRoutes from './routes/GenomYouRoutes'
import GenomSysRoutes from './routes/GenomSysRoutes'
import { useAppSelector } from './utils/useAppSelector'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  wrapperSpinner: {
    display: 'flex',
    justify: 'center',
    alignItems: 'center',
    height: '100%'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  spinner: {
    width: '40px',
    height: '40px',
    border: '5px solid #f3f3f3' /* Light grey */,
    borderTop: '5px solid #3498db' /* Blue */,
    borderRadius: '50%',
    animation: '$spin 2s linear infinite'
  }
}))

type TAppRouterProps = {}

const AppRouter: React.FC<TAppRouterProps> = () => {
  const classes = useStyles()
  const { isAuth, is2faRequired } = useAppSelector(state => state.auth.login)
  // TODO uncomment when backend done
  // const { isAccountActivated } = user;
  const isAccountActivated = true
  return (
    <Suspense
      fallback={
        <div className={classes.wrapperSpinner}>
          <div className={classes.spinner} />
        </div>
      }>
      {process.env.REACT_APP_GENOMYOU === 'true' ? (
        <GenomYouRoutes is2faRequired={is2faRequired} isAuth={isAuth} isAccountActivated={isAccountActivated} />
      ) : (
        <GenomSysRoutes is2faRequired={is2faRequired} isAuth={isAuth} isAccountActivated={isAccountActivated} />
      )}
    </Suspense>
  )
}

export default AppRouter
