import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

function useEventListener(eventName: string, handler: (e: any) => void, element = window) {
  const savedHandler = useRef<any>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: any) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

type AccessibleTooltipProps = {
  text: string
  children: ReactElement
  [x: string]: any
}

const AccessibleTooltip = ({ text, children, ...rest }: AccessibleTooltipProps) => {
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const handler = useCallback(e => {
    if (e.key === 'Escape') {
      onClose()
    }
  }, [])

  useEventListener('keydown', handler)

  return (
    <Tooltip open={open} onOpen={onOpen} onClose={onClose} interactive title={text} aria-label={text} {...rest}>
      {children}
    </Tooltip>
  )
}

AccessibleTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default AccessibleTooltip
