// @flow
import { combineReducers } from 'redux';
import userList from './userList';
import userEdit from './userEdit';
import userDelete from './userDelete';
import userDetails from './userDetails.ts';
import userEditDetails from './userEditDetails';
import userCreate from './userCreate';

export default combineReducers({
  userList,
  userEdit,
  userDelete,
  userDetails,
  userCreate,
  userEditDetails,
});
