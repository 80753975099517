import { takeEvery, put } from 'redux-saga/effects'
import apolloClient from '../../../apolloClient/notifications'

import {
  SUBSCRIPTIONS_LIST_FETCH,
  SUBSCRIPTIONS_LIST_GET_SUCCESS,
  SUBSCRIPTIONS_LIST_GET_FAILURE,
  CREATE_USER_SUBSCRIPTION_SUCCESS,
  CREATE_USER_SUBSCRIPTION_START,
  UPDATE_USER_SUBSCRIPTION_START,
  CREATE_USER_SUBSCRIPTION_FAILURE,
  UPDATE_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_USER_SUBSCRIPTION_FAILURE,
  DELETE_USER_SUBSCRIPTION_FAILURE,
  DELETE_USER_SUBSCRIPTION_SUCCESS,
  DELETE_USER_SUBSCRIPTION_START
} from '../../redux/notifications/subscriptions'

import {
  SOURCES_LIST_FETCH,
  SOURCES_LIST_GET_SUCCESS,
  SOURCES_LIST_GET_FAILURE
} from '../../redux/notifications/sources'
import { GET_EVENT_SOURCES, GET_USER_SUBSCRIPTION } from '../../../graphql/queries/notifications'
import {
  CREATE_USER_SUBSCRIPTION,
  UPDATE_USER_SUBSCRIPTION,
  DELETE_USER_SUBSCRIPTION
} from '../../../graphql/mutations/notifications'
import { notifyError } from '../../redux/notify/notify'

export function* SubscriptionsList() {
  try {
    const result = yield apolloClient.query({
      query: GET_USER_SUBSCRIPTION
    })
    const { data } = result
    const parsedData = data.subscriptions.edges.map(edge => {
      return {
        ...edge.node,
        channels: edge.node.channels.split(', '),
        details: edge.node.details.split(', ')
      }
    })

    yield put({
      type: SUBSCRIPTIONS_LIST_GET_SUCCESS,
      payload: {
        subscriptions: parsedData
      }
    })
  } catch (e) {
    yield put({
      type: SUBSCRIPTIONS_LIST_GET_FAILURE,
      payload: {
        error: e
      }
    })
    yield put(notifyError(`${e}`))
  }
}

export function* SourcesList() {
  try {
    const result = yield apolloClient.query({
      query: GET_EVENT_SOURCES
    })
    const { data } = result
    const parsedData = data.eventSources.edges.map(edge => {
      return {
        ...edge.node,
        eventTypes: edge.node.eventTypes.edges.map(eventType => {
          return {
            ...eventType.node
          }
        })
      }
    })

    yield put({
      type: SOURCES_LIST_GET_SUCCESS,
      payload: {
        sources: parsedData
      }
    })
  } catch (e) {
    yield put({
      type: SOURCES_LIST_GET_FAILURE,
      payload: {
        error: e
      }
    })
    yield put(notifyError(`${e}`))
  }
}

export function* CreateUserSubscription({ payload }) {
  try {
    const { data } = yield apolloClient.mutate({
      mutation: CREATE_USER_SUBSCRIPTION,
      variables: {
        eventTypeUuid: payload.eventType,
        eventSourceUuid: payload.eventSource,
        channels: payload.channels
      }
    })

    yield put({
      type: CREATE_USER_SUBSCRIPTION_SUCCESS,
      payload: data.createSubscription
    })
  } catch (e) {
    yield put({
      type: CREATE_USER_SUBSCRIPTION_FAILURE,
      payload: e
    })
    yield put(notifyError(`${e}`))
  }
}

export function* UpdateUserSubscription({ payload }) {
  try {
    const { data } = yield apolloClient.mutate({
      mutation: UPDATE_USER_SUBSCRIPTION,
      variables: {
        uuid: payload.uuid,
        channels: payload.channels
      }
    })

    yield put({
      type: UPDATE_USER_SUBSCRIPTION_SUCCESS,
      payload: data.updateSubscription
    })
  } catch (e) {
    yield put({
      type: UPDATE_USER_SUBSCRIPTION_FAILURE,
      payload: e
    })
    yield put(notifyError(`${e}`))
  }
}

export function* DeleteUserSubscription({ payload }) {
  try {
    yield apolloClient.mutate({
      mutation: DELETE_USER_SUBSCRIPTION,
      variables: {
        uuid: payload.uuid
      }
    })
    yield put({
      type: DELETE_USER_SUBSCRIPTION_SUCCESS,
      payload: payload.uuid
    })
  } catch (e) {
    yield put({
      type: DELETE_USER_SUBSCRIPTION_FAILURE,
      payload: e
    })
    yield put(notifyError(`${e}`))
  }
}

export default function* watchNotifications() {
  yield takeEvery(SUBSCRIPTIONS_LIST_FETCH, SubscriptionsList)
  yield takeEvery(SOURCES_LIST_FETCH, SourcesList)
  yield takeEvery(CREATE_USER_SUBSCRIPTION_START, CreateUserSubscription)
  yield takeEvery(UPDATE_USER_SUBSCRIPTION_START, UpdateUserSubscription)
  yield takeEvery(DELETE_USER_SUBSCRIPTION_START, DeleteUserSubscription)
}
