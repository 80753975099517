import * as React from 'react'

// COMMON
import { loginUserAuthClean } from '../../../../../store/redux/auth/login'
import { useDispatch } from 'react-redux'
import * as routes from '../../../../../constants/routes'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import MenuRoute from '../MenuRoute/MenuRoute'
import { List, useTheme } from '@material-ui/core'
import i18n from '../../../../../locales/i18next'

type TMenuProps = {
  open: boolean
  children: any
  logout: Function
  email: string
  onClose: () => void
}

const Menu = (props: TMenuProps) => {
  const theme = useTheme()
  const { open, children, email, onClose } = props
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(loginUserAuthClean())
  }

  return (
    <Drawer
      BackdropProps={{ style: { top: 84 }, invisible: true }}
      PaperProps={{ style: { top: 84, height: 'calc(100vh - 84px)', width: '100%' } }}
      anchor={'top'}
      open={open}
      onClose={() => onClose()}
      style={{ zIndex: 1230 }}>
      <>
        <nav>
          <List disablePadding={true}>{children}</List>
        </nav>
        <div style={{ marginTop: 'auto' }}>
          {process.env.REACT_APP_GENOMYOU !== 'true' && (
            <MenuRoute
              text={email}
              color={theme.palette.secondary.main}
              key={'Profile details'}
              onHandleClick={onClose}
              routePath={routes.MY_PROFILE_DETAILS}
            />
          )}
          <Divider style={{ margin: '0 20px' }} />
          <MenuRoute
            text={i18n.t('menu.logout')}
            color={theme.palette.error.main}
            key={'Logout'}
            onHandleClick={() => logout()}
            routePath={routes.LOGIN}
          />
        </div>
      </>
    </Drawer>
  )
}

export default Menu
