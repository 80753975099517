import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../utils/useAppSelector'

// COMMON
import { loginUserAuthClean } from '../../../store/redux/auth/login'
import { defaultMenu, dynamicMenu } from './header.config'
import DrawerMobile from './components/DrawerMobile/DrawerMobile'
import Drawer from './components/Drawer/Drawer'
import { useMediaQuery, useTheme } from '@material-ui/core'

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const user = useAppSelector(state => state.userManagement.userDetails.userDetails)
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(loginUserAuthClean())
  }

  if (isTablet)
    return (
      <DrawerMobile
        menu={defaultMenu}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        logout={logout}
        user={user}
      />
    )

  return <Drawer menu={dynamicMenu()} logout={logout} user={user} />
}

export default Header
