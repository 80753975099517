// @flow

export const UPLOAD_USER_PROFILE_PICTURE: 'upload/UPLOAD_USER_PROFILE_PICTURE' =
  'upload/UPLOAD_USER_PROFILE_PICTURE';
export const UPLOAD_USER_PROFILE_PICTURE_SUCCESS: 'upload/UPLOAD_USER_PROFILE_PICTURE_SUCCESS' =
  'upload/UPLOAD_USER_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_USER_PROFILE_PICTURE_FAILURE: 'upload/UPLOAD_USER_PROFILE_PICTURE_FAILURE' =
  'upload/UPLOAD_USER_PROFILE_PICTURE_FAILURE';

type uploadDataPictureT = {
  file: Object,
  id: string,
};

type UploadProfilePicture = {
  type: typeof UPLOAD_USER_PROFILE_PICTURE,
};

export type UploadProfilePictureSuccess = {
  type: typeof UPLOAD_USER_PROFILE_PICTURE_SUCCESS,
  payload: any,
};

type UploadProfilePictureFailure = {
  type: typeof UPLOAD_USER_PROFILE_PICTURE_FAILURE,
  payload: any,
};

type ActionType =
  | UploadProfilePicture
  | UploadProfilePictureSuccess
  | UploadProfilePictureFailure;

export type State = {
  loading: boolean,
  isSuccess: false,
  error: ?string,
};

const initialState: State = {
  loading: false,
  isSuccess: false,
  error: null,
};

export default function uploadReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case UPLOAD_USER_PROFILE_PICTURE:
      return {
        loading: true,
        isSuccess: false,
        error: null,
      };

    case UPLOAD_USER_PROFILE_PICTURE_SUCCESS:
      return {
        loading: false,
        isSuccess: true,
        error: null,
      };

    case UPLOAD_USER_PROFILE_PICTURE_FAILURE:
      return {
        loading: false,
        isSuccess: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function uploadUserProfilePicture(data: uploadDataPictureT) {
  return {
    type: UPLOAD_USER_PROFILE_PICTURE,
    payload: data,
  };
}
