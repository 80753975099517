const patient = {
  title: 'My patients',
  emptyTable: 'You have no patient data',
  table: {
    head: {
      name: 'Name',
      hospitals: 'Hospitals',
      date: 'Date of birth',
      sex: 'Sex',
      phone: 'Phone',
    },
  },
  components: {
    cardContent: {
      contacts: 'Contacts:',
      hospitals: 'Hospitals:',
    },
  },
}

export default patient