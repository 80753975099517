import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';

import {
  PATIENTS_LIST,
  PATIENTS_GET_SUCCESS,
  PATIENT_GET_FAILURE,
} from '../../redux/patients/list';
import * as endpoints from '../../../constants/api';

function* patientsList(action) {
  const { limit, offset } = action.payload;

  try {
    const url = `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PATIENTS}/?limit=${limit}&offset=${offset}`;

    const result = yield axios.get(url);
    const { data } = result;

    yield put({
      type: PATIENTS_GET_SUCCESS,
      payload: {
        patients: data,
      },
    });
  } catch (error) {
    yield put({
      type: PATIENT_GET_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

export default function* watchList() {
  yield takeEvery(PATIENTS_LIST, patientsList);
}
