import React from 'react'
import { Dialog, DialogContent, DialogActions, makeStyles, Theme, DialogProps, Grid } from '@material-ui/core'
import TextWrapper, { TVariantOption } from '../TextWrapper/TextWrapper'
import DialogTitle from './components/DialogTitle'

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.up('tablet')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`
    }
  },
  dialogContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('tablet')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    }
  }
}))

type TModalProps = DialogProps & {
  subtitle?: string | null
  actions?: React.ReactNode
  onClose: () => void
  titleType?: TVariantOption
  subtitleType?: TVariantOption
  titleIcon?: React.ReactNode
}

const Modal = ({
  onClose,
  title,
  subtitle,
  children,
  titleType,
  subtitleType,
  maxWidth = 'lg',
  titleIcon,
  actions = null,
  ...rest
}: TModalProps) => {
  const classes = useStyles()

  return (
    <Dialog {...rest} maxWidth={maxWidth}>
      <>
        <DialogTitle disableTypography={true} onClose={onClose} className={classes.dialogTitle}>
          {title && (
            <Grid container alignItems={'center'} style={{marginTop:4}}>
              {titleIcon}
              <TextWrapper component={titleType || 'h3'} fontWeight={'bold'} style={{ width: '70%' }}>
                {title}
              </TextWrapper>
            </Grid>
          )}
          {subtitle && (
            <TextWrapper
              variant={subtitleType || 'subtitle1'}
              fontWeight={'regular'}
              style={{ marginTop: 20, display: 'inline-block' }}>
              {subtitle}
            </TextWrapper>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </>
    </Dialog>
  )
}

export default Modal
