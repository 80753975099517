import React, { Component } from 'react'
import { Button as MaterialButton, ButtonProps } from '@material-ui/core'

type PropsT = ButtonProps & {
  text: string
  loadingText?: string
  handleSubmit?: () => void
}

export default class Button extends Component<PropsT> {
  render() {
    const { text, loadingText, handleSubmit = () => {}, ...rest } = this.props

    const handleClick = () => {
      handleSubmit()
    }

    return (
      <MaterialButton onClick={handleClick} {...rest}>
        {loadingText ? loadingText : text}
      </MaterialButton>
    )
  }
}
