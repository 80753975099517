import { combineReducers } from 'redux';

import subscriptions from './subscriptions';
import sources from './sources';
import rightBottom, { TRightBottomNotifyState } from './rightBottom';
import notify from '../notify/notify';

export type TNotifications = {
  subscriptions:any,
  sources:any,
  rightBottom:TRightBottomNotifyState,
  notify:any
}

export default combineReducers<TNotifications>({
    subscriptions,
    sources,
    rightBottom,
    notify
});
