import login, { watchSessionExpired } from './auth/login'
import reset from './auth/reset'
import register from './auth/register'
import patientsList from './patients/list'
import data from './myData/data'
import dataSet from './dataSet/dataSet'
import upload from './upload'
import dataManagement from './dataManagement'
import logger from './logger'
import profile from './profile'
import notifications from './notifications'
import { all } from 'redux-saga/effects'
import genomYou from './genomYou'
import { uploadProfilePicture } from './../redux/upload/uploadProfilePicture'

export default function* rootSaga() {
  yield all([
    watchSessionExpired(),
    login(),
    reset(),
    register(),
    patientsList(),
    uploadProfilePicture,
    data(),
    dataSet(),
    // changePassword,
    ...upload,

    ...dataManagement,
    ...logger,
    ...profile,
    ...notifications,
    ...genomYou
  ])
}
