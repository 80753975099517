import { gql } from '@apollo/client'

const GET_FILES_IN_FOLDER = gql`
  query AllFilesInDirectory($uuid: UUID!, $offset: Int!, $first: Int!, $orderBy: String!) {
    files(filter: { parentDirectory_Uuid: $uuid, inUse: false }, offset: $offset, first: $first, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          uuid
          name
          description
          ctime
          mtime
          partial
          inUse
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const GET_FILE_URL = gql`
  query GetFileUrl($uuid: UUID!) {
    downloadFile(uuid: $uuid) {
      downloadUrl
    }
  }
`

const GET_FILE_URL_BY_FULLPATH = gql`
  query GetFileUrlByFullPath($fullPath: String!) {
    downloadFile(fullPath: $fullPath) {
      downloadUrl
    }
  }
`

const SEARCH_FILE_BY_NAME_AND_DIR = gql`
  query files($parent: UUID, $name: String) {
    files(filter: { parentDirectory_Uuid: $parent, name: $name }) {
      edges {
        node {
          uuid
        }
      }
    }
  }
`

export { GET_FILES_IN_FOLDER, GET_FILE_URL, GET_FILE_URL_BY_FULLPATH, SEARCH_FILE_BY_NAME_AND_DIR }
