// @flow
export const ME_INFO: 'me/ME_INFO' = 'me/ME_INFO';
export const ME_INFO_SUCCESS: 'me/ME_INFO_SUCCESS' = 'me/ME_INFO_SUCCESS';
export const ME_INFO_FAILURE: 'me/ME_INFO_FAILURE' = 'me/ME_INFO_FAILURE';

type MeInfoT = {
  id: number,
  role: string,
};

type MeInfo = {
  type: typeof ME_INFO,
};

export type MeInfoSuccess = {
  type: typeof ME_INFO_SUCCESS,
  payload: any,
};

type MeInfoFailure = {
  type: typeof ME_INFO_FAILURE,
  payload: any,
};

type ActionType = MeInfo | MeInfoSuccess | MeInfoFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  me: null,
  user: null,
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case ME_INFO:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case ME_INFO_SUCCESS:
      return {
        ...initialState,
        me: action.payload.me,
        loading: false,
        error: null,
      };

    case ME_INFO_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function meInfo(data: MeInfoT) {
  return {
    type: ME_INFO,
    payload: data,
  };
}
