const formik = {
  common: {
    overrideFileCheckbox: 'Do you want to override file in folder destination?',
    name: 'Name',
    description: 'Description',
  },
  button: {
    selectLocation: 'Select new location',
    dragAndDrop: process.env.REACT_APP_ALITHEA_PROJECT === 'false' ? 'Drag and drop your paired FASTQ files and your project files here' : 'Drag and drop your paired FASTQ files and the SIT xslx file'
  },
  validationCommon: {
    required: 'Required',
    email: 'Invalid email address'
  },
  uploadFile: {
    validation: {
      fileNumberMax2: 'Required number of files is 2',
      duplicate: 'List of files contains duplicates!',
      wrongFileName: 'Wrong file name!',
      jsonPredefinedName: 'The json file has a predefined name: user_input.json or external_user_input.json!',
      notRelatedFile: 'File is not related with any of others files!',
    },
  },
};

export default formik;
