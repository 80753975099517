import { combineReducers } from 'redux'
import listData, { TFolderAndFileState } from './listData'
import listAllFolders, { TListAllFoldersState } from './listAllFolders'
import createFolder, { TCreateFolderState } from './createFolder'

import analyses, { TAnalysesState } from './analyses'

export type TDataManagement = {
  listData: TFolderAndFileState
  listAllFolders: TListAllFoldersState
  createFolder: TCreateFolderState

  analyses: TAnalysesState
}

export default combineReducers<TDataManagement>({
  listData,
  listAllFolders,
  createFolder,

  analyses
})
