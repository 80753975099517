import React from 'react'

import { IconButtonProps, IconButton as Button } from '@material-ui/core'
import AccessibleTooltip from '../AccessibleTooltip'

export type TIconButton = IconButtonProps & {
  tooltip?: string,
  component?: any,
  href?: string
}

const IconButton = (props: TIconButton) => {
  const { tooltip, children, ...rest } = props
  const renderButton = () => <Button {...rest}>{children}</Button>

  return tooltip ? <AccessibleTooltip text={tooltip}>{renderButton()}</AccessibleTooltip> : renderButton()
}

export default IconButton
