// @flow
export const CREATE_DATASET: 'dataset/CREATE_DATASET' =
  'dataset/CREATE_DATASET';
export const CREATE_DATASET_SUCCESS: 'dataset/CREATE_DATASET_SUCCESS' =
  'dataset/CREATE_DATASET_SUCCESS';
export const CREATE_DATASET_FAILURE: 'dataset/CREATE_DATASET_FAILURE' =
  'dataset/CREATE_DATASET_FAILURE';
export const CREATE_DATASET_RESET: 'dataset/CREATE_DATASET_RESET' =
  'dataset/CREATE_DATASET_RESET';

type createDataSetT = {
  dataset_name: string,
  dataset_description: string,
};

type idUserT = {
  id: string,
};

type roleUserT = {
  role: string,
};

type Create = {
  type: typeof CREATE_DATASET,
};

export type CreateDataSuccess = {
  type: typeof CREATE_DATASET_SUCCESS,
  payload: any,
};

type CreateDataFailure = {
  type: typeof CREATE_DATASET_FAILURE,
  payload: any,
};

type CreateDataReset = {
  type: typeof CREATE_DATASET_RESET,
};

type ActionType =
  | Create
  | CreateDataSuccess
  | CreateDataFailure
  | CreateDataReset;

export type State = {
  loading: boolean,
  error: ?string,
  curDataSet: Object,
};

const initialState: State = {
  loading: false,
  curDataSet: null,
  error: null,
};

export default function dataSetReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case CREATE_DATASET:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case CREATE_DATASET_SUCCESS:
      return {
        ...initialState,
        curDataSet: action.payload.curDataSet,
        loading: false,
        error: null,
      };

    case CREATE_DATASET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_DATASET_RESET:
      return {
        ...initialState,
        curDataSet: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

export function createDataSet(data: createDataSetT, list: any) {
  return {
    type: CREATE_DATASET,
    payload: { data, list },
  };
}
