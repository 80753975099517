const login = {
  components: {
    form: {
      emailLabel: 'Email*',
      emailPlaceholder: 'Enter email',
      passwordLabel: 'Password*',
      passwordPlaceholder: 'Enter password',
      loginButton: 'Sign in',
      registerButton: 'Sign up',
    },
  },
  schema: {
    username: 'Email is required',
    password: 'Password is required',
  },
  notifications: {
    credentialsError: 'Invalid email or password'
  },
}

export default login;
