import listData from './listData'
import listAllFolders from './listAllFolders'
import createFolder from './createFolder'
import analyses from './analyses'
import { FetchResult } from '@apollo/client'
import { PutEffect } from 'redux-saga/effects'
import { TNotifyActions } from '../../redux/notify/notify'
import { TListActions } from '../../redux/dataManagement/listData'
import { TAnalysesStateActions } from '../../redux/dataManagement/analyses'

export type CommonDataManagementGeneratorActions =
  | Promise<FetchResult>
  | PutEffect<TNotifyActions>
  | PutEffect<TListActions>
  | PutEffect<TAnalysesStateActions>

export default [listData(), listAllFolders(), createFolder(), analyses()]
