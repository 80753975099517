import React from 'react'
import Box from '@material-ui/core/Box'
import { Typography, TypographyProps } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

type TextWrapperProps = TypographyProps & {
  children: string | React.ReactNode
  fontWeight?: string
  [x: string]: any
}

export type TVariantOption = Variant | 'srOnly' | 'inherit'

const TextWrapper = ({
  children,
  component = 'span',
  gutterBottom = false,
  fontWeight = 'regular',
  variant = 'body1',
  ...rest
}: TextWrapperProps) => {
  let variantOption: TVariantOption
  if (!variant) {
    switch (component) {
      case 'h1':
        variantOption = 'h4'
        break
      case 'h2':
        variantOption = 'h5'
        break
      case 'h3':
        variantOption = 'h6'
        break
      case 'h4':
        variantOption = 'subtitle2'
        break
      default:
        variantOption = 'body1'
    }
  } else {
    variantOption = variant
  }

  let fontWeightOption
  switch (fontWeight) {
    case 'light':
      fontWeightOption = 'fontWeightLight'
      break
    case 'bold':
      fontWeightOption = 'fontWeightBold'
      break
    case 'medium':
      fontWeightOption = 'fontWeightMedium';
      break;
    default:
      fontWeightOption = 'fontWeightRegular'
  }
  return (
    <Typography component={component} variant={variantOption} gutterBottom={gutterBottom} {...rest}>
      <Box component={'span'} fontWeight={fontWeightOption} style={{ wordWrap: 'break-word' }}>
        {children}
      </Box>
    </Typography>
  )
}

export default TextWrapper
