// @flow
import type {ActionDataType, DataState, DataListT} from "./data";

export const DATA_LIST_PATIENT_MODAL: 'patients/DATA_LIST_PATIENT' =
  'patients/DATA_LIST_PATIENT_MODAL';
export const DATA_LIST_SUCCESS_MODAL: 'patients/DATA_LIST_SUCCESS' =
  'patients/DATA_LIST_SUCCESS_MODAL';
export const DATA_LIST_FAILURE_MODAL: 'patients/DATA_LIST_FAILURE' =
  'patients/DATA_LIST_FAILURE_MODAL';


const initialState: DataState = {
  loading: false,
  data: null,
  error: null,
};

export default function profileReducer(
  state: DataState = initialState,
  action: ActionDataType,
): DataState {
  switch (action.type) {
    case DATA_LIST_PATIENT_MODAL:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case DATA_LIST_SUCCESS_MODAL:
      return {
        ...initialState,
        data: action.payload.data,
        loading: false,
        error: null,
      };

    case DATA_LIST_FAILURE_MODAL:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function dataListModal(params: DataListT) {
  return {
    type: DATA_LIST_PATIENT_MODAL,
    payload: params,
  };
}
