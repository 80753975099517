// @flow
import { combineReducers } from 'redux'

import login, { TLoginState } from './login'
import register, { TRegisterState } from './register'
import reset, { TResetPasswordState } from './reset'

export type AuthState = {
  login: TLoginState
  register: TRegisterState
  reset: TResetPasswordState
}

export default combineReducers<AuthState>({
  login,
  register,
  reset
})
