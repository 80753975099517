// @flow
import type {ActionDataType, DataListT, DataState} from "./data";

export const DATA_LIST_DOCTOR_MODAL: 'data/DATA_LIST' = 'data/DATA_LIST_MODAL';
export const DATA_LIST_GET_SUCCESS_MODAL: 'data/DATA_LIST_GET_SUCCESS' =
  'data/DATA_LIST_GET_SUCCESS_MODAL';
export const DATA_LIST_GET_FAILURE_MODAL: 'data/DATA_LIST_GET_FAILURE' =
  'data/DATA_LIST_GET_FAILURE_MODAL';

const initialState: ActionDataType = {
  loading: false,
  doctor_data: null,
  error: null,
};

export default function myDataReducer(
  state: DataState = initialState,
  action: ActionDataType,
): State {
  switch (action.type) {
    case DATA_LIST_DOCTOR_MODAL:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case DATA_LIST_GET_SUCCESS_MODAL:
      return {
        ...initialState,
        doctor_data: action.payload.doctor_data,
        loading: false,
        error: null,
      };

    case DATA_LIST_GET_FAILURE_MODAL:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function dataListDoctorModal(params: DataListT) {
  return {
    type: DATA_LIST_DOCTOR_MODAL,
    payload: params,
  };
}
