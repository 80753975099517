import { takeEvery, put } from 'redux-saga/effects';

import {
  UPLOAD_PROFILE_PICTURE,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
} from '../../redux/upload/uploadProfilePicture';
import { PROFILE_INFO } from '../../redux/profile/detail';
function* uploadProfilePicture({ payload }) {
  const { id } = payload;
  try {
    yield put({
      type: UPLOAD_PROFILE_PICTURE_SUCCESS,
    });
    yield put({
      type: PROFILE_INFO,
      payload: { id },
    });
  } catch (error) {
    yield put({
      type: UPLOAD_PROFILE_PICTURE_FAILURE,
      payload: {
        error: error,
      },
    });
  }
}

export default function* watchUploadProfilePicture() {
  yield takeEvery(UPLOAD_PROFILE_PICTURE, uploadProfilePicture);
}
