import axios from 'axios';
import { takeEvery, put, call } from 'redux-saga/effects';

import {
  CREATE_DATASET,
  CREATE_DATASET_SUCCESS,
  CREATE_DATASET_FAILURE,
} from '../../redux/dataSet/dataSet';
import * as endpoints from '../../../constants/api';
import { dataListPatient } from '../profile/data';
import { DATA_LIST_PATIENT } from '../../redux/profile/data';
import { dataListDoctor } from '../myData/data';
import { DATA_LIST_DOCTOR } from '../../redux/myData/data';

function* createDataSet(action) {
  const {
    data,
    list: { role, id, limit, offset, current },
  } = action.payload;

  try {
    const url = `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.DATASET_CREATE}/`;

    const result = yield axios.post(url, data);

    yield put({
      type: CREATE_DATASET_SUCCESS,
      payload: {
        curDataSet: result.data,
      },
    });

    if (role !== 'doctor') {
      yield call(dataListPatient, {
        type: DATA_LIST_PATIENT,
        payload: {
          id,
          role,
          limit,
          offset,
          current,
        },
      });
    } else {
      yield call(dataListDoctor, {
        type: DATA_LIST_DOCTOR,
        payload: {
          id,
          limit,
          offset,
          current,
        },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_DATASET_FAILURE,
      payload: {
        error: error.response.data,
      },
    });
  }
}

export default function* watchDataSet() {
  yield takeEvery(CREATE_DATASET, createDataSet);
}
