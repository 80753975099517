import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'

type TMenuRoute = {
  text: string
  icon?: Node
  leftIcon?: Node
  routePath: string
  nestedItems?: boolean
  color?: string
  onHandleClick?: () => void
  disabled?: boolean
}

const MenuRoute = ({
  text,
  icon,
  leftIcon,
  routePath,
  nestedItems = false,
  color,
  onHandleClick,
  ...rest
}: TMenuRoute) => {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  if (nestedItems)
    return (
      <>
        <ListItem
          button
          component={'li'}
          onClick={() => {
            setExpanded(!expanded)
            if (onHandleClick) onHandleClick()
          }}
          aria-label={`Rozwiń ${text} `}>
          <ListItemIcon style={{ color: 'black' }}>{icon || leftIcon}</ListItemIcon>
          <ListItemText primary={text} />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        ,
        <Collapse key={'2'} in={expanded} timeout="auto" unmountOnExit>
          <List style={{ marginLeft: '16px' }} component="div" disablePadding>
            {nestedItems}
          </List>
        </Collapse>
      </>
    )

  return (
    <ListItem
      button
      style={{
        color: color || theme.palette.secondary.main,
        padding: '8px 24px'
      }}
      onClick={onHandleClick}
      exact
      component={NavLink}
      activeStyle={{
        fontWeight: 'bold',
        color: theme.palette.primary.main
      }}
      to={routePath}
      {...rest}>
      <ListItemText style={{ textAlign: 'center', whiteSpace: 'nowrap' }} primary={text} />
    </ListItem>
  )
}

export default MenuRoute
