// @flow
import { combineReducers } from 'redux';

import data from './data';
import dataModal from './dataModal';

export default combineReducers({
  data,
  dataModal
});
