export const speciesConfig = [
  {
    name: 'Homo Sapiens',
    analysisName: '14-Analyses bundle',
    analysisType: 'SNVs/indels',
    reference: 'hg38',
    sampleType: 'germline'
  },
  {
    name: 'Canis Familiaris',
    analysisName: 'WGS Canis Familiaris',
    analysisType: 'SNVs/indels',
    reference: 'Cfam31',
    sampleType: 'germline'
  }
]
