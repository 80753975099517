import { gql } from '@apollo/client';

const CREATE_USER_SUBSCRIPTION = gql`
  mutation CreateSubscription($eventTypeUuid: UUID, $eventSourceUuid: UUID, $channels: [String]) {
    createSubscription(
      eventTypeUuid: $eventTypeUuid,
      eventSourceUuid: $eventSourceUuid,
      channels: $channels,
      details: "subject, timestamp, message") {
      uuid,
      userUuid,
      eventSource {
        name,
        uuid
      },
      eventType {
        name,
        uuid
      },
      channels,
      details
    }
  }
`;

const UPDATE_USER_SUBSCRIPTION = gql`
  mutation UpdateSubscription($uuid: UUID, $channels: [String]) {
    updateSubscription(
      uuid: $uuid,
      channels: $channels,
      details: "subject, timestamp, message") {
      uuid,
      userUuid,
      eventSource {
        uuid,
        name
      },
      eventType {
        uuid,
        name
      },
      channels,
      details
    }
  }
`;

const DELETE_USER_SUBSCRIPTION = gql`
  mutation DeleteSubscription($uuid: UUID) {
    deleteSubscription(
      uuid: $uuid
      ) {
      ok
    }
  }
`;


export { CREATE_USER_SUBSCRIPTION, UPDATE_USER_SUBSCRIPTION, DELETE_USER_SUBSCRIPTION };
