import * as React from 'react'

// COMMON
import Box from '@material-ui/core/Box'
import { useStyles } from './MenuButtonStyles'

type TMenuButton = {
  open: boolean
  onHandleClick: () => void
}

const MenuButton = ({ open, onHandleClick }: TMenuButton) => {
  const cl = useStyles({ open })

  return (
    <Box component={'button'} className={cl.container} onClick={onHandleClick}>
      <div className={`${cl.line} ${cl.lineTop}`} />
      <div className={`${cl.line} ${cl.lineMiddle}`} />
      <div className={`${cl.line} ${cl.lineBottom}`} />
    </Box>
  )
}

export default MenuButton
