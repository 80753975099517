import { takeEvery, put, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects'

import apolloClient from '../../../apolloClient/userFile'

import { GET_ALL_FOLDERS } from '../../../graphql/queries/folderList'

import {
  LIST,
  LIST_SUCCESS,
  LIST_FAILURE,
  TListAllFoldersSaga,
  TAListAllFolders,
  TListAllFoldersActions,
  TAListAllFoldersSuccess,
  TAListAllFoldersFailure
} from '../../redux/dataManagement/listAllFolders'

import { GetAllDirectories, GetAllDirectoriesVariables } from '../../../types/generated/GetAllDirectories'
import { CommonDataManagementGeneratorActions } from './index'
import { ApolloQueryResult } from '@apollo/client'

type GetFolderListGenerator = CommonDataManagementGeneratorActions | PutEffect<TListAllFoldersActions>

function* getFolderList({ payload }: TListAllFoldersSaga): Generator<GetFolderListGenerator, void, any> {
  try {
    const { data }: ApolloQueryResult<GetAllDirectories> = yield apolloClient.query<
      GetAllDirectories,
      GetAllDirectoriesVariables
    >({
      query: GET_ALL_FOLDERS,
      variables: {
        uuid: payload.uuid
      }
    })

    yield put<TAListAllFoldersSuccess>({
      type: LIST_SUCCESS,
      // @ts-ignore
      payload: data?.directories ? data.directories.edges.map(x => x && x.node) : []
    })
  } catch (error) {
    yield put<TAListAllFoldersFailure>({
      type: LIST_FAILURE,
      payload: {
        error: `${error}`
      }
    })
  }
}

export default function* watchAllFolders(): Generator<ForkEffect<TAListAllFolders> | CallEffect, void> {
  yield takeEvery(LIST, getFolderList)
}
