// @flow
export const USER_EDIT_DETAILS: 'userManagement/USER_EDIT_DETAILS' =
  'userManagement/USER_EDIT_DETAILS';
export const USER_EDIT_DETAILS_GET_SUCCESS: 'userManagement/USER_EDIT_DETAILS_GET_SUCCESS' =
  'userManagement/USER_EDIT_DETAILS_GET_SUCCESS';
export const USER_EDIT_DETAILS_GET_FAILURE: 'userManagement/USER_EDIT_DETAILS_GET_FAILURE' =
  'userManagement/USER_EDIT_DETAILS_GET_FAILURE';

type UserDetailsT = {
  userUuid: String,
};
type UserDetails = {
  type: typeof USER_EDIT_DETAILS,
};

export type UserDetailsGetSuccess = {
  type: typeof USER_EDIT_DETAILS_GET_SUCCESS,
  payload: any,
};

type UserDetailsGetFailure = {
  type: typeof USER_EDIT_DETAILS_GET_FAILURE,
  payload: any,
};

type ActionType = UserDetails | UserDetailsGetSuccess | UserDetailsGetFailure;

export type State = {
  userDetails: Array,
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  userDetails: [],
  loading: false,
  error: null,
};

export default function userReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case USER_EDIT_DETAILS:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case USER_EDIT_DETAILS_GET_SUCCESS:
      return {
        ...initialState,
        userDetails: action.payload,
        loading: false,
        error: null,
      };

    case USER_EDIT_DETAILS_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function loadUserDetails(data: UserDetailsT) {
  return {
    type: USER_EDIT_DETAILS,
    payload: data,
  };
}
