import React, { Component } from 'react'
import * as routes from '../../constants/routes'
import { Route, Redirect, RouteProps } from 'react-router'

type TAuthRouteProps = RouteProps & {
  isAuth: boolean
  isAccountActivated: boolean
  component: Component | React.FC
}

const AuthRoute: React.FC<TAuthRouteProps> = ({ component: Component, isAuth, path, isAccountActivated, ...rest }) => {
  const isResendEmailPath = path === routes.RESEND_EMAIL
  return (
    <Route
      {...rest}
      render={props =>
        isAuth === true ? (
          isAccountActivated ? (
            <Component {...props} />
          ) : isResendEmailPath ? (
            <Component {...props} />
          ) : (
            <Redirect to={routes.RESEND_EMAIL} />
          )
        ) : (
          <Redirect to={routes.LOGIN} />
        )
      }
    />
  )
}

export default AuthRoute
