// export const LOGIN = 'api_token_auth';
export const LOGIN = './login'
export const REGISTER = '/auth/account/register'
export const RESET = '/auth/account/password/forgot/'
export const RESEND_EMAIL = '/auth/account/activation/resend'
export const OAUTH_TOKEN = '/o/token'
export const GRAPHQL_ENDPOINT = '/mobile/graphql/'
// DOCTOR
export const PATIENTS = '/patient_list'
export const PATIENT_INFO = '/patient_detail/{id}'
export const PATIENT_DATA = '/patient_data/{id}'
export const PATIENT_DOCTORS = '/patient_doctors/{id}'
export const PATIENT_HOSPITALS = '/patient_hospitals/{id}'
export const SHARE_TO_DATA = '/share'
export const SHARE_LIST = '/sharing_requests_list'
export const SHARE_ACTION = '/sharing_requests'
export const SHARE_STATUS = '/sharing_status'
export const PROFILE_DOCTOR = '/my_detail_doctor/{id}'
export const DOCTOR_DATA = '/doctor_data/{id}'
export const UPLOAD_FILE_DOCTOR = '/doctor_data/{id}'
export const DATASET_CREATE = '/create_dataset'
export const CHANGE_PASSWORD = '/password/{email}'

// PATIENT
export const PROFILE_PATIENT = '/my_detail_patient/{id}'
export const MY_DATA = '/my_data/{id}'
export const MY_HOSPITALS = '/my_hospitals/{id}'
export const MY_DOCTORS = '/my_doctors/{id}'
export const UPLOAD_FILE_PATIENT = '/my_data/{id}'
