import { TPayloadError } from '../../../types'

export const LOAD_FOLDER_LIST_SUCCESS = 'folderAndFileList/LOAD_FOLDER_LIST_SUCCESS'
export const LOAD_FOLDER_LIST_FAILURE = 'folderAndFileList/LOAD_FOLDER_LIST_FAILURE'
export const LOAD_FILES_LIST_FAILURE = 'folderAndFileList/LOAD_FILES_LIST_FAILURE'
export const UPDATE_PAGINATION_FOLDERS_LIST_SUCCESS ='folderAndFileList/UPDATE_PAGINATION_FOLDERS_LIST_SUCCESS'
export const UPDATE_PAGINATION_FILES_LIST_SUCCESS ='folderAndFileList/UPDATE_PAGINATION_FILES_LIST_SUCCESS'
export const LOAD_FOLDER_LIST = 'folderAndFileList/LOAD_FOLDER_LIST'
export const LOAD_FILES_LIST = 'folderAndFileList/LOAD_FILES_LIST'
export const LOAD_FILES_LIST_SUCCESS = 'folderAndFileList/LOAD_FILES_LIST_SUCCESS'

export type TFolder = {
  description: string
  name: string
  uuid: string
  parentUuid: string
  ctime: string
  fileCount: number
}

export type TFile = {
  uuid: string
  name: string
  description?: string
  ctime: string
  mtime: string
  partial: boolean
  inUse: boolean
  directoryUuid?: string
}

type TFolderAndFileList = {
  currentPath: string | null
  pagination: TPagination
}


export type TALoadFolderListSaga  = {
  type: typeof LOAD_FOLDER_LIST
  payload: TFolderAndFileList
}

export type TALoadFilesListSaga = {
  type: typeof LOAD_FILES_LIST
  payload: TFolderAndFileList
}


export type TALoadFilesListSuccess = {
  type: typeof LOAD_FILES_LIST_SUCCESS
  payload: {
    fileList: TFile[]
    totalCount: number
  }
}

export type TALoadFolderListSuccess = {
  type: typeof LOAD_FOLDER_LIST_SUCCESS
  payload: {
    folderList: TFolder[]
    parentId: string
    totalCount: number
  }
}

export type TAUpdatePaginationFoldersListSuccess = {
  type: typeof UPDATE_PAGINATION_FOLDERS_LIST_SUCCESS
  payload: {
    pagination: TPagination
  }
}

export type TAUpdatePaginationFilesListSuccess = {
  type: typeof UPDATE_PAGINATION_FILES_LIST_SUCCESS
  payload: {
    pagination: TPagination
  }
}

export type TAFolderListFailure = {
  type: typeof LOAD_FOLDER_LIST_FAILURE
  payload: TPayloadError
}

export type TAFilesListFailure = {
  type: typeof LOAD_FILES_LIST_FAILURE
  payload: TPayloadError
}

export type TListActions = TAFolderListFailure | TAFilesListFailure |
  TAUpdatePaginationFoldersListSuccess | TALoadFolderListSuccess | TALoadFolderListSaga  |
  TALoadFilesListSuccess | TALoadFilesListSaga  | TAUpdatePaginationFilesListSuccess

export type TFolderAndFileState = {
  parentId: string | null
  totalCountFolder: number
  totalCountFile: number
  folderList: TFolder[]
  fileList: TFile[]
  filesFirstLoading: boolean
  folderFirstLoading: boolean
  loadingFolders: boolean
  loadingFiles: boolean
  paginationFolders: TPagination
  paginationFiles: TPagination
  errorFiles: string | null
  errorFolder: string | null
}

export type TPagination = {
  offset: number
  page: number
  order: string
  orderBy: string
}

const initialState: TFolderAndFileState = {
  parentId: null,
  folderList: [],
  fileList: [],
  folderFirstLoading: true,
  filesFirstLoading: true,
  loadingFolders: false,
  loadingFiles: false,
  errorFolder: null,
  errorFiles: null,
  totalCountFolder: 0,
  totalCountFile: 0,
  paginationFolders:{
    offset: 5,
    page: 0,
    order: 'asc',
    orderBy: 'ctime'
  },
  paginationFiles:{
    offset: 5,
    page: 0,
    order: 'asc',
    orderBy: 'ctime'
  }
}


export type TLoadFolderListSaga = {
  type: typeof LOAD_FOLDER_LIST
  payload: TFolderAndFileList
}

export type TLoadFilesListSaga = {
  type: typeof LOAD_FILES_LIST
  payload: TFolderAndFileList
}

export default function folderAndFileReducer(state = initialState, action: TListActions): TFolderAndFileState {
  switch (action.type) {
    case LOAD_FOLDER_LIST:
      return {
        ...state,
        loadingFolders: true,
        errorFolder: null
      }
    case LOAD_FILES_LIST:
      return {
        ...state,
        loadingFiles: true,
        errorFiles: null
      }

    case LOAD_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        totalCountFolder: action.payload.totalCount,
        parentId: action.payload.parentId,
        folderList: action.payload.folderList,
        folderFirstLoading: false,
        loadingFolders: false,
        errorFolder: null
      }

    case LOAD_FILES_LIST_SUCCESS:
      return {
        ...state,
        totalCountFile: action.payload.totalCount,
        fileList: action.payload.fileList,
        loadingFiles: false,
        filesFirstLoading: false,
        errorFiles: null
      }

    case UPDATE_PAGINATION_FOLDERS_LIST_SUCCESS:
      return {
        ...state,
        paginationFolders: action.payload.pagination,
      }

    case UPDATE_PAGINATION_FILES_LIST_SUCCESS:
      return {
        ...state,
        paginationFiles: action.payload.pagination,
      }

    case LOAD_FILES_LIST_FAILURE:
      return {
        ...state,
        loadingFiles: false,
        filesFirstLoading: true,
        errorFiles: action.payload.error
      }

    case LOAD_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loadingFolders: false,
        folderFirstLoading: true,
        errorFolder: action.payload.error
      }
    default:
      return state
  }
}


export function loadFolderList(data: TFolderAndFileList): TLoadFolderListSaga {
  return {
    type: LOAD_FOLDER_LIST,
    payload: data
  }
}

export function loadFilesList(data: TFolderAndFileList): TLoadFilesListSaga {
  return {
    type: LOAD_FILES_LIST,
    payload: data
  }
}


export function updatePaginationFolder(data: TPagination): TAUpdatePaginationFoldersListSuccess {
  return {
    type: UPDATE_PAGINATION_FOLDERS_LIST_SUCCESS,
    payload: {
      pagination: data
    }
  }
}

export function updatePaginationFiles(data: TPagination): TAUpdatePaginationFilesListSuccess {
  return {
    type: UPDATE_PAGINATION_FILES_LIST_SUCCESS,
    payload: {
      pagination: data
    }
  }
}
