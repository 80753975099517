import { put, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_DETAILS,
  UPDATE_DETAILS_FAILURE,
  UPDATE_DETAILS_SUCCESS,
} from '../../redux/profile/updateProfileDetails';

function* updateDetails(action) {
  const { values } = action.payload;

  try {
    //TODO connect with backend
    yield put({
      type: UPDATE_DETAILS_SUCCESS,
      payload: {
        me: values,
      },
    });
  } catch (error) {
    yield put({
      type: UPDATE_DETAILS_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

export default function* watchUpdateProfileDetails() {
  yield takeEvery(UPDATE_DETAILS, updateDetails);
}
