// @flow
export const DOCTORS_LIST: 'patients/DOCTORS_LIST' = 'patients/DOCTORS_LIST';
export const DOCTORS_LIST_SUCCESS: 'patients/DOCTORS_LIST_SUCCESS' =
  'patients/DOCTORS_LIST_SUCCESS';
export const DOCTORS_LIST_FAILURE: 'patients/DOCTORS_LIST_FAILURE' =
  'patients/DOCTORS_LIST_FAILURE';

type DoctorsListT = {
  params: string,
};

type List = {
  type: typeof DOCTORS_LIST,
};

export type DoctorsGetSuccess = {
  type: typeof DOCTORS_LIST_SUCCESS,
  payload: any,
};

type DoctorsGetFailure = {
  type: typeof DOCTORS_LIST_FAILURE,
  payload: any,
};

type ActionType = List | DoctorsGetSuccess | DoctorsGetFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  doctors: null,
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case DOCTORS_LIST:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case DOCTORS_LIST_SUCCESS:
      return {
        ...initialState,
        doctors: action.payload.doctors,
        loading: false,
        error: null,
      };

    case DOCTORS_LIST_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function doctorsList(params: DoctorsListT) {
  return {
    type: DOCTORS_LIST,
    payload: params,
  };
}
