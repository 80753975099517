export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE'

export type TResetPasswordData = {
  username: string
}

export type TResetPasswordState = {
  loading: boolean
  error: string | null
  success: boolean | null
}

const initialState: TResetPasswordState = {
  loading: false,
  error: null,
  success: false
}

export type TResetPassword = {
  type: typeof RESET_PASSWORD
}

export type TResetPasswordSuccess = {
  type: typeof RESET_PASSWORD_SUCCESS
}

export type TResetPasswordFailure = {
  type: typeof RESET_PASSWORD_FAILURE
  payload: {
    error: string
  }
}

export type TResetPasswordAction = TResetPassword | TResetPasswordSuccess | TResetPasswordFailure

export type TResetPasswordSaga = {
  type: typeof RESET_PASSWORD
  payload: TResetPasswordData
}

export function resetPassword(data: TResetPasswordData): TResetPasswordSaga {
  return {
    type: RESET_PASSWORD,
    payload: data
  }
}


const resetReducer = (state = initialState, action: TResetPasswordAction): TResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...initialState,
        loading: true,
        error: null,
        success: false
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...initialState,
        loading: false,
        error: null,
        success: true
      }

    case RESET_PASSWORD_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
        success: false
      }

    default:
      return state
  }
}

export default resetReducer
