const profileDetails = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  dateOfBirth: 'Date of birth',
  phoneNumber: 'Phone number',
  address: 'Address',
  gender: 'Gender',
  uploadPicture: 'Upload profile picture',
  changePassword: 'Change password',
  save: 'Save changes',
  activationInfo:
    'If you want to edit your profile you must activate your account by clicking the link sent on your email',
  sendActivationEmail: 'Send activation email',
  changePasswordModal: {
    title: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    oldPasswordRequired: 'Old password is required',
    newPasswordRequired: 'New password is required',
    newPasswordMinLength: 'New password must be at least 8 characters',
    confirmPasswordMatch: 'Passwords must match',
  },
  uploadPictureModal: {
    title: 'Upload profile picture',
    inputLabel: 'Attach picture',
    inputPlaceholder: 'Select picture',
    inputButton: 'Choose',
    uploadButton: 'Upload picture',
    pictureIsRequired: 'Profile picture is required',
    unsupportedType: 'Unsupported image type',
  },
  googleAuth: {
    title: 'Two-factor authentication',
    phoneActivateInfo: 'More information about setup mobile device',
    unknownStep: '',
    step1: {
      title: 'Download',
      firstLabel: 'Download TOTP app',
      firstContent: `Firstly you have to download the TOTP(Time-based One-time Password) mobile application.
       This will allow you to connect your mobile device with GenomSys two factor authentication`,
      secondLabel: 'Set up your TOTP app',
      secondContent: `Follow the steps of the downloaded application to be able to connect the account`,
      lastLabel: 'Open your TOTP app and be ready to action!',
      lastContent: '',
    },
    step2: {
      title: 'Verify',
      instruction: 'Instruction',
      inputLabel: 'Enter current TOTP code',
      secretCode: 'Your secret TOTP code',
      validation: {
        invalidCode: 'Invalid TOTP code',
        min: 'Code is too short (only 6 characters)! ',
        max: 'Code is too long (only 6 characters)!',
        onlyNumbers: 'There is only numbers in code!',
        required: 'Field is required',
      },
      instructionLabel: `Current step allow you to integrate your mobile device with the
    application.
    Open your TOTP application on mobile device, find option to scan QR code and use it. After it enter displayed code and click confirm!`,
    },
    step3: {
      title: 'Finish',
      finishMainText: 'Congratulations. You have successfully turned on the two-factor authentication.',
    },
    verify: 'Verify',
    finish: 'Finish',
    back: 'Back',
    next: 'Next',
    cancel: 'Cancel',
    errorPage: 'Something goes wrong. Contact our service and report this occurrence!',
    remove2fa: {
      title: 'Remove two-factor authentication',
      text: `Currently logging with double authorization is enabled. This allows for better account protection and
                requires an authorization key when logging in to the application.`,
      removeSuccessfully: 'You have detached double verification from your account ',
      confirmTitle: 'Are you sure?',
      confirmSubtitle: 'If you remove TOTP authentication you will remove connection with your mobile device and two-factor authentication will be disable.',
    },
    requiredText: 'Enter the code from the linked mobile app',
  },
};

export default profileDetails
