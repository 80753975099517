import React, { Component } from 'react'
import * as routes from '../../constants/routes'
import { Route, Redirect, RouteProps } from 'react-router'

type TPublicRouteProps = RouteProps & {
  isAuth: boolean
  isAccountActivated?: boolean
  is2faRequired?: boolean
  component: Component | React.FC
}

const PublicRoute: React.FC<TPublicRouteProps> = ({ component: Component, isAuth, is2faRequired, ...rest }) => {
  if (is2faRequired) return <Redirect to={routes.TWO_FACTOR_AUTH} />
  const redirectPage = routes.HOME
  return <Route {...rest} render={props => (isAuth ? <Redirect to={redirectPage} /> : <Component {...props} />)} />
}

export default PublicRoute
