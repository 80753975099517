declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true // removes the `xs` breakpoint
    sm: true
    md: true
    lg: true
    xl: true
    tablet: true // adds the `tablet` breakpoint
    desktop: true
  }
}

export const theme = {
  palette: {
    primary: {
      main: '#F39200',
      contrastText: '#fff'
    },
    secondary: {
      main: '#00A19A',
      dark: '#00778F',
      light: '#22B08F',
      contrastText: '#fff'
    },
    error: {
      main: '#b3281e'
    }
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      tablet: 768,
      lg: 1025,
      desktop: 1025,
      xl: 1920
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontFamily: '"Montserrat", sans-serif'
        }
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.5em'
      }
    }
  }
}
