import axios from 'axios';

export function startAxiosLogger() {
  axios.interceptors.request.use(request => {
    return request;
  });

  axios.interceptors.response.use(response => {
    return response;
  });
}
