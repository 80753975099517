import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';

import {
  DATA_LIST_DOCTOR,
  DATA_LIST_GET_SUCCESS,
  DATA_LIST_GET_FAILURE,
} from '../../redux/myData/data';
import * as endpoints from '../../../constants/api';
import {
  DATA_LIST_DOCTOR_MODAL,
  DATA_LIST_GET_SUCCESS_MODAL,
} from '../../redux/myData/dataModal';

export function* dataListDoctor(action) {
  const { limit, offset, id, current } = action.payload;

  try {
    const url = `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.DOCTOR_DATA.replace(
      '{id}',
      id,
    )}/?limit=${limit}&offset=${offset}`;

    const result = yield axios.get(url);
    const { data } = result;

    yield put({
      type:
        action.type === DATA_LIST_DOCTOR_MODAL
          ? DATA_LIST_GET_SUCCESS_MODAL
          : DATA_LIST_GET_SUCCESS,
      payload: {
        doctor_data: { ...data, current, offset },
      },
    });
  } catch (error) {
    yield put({
      type: DATA_LIST_GET_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

export default function* watchData() {
  yield takeEvery(DATA_LIST_DOCTOR, dataListDoctor);
  yield takeEvery(DATA_LIST_DOCTOR_MODAL, dataListDoctor);
}
