import React from 'react'
import AppRouter from './Router'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { theme } from './globalTheme'
import Layout from './components/Layout/index'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJSUtils from '@date-io/dayjs'

const App = () => {
  return (
    <div className="App">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <MuiPickersUtilsProvider utils={DayJSUtils}>
          <Layout>
            <AppRouter />
          </Layout>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default App
