import apolloClient from '../../../apolloClient/userFile'

import { takeEvery, put, ForkEffect, PutEffect, CallEffect } from 'redux-saga/effects'

import {
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILURE,
  TACreateFolder,
  TCreateFolderSaga,
  TCreateFolderActions,
  TACreateFolderSuccess,
  TACreateFolderFailure
} from '../../redux/dataManagement/createFolder'

import { LOAD_FOLDER_LIST, TALoadFolderListSaga } from '../../redux/dataManagement/listData'

import { CREATE_FOLDER as CREATE_FOLDER_GQL } from '../../../graphql/mutations/folderManagement'
import { notifyError, SHOW_NOTIFY, TAShowNotify } from '../../redux/notify/notify'
import { ERROR } from '../../../constants/notfiyTypes'
import { CreateDirectory, CreateDirectoryVariables } from '../../../types/generated/CreateDirectory'
import { CommonDataManagementGeneratorActions } from './index'

type CreateFolderGenerator = CommonDataManagementGeneratorActions | PutEffect<TCreateFolderActions>

function* createFolder({ payload }: TCreateFolderSaga): Generator<CreateFolderGenerator, void, any> {
  try {
    const { folderData,  } = payload
    yield apolloClient.mutate<CreateDirectory, CreateDirectoryVariables>({
      mutation: CREATE_FOLDER_GQL,
      variables: {
        folderData: folderData
      }
    })
    yield put<TACreateFolderSuccess>({
      type: CREATE_FOLDER_SUCCESS
    })
    yield put<TALoadFolderListSaga>({
      type: LOAD_FOLDER_LIST,
      payload: {
        currentPath: payload.currentPath,
        pagination: payload.pagination
      }
    })
  } catch (error) {
    yield put<TACreateFolderFailure>({
      type: CREATE_FOLDER_FAILURE,
      payload: {
        error: error
      }
    })
    yield put<TAShowNotify>(notifyError(`${error.message}`))
  }
}

export default function*(): Generator<ForkEffect<TACreateFolder | CallEffect>, void, any> {
  yield takeEvery(CREATE_FOLDER, createFolder)
}
