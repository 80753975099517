import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import { SUCCESS, ERROR } from '../../constants/notfiyTypes'
import { makeStyles } from '@material-ui/core'
import TextWrapper from '../TextWrapper/TextWrapper'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import Grid from '@material-ui/core/Grid'
import IconButton from '../IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useAppSelector } from '../../utils/useAppSelector'

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.success.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  buttons: {
    display: 'flex',
    justify: 'space-between'
  },
  deleteButton: {
    color: theme.palette.error.main
  }
}))

const CloseButton = ({ closeToast }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <IconButton size={'small'} tooltip={'Close'} onClick={closeToast}>
      <CloseIcon style={{ color: '#fff', fontSize: 20 }} />
    </IconButton>
  </div>
)

const ToastTextWrapper = ({ message, target }) => (
  <Grid container direction={'row'} alignItems={'center'} wrap={'nowrap'}>
    {target === SUCCESS ? <CheckCircleIcon style={{ marginRight: 10 }} /> : <ErrorIcon style={{ marginRight: 10 }} />}
    <TextWrapper fontWeight={'regular'}>{message}</TextWrapper>
  </Grid>
)

const Notify = () => {
  const cl = useStyles()
  const target = useAppSelector(state => state.notify.notify.target)
  const message = useAppSelector(state => state.notify.notify.message)
  const config = useAppSelector(state => state.notify.notify.config)
  const notifyGeneral = useAppSelector(state => state.notify.notify)

  useEffect(() => {
    notify()
  }, [notifyGeneral])

  const successConfig = {
    className: cl.success
  }

  const errorConfig = {
    className: cl.error
  }

  const notify = () => {
    switch (target) {
      case SUCCESS:
        toast.success(<ToastTextWrapper message={message} target={target} />, { ...successConfig, ...config })
        break
      case ERROR:
        toast.error(<ToastTextWrapper message={message} target={target} />, { ...errorConfig, ...config })
        break
      default:
        toast(message, { ...config })
    }
  }

  return <ToastContainer position="bottom-left" autoClose={7500} style={{ zIndex: 1450 }} closeButton={CloseButton} />
}

export default Notify
