import axios from 'axios';
import { takeEvery, put, call } from 'redux-saga/effects';

import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from '../../redux/upload/upload';
import { CREATE_DATASET_RESET } from '../../redux/dataSet/dataSet';
import * as endpoints from '../../../constants/api';
import { dataListPatient } from '../profile/data';
import { dataListDoctor } from '../myData/data';
import { DATA_LIST_DOCTOR } from '../../redux/myData/data';
import { DATA_LIST_PATIENT } from '../../redux/profile/data';

function* uploadFile(action) {
  const {
    data,
    list: { role, id, limit, offset, current },
  } = action.payload;

  const dataSend = {
    file_description: data.file_description,
    file_size: data.file_size,
    file_name: data.file_name,
    file_url: data.file_url,
    id: data.id,
  };

  try {
    const url =
      role !== 'doctor'
        ? `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.UPLOAD_FILE_PATIENT.replace(
            '{id}',
            id,
          )}/`
        : `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.UPLOAD_FILE_DOCTOR.replace(
            '{id}',
            id,
          )}/`;

    yield axios.post(url, dataSend);

    yield put({
      type: UPLOAD_FILE_SUCCESS,
    });
    yield put({
      type: CREATE_DATASET_RESET,
    });
    if (role !== 'doctor') {
      yield call(dataListPatient, {
        type: DATA_LIST_PATIENT,
        payload: {
          id,
          role,
          limit,
          offset,
          current,
        },
      });
    } else {
      yield call(dataListDoctor, {
        type: DATA_LIST_DOCTOR,
        payload: {
          id,
          limit,
          offset,
          current,
        },
      });
    }
  } catch (error) {
    yield put({
      type: UPLOAD_FILE_FAILURE,
      payload: {
        error: error.response.data,
      },
    });
  }
}

export default function* watchUpload() {
  yield takeEvery(UPLOAD_FILE, uploadFile);
}
