import { all, call, cancel, fork, put, PutEffect, StrictEffect, take, takeEvery } from 'redux-saga/effects'
import { createAnalysesFolder, startAnalyses } from '../dataManagement/analyses'
import axios, { CancelTokenSource } from 'axios'
import { notifyError, notifySuccess, TAShowNotify } from '../../redux/notify/notify'
import {
  GENOMYOU_UPLOAD_FILES,
  GENOMYOU_UPLOAD_FILES_FAILURE,
  GENOMYOU_UPLOAD_FILES_SET_STATUS,
  GENOMYOU_UPLOAD_FILES_START,
  GENOMYOU_UPLOAD_FILES_SUCCESS,
  GENOMYOU_UPLOAD_FILES_UPDATE,
  GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN,
  TGenomYouUploadSaga,
  TUpdateToken,
  TUploadFilesFailure,
  TUploadFilesStart,
  TUploadFilesStatus,
  TUploadFilesSuccess,
  TUploadGenomYouActions
} from '../../redux/genomYou'
import apolloClient from '../../../apolloClient/userFile'
import {
  UPDATE_FILE_STATUS as UPDATE_FILE_STATUS_GQL,
  UPDATE_MULTIPART_FILE_STATUS as UPDATE_MULTIPART_FILE_STATUS_GQL
} from '../../../graphql/mutations/fileManagement'
import { UploadedFileData } from '../dataManagement/createFile'
import dayjs from 'dayjs'
import { CHECK_FASTQ_FILENAMES } from '../../../graphql/queries/genomYou'
import { CommonDataManagementGeneratorActions } from '../dataManagement'
import { TCreateFileActions } from '../../redux/dataManagement/createFiles'
import { CheckFastqFilenames, CheckFastqFilenamesVariables } from '../../../types/generated/CheckFastqFilenames'
import { ApolloQueryResult } from '@apollo/client'
import { TRightBottomNotifyActions } from '../../redux/notifications/rightBottom'
import { UpdateFileStatus, UpdateFileStatusVariables } from '../../../types/generated/UpdateFileStatus'
import {
  UpdateMultipartFileStatus,
  UpdateMultipartFileStatusVariables
} from '../../../types/generated/UpdateMultipartFileStatus'
import { createDatabaseFile, CreatedFileData } from '../../../utils/uploadProcess/createDatabaseFile'
import { NUMBER_OF_RETRIES, RETRY_TIMEOUT } from '../../../constants/uploadConstants'
import { uploadWrapper } from '../../../utils/uploadProcess/uploadWrapper'
import { watcherLimitedRequests, watchOnForkFinish } from 'src/utils/uploadProcess/watcherLimitedRequests'
import { v4 as uuidv4 } from 'uuid'

type TWatchOnProgressGenerator = PutEffect<TRightBottomNotifyActions> | StrictEffect

function* watchOnProgress(chan: any): Generator<TWatchOnProgressGenerator, void, any> {
  while (true) {
    const data = yield take(chan)
    yield put({
      type: GENOMYOU_UPLOAD_FILES_UPDATE,
      payload: data
    })
  }
}

function* onGenerateToken(token: CancelTokenSource, uuid: string) {
  yield put<TUpdateToken>({
    type: GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN,
    payload: { uuid, cancelToken: token }
  })
}

type TUploadSingleFileGenomYouGenerator =
  | CommonDataManagementGeneratorActions
  | PutEffect<TCreateFileActions>
  | StrictEffect

function* uploadSingleFile(
  chan: any,
  uniqueId: string
): Generator<TUploadSingleFileGenomYouGenerator, UploadedFileData | undefined, any> {
  while (true) {
    const { tempData, urls, blob, uploadId, chunkSize } = yield take(chan)
    try {
      let start = 0
      let uploadedSize = 0
      let eTags: Array<string> = []
      let cancelTokenValue = tempData.cancelToken
      let retryCount = 0
      let isNewCancelTokenGenerate = false
      for (let i = 0; i < urls?.length!; i++) {
        try {
          const newEtag = yield call(
            uploadWrapper,
            isNewCancelTokenGenerate,
            cancelTokenValue!,
            tempData,
            blob,
            start,
            chunkSize,
            urls![i],
            uploadedSize,
            watchOnProgress,
            onGenerateToken
          )
          eTags = [...eTags, newEtag]
          start += chunkSize
          uploadedSize = (i + 1) * chunkSize
          retryCount = 0
        } catch (e) {
          const isTimeout = e.message === 'TimeoutError'
          if (axios.isCancel(e) && e.message !== 'TimeoutError') throw e
          else {
            if (retryCount < NUMBER_OF_RETRIES) {
              retryCount++
              if (!isTimeout) yield new Promise(r => setTimeout(r, RETRY_TIMEOUT))
              i--
              isNewCancelTokenGenerate = true
            } else {
              throw e
            }
          }
        }
      }
      yield apolloClient.mutate<UpdateMultipartFileStatus, UpdateMultipartFileStatusVariables>({
        mutation: UPDATE_MULTIPART_FILE_STATUS_GQL,
        variables: {
          uuid: tempData.uuid,
          partial: false,
          inUse: false,
          etags: eTags,
          uploadId: uploadId
        }
      })
      yield put<TUploadFilesStatus>({
        type: GENOMYOU_UPLOAD_FILES_SET_STATUS,
        payload: {
          uuid: tempData.uuid,
          status: 'success'
        }
      })
      yield put({
        type: `AFTER_OPERATION_${uniqueId}`,
        payload: {
          uuid: tempData.uuid,
          name: tempData.name!
        }
      })
    } catch (error) {
      yield put<TUploadFilesFailure>({
        type: GENOMYOU_UPLOAD_FILES_FAILURE,
        payload: error
      })
      try {
        yield apolloClient.mutate<UpdateFileStatus, UpdateFileStatusVariables>({
          mutation: UPDATE_FILE_STATUS_GQL,
          variables: {
            uuid: tempData.uuid,
            partial: true,
            inUse: false
          }
        })
      } catch (error) {
        yield put({
          type: `AFTER_OPERATION_${uniqueId}`,
          payload: error
        })
      }
      yield put({
        type: `AFTER_OPERATION_${uniqueId}`,
        payload: error
      })
    }
  }
}

type TUploadFilesGenerator = CommonDataManagementGeneratorActions | PutEffect<TUploadGenomYouActions> | StrictEffect

function* uploadFiles({ payload }: TGenomYouUploadSaga): Generator<TUploadFilesGenerator, void, any> {
  const { data } = payload
  let response: CreatedFileData[]
  let task
  try {
    const { data: checkData }: ApolloQueryResult<CheckFastqFilenames> = yield apolloClient.query<
      CheckFastqFilenames,
      CheckFastqFilenamesVariables
    >({
      query: CHECK_FASTQ_FILENAMES,
      variables: {
        filenames: data.map(item => item.fileData.name)
      }
    })
    const prefix = checkData.checkFastqFilenames.commonName
    const analysesDirectoryName = `Analysis-${prefix}-${dayjs().format('YYYY-MM-DD--HH-mm-ss')}`
    const analysesFolderUuid: string = yield call(createAnalysesFolder, analysesDirectoryName)
    const mappedData = data.map(item => ({
      ...item,
      fileData: { ...item.fileData, parentUuid: analysesFolderUuid }
    }))

    response = yield all(mappedData.map(item => call(createDatabaseFile, item)))
    yield put<TUploadFilesStart>({
      type: GENOMYOU_UPLOAD_FILES_START,
      payload: response.map(item => item.tempData)
    })

    const uniqueId = uuidv4()

    // @ts-ignore
    task = yield fork(watcherLimitedRequests, uploadSingleFile, uniqueId)

    for (const item of response) {
      yield put({ type: `START_OPERATION_${uniqueId}`, payload: item })
    }

    const uploaded: Array<UploadedFileData> = yield call(watchOnForkFinish, response.length, uniqueId, true)

    if (uploaded.some(item => item instanceof Error || !item || axios.isCancel(item)))
      throw new Error('OperationFailed')

    yield cancel(task)

    yield put<TUploadFilesSuccess>({
      type: GENOMYOU_UPLOAD_FILES_SUCCESS
    })

    yield call(
      startAnalyses,
      uploaded,
      prefix,
      analysesFolderUuid,
      data[0].species !== undefined ? data[0].species : 'Homo Sapiens'
    )
    yield put(notifySuccess('Analysis started'))
  } catch (error) {
    // @ts-ignore
    console.log('response', response)
    console.log('error', error)
    response.forEach(({ tempData }) => {
      tempData.cancelToken!.cancel()
    })

    if (!axios.isCancel(error) || (axios.isCancel(error) && error.message === 'TimeoutError')) {
      yield put<TUploadFilesFailure>({
        type: GENOMYOU_UPLOAD_FILES_FAILURE,
        payload: error.message
      })
      yield put<TAShowNotify>(notifyError(`${error.message}`))
    }
    if (task) yield cancel(task)
  }
}

export default function* watchUploadFiles() {
  yield takeEvery(GENOMYOU_UPLOAD_FILES, uploadFiles)
}
