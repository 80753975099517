const userAdd = {
  registerUser: 'Add user',
  confirmTitle: 'Add user?',
  confirmSubtitle: 'Add a user with the following details: ',
  successfulRegistrationInfo:
    'The user has been registered and an activation email was sent at this address: ',
  goBack: 'GO BACK TO USER LIST',
  editUser: 'EDIT REGISTERED USER',
}

export default userAdd