export const GET_EVENTS: 'logger/GET_EVENTS' = 'logger/GET_EVENTS';
export const GET_EVENTS_SUCCESS: 'logger/GET_EVENTS_SUCCESS' =
  'logger/GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE: 'logger/GET_EVENTS_FAILURE' =
  'logger/GET_EVENTS_FAILURE';

type filtersT = {
  nameIcontains?: string,
  source?: string,
  subjectType?: string,
  targetType?: string,
  creationTimeGte?: Date | string,
  creationTimeLte?: Date | string,
};

type paginationT = {
  offset: number,
  first: number,
  orderBy: string,
};

type getEvents = {
  type: typeof GET_EVENTS,
};

type getEventsSuccess = {
  type: typeof GET_EVENTS_SUCCESS,
  payload: any,
};

type getEventsFailure = {
  type: typeof GET_EVENTS_FAILURE,
  payload: any,
};
type ActionType = getEventsSuccess | getEventsFailure | getEvents;

export type State = {
  loading: boolean,
  error: ?string,
  events: Array,
};

const initialState: State = {
  loading: false,
  events: [],
  totalCount: 0,
  error: null,
};

export default function dataSetReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...initialState,
        events: action.payload.events,
        totalCount: action.payload.totalCount,
        loading: false,
        error: null,
      };

    case GET_EVENTS_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function loadEvents(pagination: paginationT, filters: filtersT) {
  return {
    type: GET_EVENTS,
    payload: { pagination, filter: filters },
  };
}
