import { combineReducers } from 'redux'

import auth, { AuthState } from './auth'
import patients from './patients'
import profile, { TProfile } from './profile';
import myData from './myData'
import sharing from './sharing'
import upload from './upload'
import dataSet from './dataSet'
import userManagement from './userManagement'
import dataManagement, { TDataManagement } from './dataManagement'
import logger from './logger'
import notifications, { TNotifications } from './notifications';
import notify, { TNotify } from './notify'
import genomYou, { TGenomYouState } from './genomYou'

export type TAppState = {
  auth: AuthState
  patients: any
  myData: any
  profile: TProfile
  sharing: any
  upload: any
  dataSet: any
  userManagement: any
  dataManagement: TDataManagement
  logger: any
  notifications: TNotifications
  notify: TNotify
  genomYou: TGenomYouState
}

const appReducer = combineReducers<TAppState>({
  auth,
  patients,
  myData,
  profile,
  sharing,
  upload,
  dataSet,
  userManagement,
  dataManagement,
  logger,
  notifications,
  notify,
  genomYou
})

// export default function(state: AppState, action: { type: string }) {
//   return appReducer(state, action)
// }

export default appReducer
