import React from 'react'
import { List } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import ListItemText from '@material-ui/core/ListItemText'
import TextWrapper from '../../../../../../components/TextWrapper/TextWrapper'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '../../../../../../components/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Grid from '@material-ui/core/Grid'
import { TRightBottomNotifyData } from '../../../../../../store/redux/notifications/rightBottom'
import i18n from '../../../../../../locales/i18next'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(() => ({
  avatar: {
    minWidth: 'auto',
    marginRight: 10,
    display: 'flex',
    alignItem: 'center'
  },
  list: {},
  listItem: {},
  textWrapper: {
    maxWidth: 400,
    paddingRight: 40,
    color: '#000'
  }
}))

export const closeAction = (data: TRightBottomNotifyData[]) => {
  data.forEach(({ cancelToken, status }) => {
    if (status === 'pending' || status === 'waiting') {
      cancelToken!.cancel()
    }
  })
}

const BottomNotification = ({ data }: { data: TRightBottomNotifyData[] }) => {
  const cl = useStyles()
  return (
    <List className={cl.list}>
      {data.map(({ name, progress, status, cancelToken }, index) => (
        <ListItem key={index} className={cl.listItem}>
          <ListItemAvatar className={cl.avatar}>
            <InsertDriveFileIcon color={'primary'} />
          </ListItemAvatar>
          <ListItemText className={cl.textWrapper} disableTypography primary={<TextWrapper>{name}</TextWrapper>} />
          <ListItemSecondaryAction>
            <Grid container alignItems={'center'}>
              <Box position="relative" display="inline-flex">
                {status === 'pending' || status === 'waiting' ? (
                  <>
                    {status === 'waiting' ? (
                      <Tooltip title="File is waiting for upload">
                        <HourglassEmptyIcon color={'secondary'} style={{ fontSize: 24, marginRight: 3 }} />
                      </Tooltip>
                    ) : (
                      <>
                        <CircularProgress size={35} variant="determinate" value={progress} />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center">
                          <Typography
                            variant="caption"
                            component="div"
                            color="secondary"
                            style={{ fontSize: '0.7rem' }}>{`${progress}%`}</Typography>
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      width: 35,
                      height: 35,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    {status === 'success' ? (
                      <CheckCircleIcon color={'secondary'} style={{ fontSize: 25 }} />
                    ) : (
                      <HighlightOffIcon style={{ fontSize: 25, color: 'red' }} />
                    )}
                  </div>
                )}
              </Box>
              {status === 'pending' && (
                <IconButton
                  size={'small'}
                  tooltip={i18n.t('pages.profilePage.notification.cancelUpload')}
                  style={{ marginLeft: 5 }}
                  onClick={() => cancelToken!.cancel()}>
                  <HighlightOffIcon style={{ color: 'black' }} />
                </IconButton>
              )}
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

export default BottomNotification
