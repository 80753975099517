// @flow
export const SHARE_TO: 'patients/SHARE_TO' = 'patients/SHARE_TO';
export const SHARE_TO_SUCCESS: 'patients/SHARE_TO_SUCCESS' =
  'patients/SHARE_TO_SUCCESS';
export const SHARE_TO_FAILURE: 'patients/SHARE_TO_FAILURE' =
  'patients/SHARE_TO_FAILURE';

type ShareDataT = {
  email: string,
  id: string,
};

type Share = {
  type: typeof SHARE_TO,
};

export type ShareSuccess = {
  type: typeof SHARE_TO_SUCCESS,
  payload: any,
};

type ShareFailure = {
  type: typeof SHARE_TO_FAILURE,
  payload: any,
};

type ActionType = Share | ShareSuccess | ShareFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  data: [],
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case SHARE_TO:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SHARE_TO_SUCCESS:
      return {
        ...initialState,
        loading: false,
        error: null,
      };

    case SHARE_TO_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function shareTo(data: ShareDataT) {
  return {
    type: SHARE_TO,
    payload: data,
  };
}
