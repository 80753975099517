import { UserProfileUpdate } from '../../../types/generated/globalTypes'
import { UpdateUserVariables } from '../../../types/generated/UpdateUser'

export const USER_UPDATE: 'userEdit/USER_UPDATE' = 'userEdit/USER_UPDATE'
export const USER_UPDATE_GET_SUCCESS: 'userEdit/USER_UPDATE_GET_SUCCESS' = 'userEdit/USER_UPDATE_GET_SUCCESS'
export const USER_UPDATE_GET_FAILURE: 'userEdit/USER_UPDATE_GET_FAILURE' = 'userEdit/USER_UPDATE_GET_FAILURE'

export const CHANGE_ACCOUNT_ACTIVE_STATE: 'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE' =
  'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE'
export const CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE: 'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE' =
  'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE'

export type TUserUpdate = {
  type: typeof USER_UPDATE
  payload: UpdateUserVariables
}

export type TUserUpdateGetSuccess = {
  type: typeof USER_UPDATE_GET_SUCCESS
  payload: {
    user: UserProfileUpdate
  }
}

type TUserUpdateGetFailure = {
  type: typeof USER_UPDATE_GET_FAILURE
  payload: any
}

type TChangeAccountActiveState = {
  type: typeof CHANGE_ACCOUNT_ACTIVE_STATE
}

export type TChangeAccountActiveStateFailure = {
  type: typeof CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE
  payload: any
}

type ActionType =
  | TUserUpdate
  | TUserUpdateGetSuccess
  | TUserUpdateGetFailure
  | TChangeAccountActiveState
  | TChangeAccountActiveStateFailure

export type TUserEditState = {
  loading: boolean
  error?: string | null
  user: UserProfileUpdate
}

const initialState: TUserEditState = {
  loading: false,
  user: {},
  error: null
}

export default function userReducer(state: TUserEditState = initialState, action: ActionType): TUserEditState {
  switch (action.type) {
    case USER_UPDATE:
      return {
        ...initialState,
        loading: true,
        error: null
      }

    case USER_UPDATE_GET_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        loading: false,
        error: null
      }

    case USER_UPDATE_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error
      }

    // case CHANGE_ACCOUNT_ACTIVE_STATE:
    //   return {
    //     ...initialState
    //   }

    // case CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE:
    //   return {
    //     ...initialState,
    //     error: action.payload.error
    //   }

    default:
      return state
  }
}

export function updateUser(data: UpdateUserVariables): TUserUpdate {
  return {
    type: USER_UPDATE,
    payload: data
  }
}

// export function changeAccountActiveState(state) {
//   return {
//     type: CHANGE_ACCOUNT_ACTIVE_STATE,
//     payload: state
//   }
// }
