// @flow
export const SHARING_LIST: 'sharing/SHARING_LIST' = 'sharing/SHARING_LIST';
export const SHARING_LIST_GET_SUCCESS: 'sharing/SHARING_LIST_GET_SUCCESS' =
  'sharing/SHARING_LIST_GET_SUCCESS';
export const SHARING_LIST_GET_FAILURE: 'sharing/SHARING_LIST_GET_FAILURE' =
  'sharing/SHARING_LIST_GET_FAILURE';

export const SHARING_ACTION: 'sharing/SHARING_ACTION' =
  'sharing/SHARING_ACTION';
export const SHARING_ACTION_SUCCESS: 'sharing/SHARING_ACTION_SUCCESS' =
  'sharing/SHARING_ACTION_SUCCESS';
export const SHARING_ACTION_FAILURE: 'sharing/SHARING_ACTION_FAILURE' =
  'sharing/SHARING_ACTION_FAILURE';

export const SHARING_GET_STATUS: 'sharing/SHARING_GET_STATUS' =
  'sharing/SHARING_GET_STATUS';
export const SHARING_GET_STATUS_SUCCESS: 'sharing/SHARING_GET_STATUS_SUCCESS' =
  'sharing/SHARING_GET_STATUS_SUCCESS';
export const SHARING_GET_STATUS_FAILURE: 'sharing/SHARING_GET_STATUS_FAILURE' =
  'sharing/SHARING_GET_STATUS_FAILURE';

type sharingDataT = {
  event: string,
  id: string,
};

type sharingStatusDataT = {
  id: string,
};

type sharingListT = {
  params: string,
};

type ListStatus = {
  type: typeof SHARING_GET_STATUS,
};

export type StatisGetSuccess = {
  type: typeof SHARING_GET_STATUS_SUCCESS,
  payload: any,
};

type StatusGetFailure = {
  type: typeof SHARING_GET_STATUS_FAILURE,
  payload: any,
};

type List = {
  type: typeof SHARING_LIST,
};

export type DataGetSuccess = {
  type: typeof SHARING_LIST_GET_SUCCESS,
  payload: any,
};

type DataGetFailure = {
  type: typeof SHARING_LIST_GET_FAILURE,
  payload: any,
};

type SharingAction = {
  type: typeof SHARING_ACTION,
};

export type SharingActionSuccess = {
  type: typeof SHARING_ACTION_SUCCESS,
  payload: any,
};

type SharingActionFailure = {
  type: typeof SHARING_ACTION_FAILURE,
  payload: any,
};

type ActionType =
  | List
  | DataGetSuccess
  | DataGetFailure
  | SharingAction
  | SharingActionSuccess
  | SharingActionFailure
  | ListStatus
  | StatisGetSuccess
  | StatusGetFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  results: null,
  statusList: [],
  error: null,
};

export default function sharingReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case SHARING_LIST:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SHARING_LIST_GET_SUCCESS:
      return {
        ...initialState,
        results: action.payload.sharing,
        loading: false,
        error: null,
      };

    case SHARING_LIST_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    case SHARING_ACTION:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SHARING_ACTION_SUCCESS:
      return {
        ...initialState,
        loading: false,
        error: null,
      };

    case SHARING_ACTION_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    case SHARING_GET_STATUS:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SHARING_GET_STATUS_SUCCESS:
      return {
        ...initialState,
        statusList: action.payload.statusList,
        loading: false,
        error: null,
      };

    case SHARING_GET_STATUS_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function sharingList(params: sharingListT) {
  return {
    type: SHARING_LIST,
    payload: params,
  };
}

export function sharingAction(data: sharingDataT, list: any) {
  return {
    type: SHARING_ACTION,
    payload: { data, list },
  };
}

export function sharingStatus(id: sharingStatusDataT) {
  return {
    type: SHARING_GET_STATUS,
    payload: id,
  };
}
