// @flow
export const HOME = '/'
export const PATIENTS = '/patients'
export const LOGIN = '/login'
export const RESET_PASSWORD = '/reset-password'
export const REGISTER = '/register'
export const REGISTER_SUCCESSFUL = '/register-success'
export const DATA = '/my-data'
export const ABOUT = '/about-us'
export const CONTACTS = '/contacts'
export const PATIENT_PROFILE = '/profile/:id'
export const MY_PROFILE_DETAILS = '/my-profile-data'
export const USER_LIST = '/users'
export const USER_DETAILS = '/user-details/:id'
export const USER_ADD = '/add-user'
export const RESEND_EMAIL = '/resend-email'
export const LOGGER = '/logs'
export const NOTIFICATIONS = '/notifications'
export const TWO_FACTOR_AUTH = '/two-factor-auth'
export const ADMIN = '/admin'
