// @flow
export const PATIENTS_LIST: 'patients/PATIENTS_LIST' = 'patients/PATIENTS_LIST';
export const PATIENTS_GET_SUCCESS: 'patients/PATIENTS_GET_SUCCESS' =
  'patients/PATIENTS_GET_SUCCESS';
export const PATIENT_GET_FAILURE: 'patients/PATIENT_GET_FAILURE' =
  'patients/PATIENT_GET_FAILURE';

type PatientsListT = {
  params: string,
};

type List = {
  type: typeof PATIENTS_LIST,
};

export type PatientsGetSuccess = {
  type: typeof PATIENTS_GET_SUCCESS,
  payload: any,
};

type PatientsGetFailure = {
  type: typeof PATIENT_GET_FAILURE,
  payload: any,
};

type ActionType = List | PatientsGetSuccess | PatientsGetFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  patients: [],
  error: null,
};

export default function patientsReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case PATIENTS_LIST:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case PATIENTS_GET_SUCCESS:
      return {
        ...initialState,
        patients: action.payload.patients,
        loading: false,
        error: null,
      };

    case PATIENT_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function patientsList(params: PatientsListT) {
  return {
    type: PATIENTS_LIST,
    payload: params,
  };
}
