import apolloClient from '../../../apolloClient/userFile'
import { put, takeEvery, call, PutEffect, StrictEffect } from 'redux-saga/effects'
import {
  getAnalysesByUuid,
  START_ALITHEA_ANALYSES,
  TAnalysesDataSaga,
  TStartAlitheaAnalysesSaga
} from 'src/store/redux/dataManagement/analyses'
import { notifyError, notifySuccess, TAShowNotify, TNotifyActions } from '../../redux/notify/notify'
import {
  //GET_ANALYSIS_BY_DIRECTORY_UUID,
  GET_ANALYSIS_CREATE_FOLDER_DATA as GET_ANALYSIS_CREATE_FOLDER_DATA_GQL,
  GET_ANALYSIS_UUID
} from '../../../graphql/queries/analysis'
import { CREATE_FOLDER as CREATE_FOLDER_GQL } from '../../../graphql/mutations/folderManagement'
import dayjs from 'dayjs'
import {
  RUN_ANALYSIS as RUN_ANALYSIS_GQL,
  UPDATE_FILE_STATUS,
  UPLOAD_FILE,
  GY_CREATE_ANALYSYS_RUN
} from '../../../graphql/mutations/fileManagement'
import { ApolloQueryResult, FetchResult } from '@apollo/client'
import { GetAnalysisNecessaryData } from '../../../types/generated/GetAnalysisNecessaryData'
import { CreateDirectory, CreateDirectoryVariables } from '../../../types/generated/CreateDirectory'
import { GetAnalysisUuid, GetAnalysisUuidVariables } from '../../../types/generated/GetAnalysisUuid'
import { CreateAnalysisRun, CreateAnalysisRunVariables } from '../../../types/generated/CreateAnalysisRun'
import { UploadedFileData } from './createFile'
import { TFile } from '../../redux/dataManagement/listData'

import { UploadFile, UploadFileVariables } from 'src/types/generated/UploadFile'
import axios from 'axios'
import { UpdateFileStatus, UpdateFileStatusVariables } from 'src/types/generated/UpdateFileStatus'
import { SampleInput, SampleInputFileInput } from 'src/types/generated/globalTypes'

import { speciesConfig } from '../../../constants/analysis'

const longest_matching_prefix = (name1: string, name2: string) => {
  const split1 = name1.split('')
  const split2 = name2.split('')
  let res = ''
  for (let i = 0; i < split1.length; i++) {
    if (split1[i] !== split2[i]) break
    else res += split1[i]
  }
  return res
}

export const sequencePrefix = (name1: string, name2: string) => {
  const reg = new RegExp('([^a-zA-Z0-9]+[rR]?)?(\\.(fastq|fq)(\\.gz)?[^a-zA-Z0-9]*)?$', 'g')
  const name = longest_matching_prefix(name1, name2)
  // @ts-ignore
  const trimPart = name
    .match(reg)
    .filter(Boolean)
    .shift()
  return name.replace(trimPart as string, '')
}

type CreateAnalysesFolderGenerator = Promise<FetchResult> | PutEffect<TNotifyActions>

export function* createAnalysesFolder(
  analysesDirectoryName: string
): Generator<CreateAnalysesFolderGenerator, string | undefined, any> {
  try {
    const { data }: ApolloQueryResult<GetAnalysisNecessaryData> = yield apolloClient.query<GetAnalysisNecessaryData>({
      query: GET_ANALYSIS_CREATE_FOLDER_DATA_GQL
    })
    const rootFolderUuid = data?.rootFolder?.uuid
    let analysesFolderUuid
    if (!data.analysesFolder) {
      const { data }: ApolloQueryResult<CreateDirectory> = yield apolloClient.query<
        CreateDirectory,
        CreateDirectoryVariables
      >({
        query: CREATE_FOLDER_GQL,
        variables: {
          folderData: {
            name: 'analyses',
            parentUuid: rootFolderUuid,
            description: 'Directory for all analyses'
          }
        }
      })
      analysesFolderUuid = data?.createDirectory?.resDirectory?.uuid
    } else {
      analysesFolderUuid = data.analysesFolder.uuid
    }

    const { data: newAnalysesFolder }: ApolloQueryResult<CreateDirectory> = yield apolloClient.query<
      CreateDirectory,
      CreateDirectoryVariables
    >({
      query: CREATE_FOLDER_GQL,
      variables: {
        folderData: {
          name: analysesDirectoryName,
          parentUuid: analysesFolderUuid,
          description: 'Single analyses'
        }
      }
    })
    return newAnalysesFolder?.createDirectory?.resDirectory?.uuid
  } catch (e) {
    yield put<TAShowNotify>(notifyError(`${e.message}`))
    throw e
  }
}

type StartAnalysesGenerator = Promise<FetchResult> | PutEffect<TNotifyActions> | StrictEffect

export function* startAnalyses(
  uploaded: UploadedFileData[],
  prefixName: string | null = null,
  takenAnalysesFolderUuid: string | null = null,
  species: string | null = 'Homo Sapiens'
): Generator<StartAnalysesGenerator, void, any> {
  let analysisConfig = speciesConfig.filter(element => element.name === species)

  console.log('analisys config', analysisConfig)

  try {
    const { data } = yield apolloClient.query({
      query: GET_ANALYSIS_UUID,
      variables: {
        refGenome: analysisConfig[0].reference
      }
    })

    console.log('data', data)

    const prefix = prefixName || sequencePrefix(uploaded[0].name, uploaded[1].name)
    const analyseDirectoryName = `Analysis-${prefix}-${dayjs().format('YYYY-MM-DD--HH-mm-ss')}`
    const analyseFolderUUid = takenAnalysesFolderUuid
      ? takenAnalysesFolderUuid
      : yield call(createAnalysesFolder, analyseDirectoryName)

    if (data.analysesByRefGenome.length === 0) {
      throw new Error('No analyses available for this reference genome')
    }
    const analyses = data.analysesByRefGenome.filter(analysis => analysis.name === analysisConfig[0].analysisName)
    console.log('analyses', analyses)
    const analysesUuid = analyses[0].uuid

    const type = uploaded[0].name.includes('.fastq') ? 'fastq' : 'fq'

    yield apolloClient.mutate({
      mutation: GY_CREATE_ANALYSYS_RUN,
      variables: {
        name: prefix,
        fileType: type,
        directoryUuid: analyseFolderUUid,
        description: prefix,
        fileUuidList: uploaded.map(item => item.uuid),
        genomicSequencingUuid: null,
        sampleType: analysisConfig[0].sampleType,
        referenceGenome: analysisConfig[0].reference,
        analysisTypes: [analysisConfig[0].analysisType],

        analysisUuid: analysesUuid
      }
    })
  } catch (error) {
    yield put<TAShowNotify>(notifyError(`${error.message}`))
    //throw error
  }
}

export type TAlitheaSampleContent = {
  sample_name: string
  reads: string[]
  sample_input_sheet?: string
}

export type TAlitheaSample = {
  sample: TAlitheaSampleContent
}

export type TAlitheaConfig = {
  project_name: string
  samples: TAlitheaSample[]
  organism: string
  assembly_version: string
  barcode_version: string
}

export type TStartAlitheaAnalysesData = {
  payload: {
    config: TAlitheaConfig
    directoryData: TFile[]
    parentId: MyTypeUUID
  }
}

type TStartAlitheaAnalysesGenerator =
  | Promise<FetchResult>
  | PutEffect<TNotifyActions>
  | StrictEffect
  | Promise<Response>

export type TAnalysesUuid = {
  payload: {
    uuid: string
  }
}

function* startAlitheaAnalyses({
  payload
}: TStartAlitheaAnalysesData): Generator<TStartAlitheaAnalysesGenerator, void, any> {
  try {
    const { config, directoryData, parentId } = payload
    let configUuid
    let analysesUuid

    // Save config file
    {
      let instance = axios.create()

      delete instance.defaults.headers

      let { data }: ApolloQueryResult<UploadFile> = yield apolloClient.mutate<UploadFile, UploadFileVariables>({
        mutation: UPLOAD_FILE,
        variables: {
          fileData: {
            name: 'user-config.json',
            parentUuid: parentId
          },
          forceOverride: true
        }
      })
      let url = data?.uploadFile?.uploadUrl!

      configUuid = data?.uploadFile?.resFile?.uuid!

      yield call(() =>
        instance.put(url, new Blob([new TextEncoder().encode(JSON.stringify(config))]), {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      )
      yield apolloClient.mutate<UpdateFileStatus, UpdateFileStatusVariables>({
        mutation: UPDATE_FILE_STATUS,
        variables: {
          uuid: configUuid,
          partial: false,
          inUse: false
        }
      })
    }

    // Obtain analysis uuid
    {
      let { data }: ApolloQueryResult<GetAnalysisUuid> = yield apolloClient.query<
        GetAnalysisUuid,
        GetAnalysisUuidVariables
      >({
        query: GET_ANALYSIS_UUID,
        variables: {
          analysesName: process.env.REACT_APP_ANALYSES_TYPE!
        }
      })
      if (data?.analyses?.edges?.length !== 1) {
        throw new Error('Two types of analyses are forbidden')
      }
      analysesUuid = data?.analyses?.edges[0]?.node?.uuid
    }

    // Start the analysis
    {
      let libraries = config.samples.map(
        (a): SampleInput => ({
          name: a.sample.sample_name,
          type: 'fastq',
          inputFiles: a.sample.reads.map(
            (f): SampleInputFileInput => {
              let tmp = directoryData.find(file => file.name === f)

              return {
                specificationName: 'Sequencing data',
                name: tmp?.name!,
                fileUuid: tmp?.uuid
              }
            }
          )
        })
      )
      let inputFiles = [{ specificationName: 'User input', name: 'User input', fileUuid: configUuid }]

      if (config.samples[0].sample.sample_input_sheet) {
        let tmp = directoryData.filter(file => file.name.match(/.*\.xlsx$/))[0]

        libraries[0].inputFiles!.push({
          specificationName: 'Sample input table',
          name: tmp?.name!,
          fileUuid: tmp?.uuid
        } as SampleInputFileInput)
      }
      yield apolloClient.mutate<CreateAnalysisRun, CreateAnalysisRunVariables>({
        mutation: RUN_ANALYSIS_GQL,
        variables: {
          data: {
            analysisUuid: analysesUuid!,
            samples: libraries,
            directoryUuid: parentId,
            description: '',
            name: config.project_name,
            inputFiles
          }
        }
      })

      yield put<TAnalysesDataSaga>(
        getAnalysesByUuid({
          uuid: parentId
        })
      )
      yield put<TAShowNotify>(notifySuccess('Analysis successfully started!'))
    }

    // Reload the page
    window.location.reload()
  } catch (error) {
    yield put<TAShowNotify>(notifyError(`${error.message}`))
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function*(): Generator<StrictEffect, void, any> {
  yield takeEvery<TStartAlitheaAnalysesSaga>(START_ALITHEA_ANALYSES, startAlitheaAnalyses)
  //yield takeEvery<TAnalysesDataSaga>(ANALYSES_DATA, getAnalysesByDirectoryUuid)
}
