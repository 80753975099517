// @flow
export const USER_DELETE: 'userManagement/USER_DELETE' =
  'userManagement/USER_DELETE';
export const USER_DELETE_SUCCESS: 'userManagement/USER_DELETE_SUCCESS' =
  'userManagement/USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE: 'userManagement/USER_DELETE_FAILURE' =
  'userManagement/USER_DELETE_FAILURE';
export const RESET_IS_SUCCESS: 'userManagement/RESET_IS_SUCCESS' =
  'userManagement/RESET_IS_SUCCESS';

type UserDeleteT = {
  id: string,
};
type UserDelete = {
  type: typeof USER_DELETE,
};

export type UserDeleteSuccess = {
  type: typeof USER_DELETE_SUCCESS,
  payload: any,
};

type UserDeleteFailure = {
  type: typeof USER_DELETE_FAILURE,
  payload: any,
};
type ResetIsSuccess = {
  type: typeof RESET_IS_SUCCESS,
  payload: any,
};

type ActionType = UserDelete | UserDeleteSuccess | UserDeleteFailure;

export type State = {
  isSuccess: boolean,
  error: ?string,
};

const initialState: State = {
  isSuccess: false,
  error: null,
};

export default function userDeleteReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case USER_DELETE:
      return {
        ...initialState,
        isSuccess: false,
        loading: true,
        error: null,
      };

    case USER_DELETE_SUCCESS:
      return {
        ...initialState,
        isSuccess: true,
        loading: false,
        error: null,
      };

    case USER_DELETE_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    case RESET_IS_SUCCESS:
      return {
        ...initialState,
        isSuccess: false,
      };

    default:
      return state;
  }
}

export function deleteUser(data: UserDeleteT) {
  return {
    type: USER_DELETE,
    payload: data,
  };
}

export function resetIsSuccess() {
  return {
    type: RESET_IS_SUCCESS,
  };
}
