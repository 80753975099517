// @flow
import { combineReducers } from 'redux';

import file from './upload';
import uploadProfilePicture from './uploadProfilePicture';
import uploadUserProfilePicture from './uploadUserProfilePicture';
export default combineReducers({
  file,
  uploadProfilePicture,
  uploadUserProfilePicture,
});
