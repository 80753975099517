import {
  TFile
} from './listData'
import {TPayloadError} from "../../../types";
import { TAlitheaConfig } from 'src/store/saga/dataManagement/analyses';

export const START_ALITHEA_ANALYSES = 'folderManagement/START_ALITHEA_ANALYSES'
export const ANALYSES_DATA = 'ANALYSES_DATA'
export const ANALYSES_DATA_SUCCESS = 'ANALYSES_DATA_SUCCESS'
export const ANALYSES_DATA_FAILURE = 'ANALYSES_DATA_FAILURE'

export type TStartAlitheaAnalyses = {
  config: TAlitheaConfig
  directoryData: TFile[]
  parentId: string
}

export type TStartAlitheaAnalysesSaga = {
  type: typeof START_ALITHEA_ANALYSES
  payload: TStartAlitheaAnalyses
}



export type TAnalysesStateSuccess = {
  type: typeof ANALYSES_DATA_SUCCESS
  payload: {
    analyses: TAnalysesData | null
  }
}

export type TAnalysesStateFailure = {
  type: typeof ANALYSES_DATA_FAILURE
}

export type TAnalysesData = {
  uuid: string
  state: string
  name: string
}

export type TAnalysesDataSaga = {
  type: typeof ANALYSES_DATA
  payload: TAnalysesUuid
}

export type TAnalysesState = {
  analyses: TAnalysesData | null
  loading: boolean
}

export type TAnalysesUuid = {
  uuid: string
}


const initialState: TAnalysesState = {
  analyses: null,
  loading: false,
}

export type TAnalysesStateActions = TAnalysesDataSaga | TAnalysesStateSuccess | TAnalysesStateFailure


export default function AnalysisStateReducer(state = initialState, action: TAnalysesStateActions): TAnalysesState {

  switch (action.type) {
    case ANALYSES_DATA:
      return {
        ...state,
        loading: true,
      }

    case ANALYSES_DATA_SUCCESS:
      return {
        analyses: action.payload.analyses,
        loading: false,
      }

    case ANALYSES_DATA_FAILURE:
      return {
        loading: false,
        analyses: null,
      }

    default:
      return state
  }
}

export function getAnalysesByUuid(payload: TAnalysesUuid): TAnalysesDataSaga {
  return {
    type: ANALYSES_DATA,
    payload
  }
}

export function startAnalyses(payload: TStartAlitheaAnalyses): TStartAlitheaAnalysesSaga {
  return {
    type: START_ALITHEA_ANALYSES,
    payload
  }
}
