import { gql } from '@apollo/client'

const CREATE_FOLDER = gql`
  mutation CreateDirectory($folderData: DirectoryInput!) {
    createDirectory(directoryData: $folderData) {
      resDirectory {
        uuid
      }
    }
  }
`

export { CREATE_FOLDER }
