// @flow
export const SOURCES_LIST_FETCH: string = 'sources/SUBSCRIPTION_LIST_FETCH';
export const SOURCES_LIST_GET_SUCCESS: string = 'sources/SOURCES_LIST_GET_SUCCESS';
export const SOURCES_LIST_GET_FAILURE: string = 'sources/SOURCES_LIST_GET_FAILURE';

export type EventType = {
  id: string,
  name: string
}

export type SourcesList = Array<{
  id: string,
  name: string,
  eventTypes: Array<EventType>
}>

export type SourcesFetch = {
  type: typeof SOURCES_LIST_FETCH,
};

export type SourcesGetSuccess = {
  type: typeof SOURCES_LIST_GET_SUCCESS,
  payload: {
    sources: SourcesList
  },
};

export type SourcesGetFailure = {
  type: typeof SOURCES_LIST_GET_FAILURE,
  payload: any,
};

export type ActionDataType = SourcesFetch | SourcesGetSuccess | SourcesGetFailure;

export type DataState = {
  loading: boolean,
  list: SourcesList,
  error: ?string,
};

const initialState: DataState = {
  loading: false,
  list: [],
  error: null,
};

export default function mySourcesReducer(
  state: DataState = initialState,
  action: ActionDataType,
): State {
  switch (action.type) {
    case SOURCES_LIST_FETCH:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SOURCES_LIST_GET_SUCCESS:
      return {
        ...initialState,
        list: action.payload.sources,
        loading: false,
        error: null,
      };

    case SOURCES_LIST_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export const getSourcesList = () => ({
  type: SOURCES_LIST_FETCH
})
