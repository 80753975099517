import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const CustomCheckbox = ({ name, label, onCheck, checked, checkedIcon, uncheckedIcon, disabled, style }) => {
  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={onCheck}
          name={name}
          checkedIcon={checkedIcon}
          icon={uncheckedIcon}
          disabled={disabled}
          color={'primary'}
        />
      )}
      label={label}
      name={name}
      style={{ ...style }}
    />
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  checkedIcon: PropTypes.element,
  uncheckedIcon: PropTypes.element,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default CustomCheckbox;
