const register = {
  components: {
    form: {
      firstName: 'First name',
      firstNamePlaceholder: 'Enter first name',
      lastName: 'Last name',
      lastNamePlaceholder: 'Enter last name',
      emailLabel: 'Email',
      emailPlaceholder: 'Enter email',
      passwordLabel: 'Password',
      confirmPassword: 'Confirm password',
      passwordPlaceholder: 'Enter password',
      loginButton: 'Sign in',
      registerButton: 'Sign up',
    },
  },
  schema: {
    email: 'Email is required',
    passwordRequired: 'Password is required',
    passwordMinLength: 'Password must be at least 8 characters',
    firstName: 'First name must be at most 30 characters',
    lastName: 'Last name must be at most 150 characters',
    passwordConfirm: 'Passwords must match',
  },
}

export default register