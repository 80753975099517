import React, { useState } from 'react'
import styles from './style.module.scss'
import Button from '../Button'
import i18n from '../../locales/i18next'
import Modal from '../Modal'
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox'
import Fade from '@material-ui/core/Fade'
import { Grid, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TextWrapper from '../TextWrapper/TextWrapper';

type TConfirmationModal = {
  onClose: () => void
  onConfirm: (x: boolean) => void
  showCheckbox?: boolean
  checkboxText?: string
  title: string
  subtitle: string
  open: boolean
  confirmButtonColor?: string
  customColor?: string
  errorMessage?: string | null
}

const ConfirmationModal = ({
  onClose,
  open,
  title,
  subtitle,
  onConfirm,
  showCheckbox = false,
  checkboxText,
  confirmButtonColor = 'inherit',
  customColor = 'inherit',
  errorMessage
}: TConfirmationModal) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const theme = useTheme()

  const handleOnClose = () => {
    setIsCheckboxChecked(false)
    onClose()
  }

  return (
    <Modal
      open={open}
      title={title}
      subtitle={subtitle}
      onClose={handleOnClose}
      maxWidth={'sm'}
      actions={
        <>
          <Button
            className={styles.closeButton}
            text={i18n.t('components.confirmModal.cancel')}
            handleSubmit={handleOnClose}
          />
          {showCheckbox ? (
            <Button
              disabled={!isCheckboxChecked}
              color={'secondary'}
              style={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText
              }}
              text={i18n.t('components.confirmModal.confirm')}
              handleSubmit={() => {
                onConfirm(isCheckboxChecked)
                setIsCheckboxChecked(false)
              }}
            />
          ) : (
            <Button
              style={{
                backgroundColor: confirmButtonColor,
                color: customColor
              }}
              variant={'contained'}
              color={'primary'}
              text={i18n.t('components.confirmModal.confirm')}
              handleSubmit={() => {
                onConfirm(isCheckboxChecked)
                setIsCheckboxChecked(false)
              }}
            />
          )}
        </>
      }>
      {showCheckbox && errorMessage && (
        <Fade in={showCheckbox}>
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        </Fade>
      )}
      {showCheckbox && (
        <CustomCheckbox
          label={checkboxText}
          checked={isCheckboxChecked}
          onCheck={() => setIsCheckboxChecked(!isCheckboxChecked)}
        />
      )}
    </Modal>
  )
}

export default ConfirmationModal
