// @flow
export const HOSPITALS_LIST: 'patients/HOSPITALS_LIST' =
  'patients/HOSPITALS_LIST';
export const HOSPITALS_LIST_SUCCESS: 'patients/HOSPITALS_LIST_SUCCESS' =
  'patients/HOSPITALS_LIST_SUCCESS';
export const HOSPITALS_LIST_FAILURE: 'patients/HOSPITALS_LIST_FAILURE' =
  'patients/HOSPITALS_LIST_FAILURE';

type HospitalsListT = {
  params: string,
};

type List = {
  type: typeof HOSPITALS_LIST,
};

export type HospitalsGetSuccess = {
  type: typeof HOSPITALS_LIST_SUCCESS,
  payload: any,
};

type HospitalsGetFailure = {
  type: typeof HOSPITALS_LIST_FAILURE,
  payload: any,
};

type ActionType = List | HospitalsGetSuccess | HospitalsGetFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  hospitals: null,
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case HOSPITALS_LIST:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case HOSPITALS_LIST_SUCCESS:
      return {
        ...initialState,
        hospitals: action.payload.hospitals,
        loading: false,
        error: null,
      };

    case HOSPITALS_LIST_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function hospitalsList(params: HospitalsListT) {
  return {
    type: HOSPITALS_LIST,
    payload: params,
  };
}
