export const CHANGE_PASSWORD = 'profile/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'profile/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = '/CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_CLEAR = '/CHANGE_PASSWORD_CLEAR'

export type TChangePasswordData = {
  email: string
  old_password: string
  new_password: string
}

type TChangePassword = {
  type: typeof CHANGE_PASSWORD
}

export type TChangePasswordSuccess = {
  type: typeof CHANGE_PASSWORD_SUCCESS
}

export type TChangePasswordFailure = {
  type: typeof CHANGE_PASSWORD_FAILURE
  payload: any
}

type TChangePasswordClear = {
  type: typeof CHANGE_PASSWORD_CLEAR
}

type TActionType = TChangePassword | TChangePasswordSuccess | TChangePasswordFailure | TChangePasswordClear

export type TState = {
  isSuccess: boolean
  loading: boolean
  error: string | null
}

const initialState: TState = {
  isSuccess: false,
  loading: false,
  error: null
}

export default function changePasswordReducer(state: TState = initialState, action: TActionType): TState {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...initialState,
        isSuccess: false,
        loading: true,
        error: null
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...initialState,
        isSuccess: true,
        loading: false,
        error: null
      }

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error
      }

    case CHANGE_PASSWORD_CLEAR:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export type TChangePasswortSaga = {
  type: typeof CHANGE_PASSWORD
  payload: TChangePasswordData
}

export function changePassword(data: TChangePasswordData): TChangePasswortSaga {
  return {
    type: CHANGE_PASSWORD,
    payload: data
  }
}

export function clearPasswordData() {
  return {
    type: CHANGE_PASSWORD_CLEAR
  }
}
