import i18n from '../../../locales/i18next'
import * as routes from '../../../constants/routes'

export type THeaderConfig = {
  label: string
  route: string
  disabled?: boolean
}

export const defaultMenu =
  process.env.REACT_APP_GENOMYOU === 'true'
    ? []
    : process.env.REACT_APP_ANALYSES_TYPE !== 'Alithea-RNAseq'
    ? [
        { label: i18n.t('menu.data'), route: `${routes.HOME}` },
        // { label: i18n.t('menu.about'), route: routes.ABOUT },
        // { label: i18n.t('menu.contacts'), route: routes.CONTACTS },
        { label: i18n.t('menu.admin'), route: `${routes.ADMIN}` },
        { label: i18n.t('menu.users'), route: routes.USER_LIST, disabled: true },
        { label: i18n.t('menu.events'), route: routes.LOGGER, disabled: true },
        { label: i18n.t('menu.notifications'), route: routes.NOTIFICATIONS, disabled: true }
      ]
    : [{ label: i18n.t('menu.data'), route: `${routes.HOME}` }]

export function dynamicMenu(isAdmin = false) {
  let menu = []
  menu =
    process.env.REACT_APP_GENOMYOU === 'true'
      ? []
      : process.env.REACT_APP_ANALYSES_TYPE !== 'Alithea-RNAseq'
      ? [
          { label: i18n.t('menu.data'), route: `${routes.HOME}` },
          // { label: i18n.t('menu.about'), route: routes.ABOUT },
          // { label: i18n.t('menu.contacts'), route: routes.CONTACTS },
          { label: i18n.t('menu.users'), route: routes.USER_LIST, disabled: true },
          { label: i18n.t('menu.events'), route: routes.LOGGER, disabled: true },
          { label: i18n.t('menu.notifications'), route: routes.NOTIFICATIONS, disabled: true }
        ]
      : [{ label: i18n.t('menu.data'), route: `${routes.HOME}` }]

  if (isAdmin) {
    menu.push({ label: i18n.t('menu.admin'), route: `${routes.ADMIN}` })
  }

  return menu
}
