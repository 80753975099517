const profile = {
  titlePatient: 'My profile',
  titleDoctor: 'Patient profile',
  emptyPatient: 'You have no patient data',
  emptyHospital: 'No hospitals at the moment',
  emptyDoctors: 'No doctors at the moment',
  emptySharing: 'No sharing requests at the moment',
  emptyFolder: 'No folders',
  tabs: {
    doctor: {
      data: 'Patient Data',
      hospitals: 'Patient hospitals',
      doctors: 'Patient doctors',
    },
    patient: {
      data: 'My Data',
      hospitals: 'My hospitals',
      doctors: 'My doctors',
      sharing: 'Sharing requests',
      sharedFiles: 'Shared files',
    },
  },
  table: {
    directoryTitle: 'Directories-table',
    filesTitle: 'Files-table',
    emptyDirectoryTable: 'This directory does not contains subdirectories',
    emptyFilesTable: 'This directory does not contain files',
    sortDesc: 'Descending sorting',
    sortAsc: 'Ascending sorting',
    headHospitals: {
      name: 'Name',
      doctors: 'Doctors',
      phone: 'Phone',
    },
    headDoctors: {
      name: 'Name',
      hospitals: 'Hospitals',
      phone: 'Phone',
      email: 'Email',
    },
    headFiles: {
      name: 'File name',
      size: 'Size',
      date: 'Date',
      description: 'Description',
    },
    headFolders: {
      name: 'Name',
      size: 'Size',
      date: 'Date',
      description: 'Description',
      actions: 'Actions',
    },
    headTablePatient: {
      name: 'Data set name',
      date: 'Date',
      doctors: 'Doctors',
      description: 'Description',
    },
  },
  components: {
    cardHospital: {
      doctors: 'Doctors:',
      hours: 'Opening hours:',
      contacts: 'Contacts:',
    },
    cardDoctor: {
      contacts: 'Contacts:',
      hospitals: 'Hospitals:',
    },
  },
  buttons: {
    createFolder: 'Create folder',
    uploadFile: 'Upload files',
    openWorkspace: 'Open workspace',
  },
  inputs: {
    selectLocation: 'Select new location',
    description: 'Description',
    overrideFileCheckbox: 'Do you want to override file in folder destination?',
    overrideFilesCheckbox: 'Do you want to override files in folder destination?',
    dragAndDrop: process.env.REACT_APP_ALITHEA_PROJECT === 'false' ? 'Drag and drop your paired FASTQ files \t and your project files here' : 'Drag and drop your paired FASTQ files \t and the SIT xslx file'
  },
  modal: {
    createFolder: 'Create folder',
    modifyFolder: 'Edit folder',
    uploadFile: 'Upload file',
    uploadFileSubtitle: 'Upload file to server',
    shareFile: 'Share file',
    editFolder: 'Edit folder',
    moveFolder: 'Move folder',
    moveFolderSubtitle: 'Move folder to new location',
    editFile: 'Edit file',
    editFileSubtitle: 'Edit file data',
    moveFile: 'Move file',
    moveFileSubtitle: 'Move file to new location',
    deleteFolderTitle: 'Delete folder',
    deleteFolderSubtitle: 'Are you sure? The folder will be permanently deleted.',
    deleteFolderCheckbox: 'Force delete all folders and files inside?',
  },
  message: {
    ownShareEmail: 'Cannot share file to yourself',
    userByEmailNoExist: 'User with given email does not exist'
  },
  notification: {
    cancelUpload: 'Cancel upload'
  }
}

export default profile
