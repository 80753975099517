import React from 'react'
import MenuButton from '../MenuButton/MenuButton'
import Menu from '../Menu/Menu'
import MenuRoute from '../MenuRoute/MenuRoute'
import Divider from '@material-ui/core/Divider'
import MenuLogo from '../MenuLogo/MenuLogo'
import { Grid, Toolbar } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'

type TDrawerMobileProps = {
  menu: Array<{
    label: string
    route: string
    disabled: boolean
  }>
  drawerOpen: boolean
  setDrawerOpen: (state: boolean) => void
  logout: () => void
  user: any
}

const DrawerMobile = (props: TDrawerMobileProps) => {
  const { menu, drawerOpen, setDrawerOpen, logout, user } = props
  return (
    <AppBar position="sticky" color={'inherit'} style={{ zIndex: 1250 }}>
      <Toolbar variant={'dense'} component="nav">
        <Grid container alignItems={'center'} justify={'space-between'} style={{ height: 80 }}>
          <MenuLogo />
          <MenuButton open={drawerOpen} onHandleClick={() => setDrawerOpen(!drawerOpen)} />
        </Grid>
        <Menu open={drawerOpen} onClose={() => setDrawerOpen(false)} logout={logout} email={user?.email}>
          {menu.map((value, index) => (
            <React.Fragment key={`mobile-${index}`}>
              <MenuRoute
                text={value.label}
                routePath={value.route}
                onHandleClick={() => setDrawerOpen(!drawerOpen)}
                color="#f59a9a"
                disabled={value.disabled}
              />
              <Divider style={{ margin: '0 20px' }} />
            </React.Fragment>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default DrawerMobile
