import axios, { AxiosResponse } from 'axios'
import { takeEvery, put, takeLatest, call, StrictEffect } from 'redux-saga/effects'

import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,
  TRegisterUser,
  TResendEmail,
  TRegisterUserSuccess,
  TUserRegistrationModel,
  TRegisterUserFailure,
  TResendEmailSuccess,
  TResendEmailFailure
} from '../../redux/auth/register'
import * as endpoints from '../../../constants/api'

export type RegistrationResponse = {
  data: TUserRegistrationModel
}

function* register({
  payload
}: TRegisterUser): Generator<StrictEffect, void, AxiosResponse<TUserRegistrationModel> | undefined> {
  try {
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_GS_REGISTER || process.env.REACT_APP_BASE_API_URL}${endpoints.REGISTER}`,
      payload
    )
    if (!response) throw new Error('')

    const { data } = response

    const createdUser = {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email
    }
    yield put<TRegisterUserSuccess>({
      type: REGISTER_USER_SUCCESS,
      payload: {
        user: createdUser
      }
    })
  } catch (error) {
    yield put<TRegisterUserFailure>({
      type: REGISTER_USER_FAILURE,
      payload: {
        error: error.response.data.non_field_errors ? error.response.data.non_field_errors[0] : 'Unexpected error',
        errors: error.response.data
      }
    })
  }
}

function* resendEmail({ payload }: TResendEmail): Generator<StrictEffect, void, undefined> {
  try {
    yield call(
      axios.get,
      `${process.env.REACT_APP_GS_AUTH || process.env.REACT_APP_BASE_API_URL}${endpoints.RESEND_EMAIL}?email=${payload}`
    )
    yield put<TResendEmailSuccess>({
      type: RESEND_EMAIL_SUCCESS
    })
  } catch (error) {
    yield put<TResendEmailFailure>({
      type: RESEND_EMAIL_FAILURE,
      payload: {
        error: error.response.data.detail || 'Unknown error'
      }
    })
  }
}

function* watchRegister(): Generator<StrictEffect, void, undefined> {
  yield takeEvery<TRegisterUser>(REGISTER_USER, register)
  yield takeLatest<TResendEmail>(RESEND_EMAIL, resendEmail)
}

export default watchRegister
