import React, { useState } from 'react'
import MenuRoute from '../MenuRoute/MenuRoute'
import IconButton from '../../../../IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Popover from '@material-ui/core/Popover'
import TextWrapper from '../../../../TextWrapper/TextWrapper'
import List from '@material-ui/core/List'
import i18n from '../../../../../locales/i18next'
import * as routes from '../../../../../constants/routes'
import MenuLogo from '../MenuLogo/MenuLogo'
import AppBar from '@material-ui/core/AppBar'
import { Toolbar, makeStyles, Theme } from '@material-ui/core'
import { THeaderConfig } from '../../header.config'

type TDrawer = {
  menu: THeaderConfig[]
  logout: () => void
  user: any
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    height: 80,
    [theme.breakpoints.up('xl')]: {
      padding: `0 ${theme.spacing(5)}px`,
      width: '85%',
      margin: 'auto'
    }
  }
}))

const Drawer = ({ menu, logout, user }: TDrawer) => {
  const classes = useStyles()
  const [showProfile, setShowProfile] = useState<null | HTMLElement>(null)

  const popoverId = showProfile ? 'simple-popover' : undefined
  const isPopoverOpen = Boolean(showProfile)
  return (
    <AppBar position="fixed" color={'inherit'}>
      <Toolbar className={classes.appBar} variant={'dense'} component="nav">
        <MenuLogo />
        <ul style={{ display: 'flex' }}>
          {menu.map((value, index) => (
            <MenuRoute
              text={value.label}
              color={'black'}
              routePath={value.route}
              key={index}
              disabled={value.disabled}
            />
          ))}
        </ul>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLElement>) => setShowProfile(e.currentTarget)}
            aria-describedby={popoverId}
            tooltip="User panel">
            <AccountCircleIcon color="primary" style={{ fontSize: 32 }} />
          </IconButton>
          <Popover
            id={popoverId}
            open={isPopoverOpen}
            anchorEl={showProfile}
            onClose={() => setShowProfile(null)}
            onClick={() => setShowProfile(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <nav>
              <div style={{ padding: 10 }}>
                <TextWrapper component={'p'} fontWeight="bold" align={'center'} style={{ marginBottom: 5 }}>
                  {user?.firstName} {user?.lastName}
                </TextWrapper>
                <TextWrapper align={'center'} component={'p'}>
                  {user?.email}
                </TextWrapper>
              </div>
              <List>
                {process.env.REACT_APP_GENOMYOU !== 'true' && process.env.REACT_APP_ANALYSES_TYPE !== "Alithea-RNAseq" && (
                  <MenuRoute
                    text={i18n.t('menu.profile')}
                    routePath={routes.MY_PROFILE_DETAILS}
                    color="primary"
                    key={1}
                  />
                )}
                <MenuRoute
                  text={i18n.t('menu.logout')}
                  key={2}
                  color={'#f59a9a'}
                  onHandleClick={() => logout()}
                  routePath={routes.LOGIN}
                />
              </List>
            </nav>
          </Popover>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Drawer
