import { gql } from '@apollo/client'

const GET_FOLDERS_IN_FOLDER = gql`
  query GetAllDirectoriesInFolder($uuid: UUID!, $offset: Int!, $first: Int!, $orderBy: String!) {
    directories(filter: { parent_Uuid: $uuid }, offset: $offset, first: $first, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          parentUuid
          name
          uuid
          description
          ctime
          fileCount
        }
      }
    }
  }
`

const GET_FOLDER_ID_BY_FOLDER_FULL_PATH = gql`
  query GetDirectoryByFolderFullPath($fullPath: String!) {
    directoryByFullPath(fullPath: $fullPath) {
      uuid
    }
  }
`

const GET_ALL_FOLDERS = gql`
  query GetAllDirectories($uuid: UUID!) {
    directories(filter: { ownerUuid: $uuid }) {
      totalCount
      edges {
        node {
          uuid
          name
        }
      }
    }
  }
`

const GET_FOLDERS_FROM_ROOT = gql`
  query GetAllDirectoriesFromRoot($parentUuid: UUID!) {
    directories(filter: { parent_Uuid: $parentUuid }) {
      edges {
        node {
          name
          uuid
          description
          ctime
          fileCount
          parentUuid
        }
      }
    }
  }
`

export { GET_FOLDERS_IN_FOLDER, GET_FOLDER_ID_BY_FOLDER_FULL_PATH, GET_ALL_FOLDERS, GET_FOLDERS_FROM_ROOT }
