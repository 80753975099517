import { put, call, take, fork, cancelled, cancel } from 'redux-saga/effects'
import { channel } from 'redux-saga'
import { MAX_NUMBER_UPLOAD_AT_ONE_TIME } from 'src/constants/uploadConstants'
import axios from 'axios'

export function* watcherLimitedRequests(worker: Generator<any,any,any>, uniqueId: string): Generator<any, void, any> {
  let forksList: any = []
  try {
    const chan = yield call(channel)

    for (let i = 0; i < MAX_NUMBER_UPLOAD_AT_ONE_TIME; i++) {
      // @ts-ignore
      let forkWatcher = yield fork(worker, chan, uniqueId)
      forksList = [forkWatcher, ...forksList]
    }

    while (true) {
      const { payload } = yield take(`START_OPERATION_${uniqueId}`)
      yield put(chan, payload)
    }
  } finally {
    if (yield cancelled()) {
      for (const item of forksList) yield cancel(item)
    }
  }
}

export function* watchOnForkFinish(size: number, uniqueId: string, isStrict = false) {
  const arrayOfResponse = []
  while (true) {
    const { payload } = yield take(`AFTER_OPERATION_${uniqueId}`)
    if (isStrict && (payload instanceof Error || axios.isCancel(payload))) throw payload
    arrayOfResponse.push(payload)
    if (arrayOfResponse.length === size) return arrayOfResponse
  }
}
