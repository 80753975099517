import { combineReducers } from 'redux'

import meInfo from './me'
import details from './detail'
import data from './data'
import dataModal from './dataModal'
import hospitals from './hospitals'
import doctors from './doctors'
import share from './share'
import updateProfileDetails from './updateProfileDetails'
import changePassword from './changePassword'

export type TProfile = {
  details: any
  meInfo: any
  data: any
  dataModal: any
  hospitals: any
  doctors: any
  share: any
  updateProfileDetails: any
  changePassword: any
}

export default combineReducers<TProfile>({
  details,
  meInfo,
  data,
  dataModal,
  hospitals,
  doctors,
  share,
  updateProfileDetails,
  changePassword
})
