// @flow
export const UPLOAD_FILE: 'upload/UPLOAD_FILE' = 'upload/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS: 'upload/UPLOAD_FILE_SUCCESS' =
  'upload/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE: 'upload/UPLOAD_FILE_FAILURE' =
  'upload/UPLOAD_FILE_FAILURE';

type uploadDataT = {
  dataset_name: string,
  dataset_description: string,
  file_description: string,
  file_url: string,
  role: string,
};

type uploadRoleT = {
  role: string,
};

type uploadIdT = {
  id: string,
};

type Upload = {
  type: typeof UPLOAD_FILE,
};

export type UploadDataSuccess = {
  type: typeof UPLOAD_FILE_SUCCESS,
  payload: any,
};

type UploadDataFailure = {
  type: typeof UPLOAD_FILE_FAILURE,
  payload: any,
};

type ActionType = Upload | UploadDataSuccess | UploadDataFailure;

export type State = {
  loading: boolean,
  file: ?string,
  error: ?string,
};

const initialState: State = {
  loading: false,
  file: null,
  error: null,
};

export default function uploadReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...initialState,
        loading: false,
        error: null,
      };

    case UPLOAD_FILE_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function uploadData(data: uploadDataT, list: any) {
  return {
    type: UPLOAD_FILE,
    payload: {
      data,
      list,
    },
  };
}
