import { makeStyles, Theme } from '@material-ui/core'

type MenuButtonStylesProps = {
  open: boolean
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 32,
    width: 32,
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '100%',
    fontFamily: 'inherit',
    border: '0',
    padding: '0',
    backgroundColor: 'inherit'
  },
  line: {
    height: '3px',
    width: '20px',
    background: '#2b5171',
    transition: 'all 0.2s ease',
    borderRadius: '6px'
  },
  lineTop: {
    transform: ({ open }: MenuButtonStylesProps) => (open ? 'translate(0px, 1px) rotate(45deg)' : 'none'),
    transformOrigin: 'top left',
    marginBottom: '5px'
  },
  lineMiddle: ({ open }: MenuButtonStylesProps) => ({
    opacity: open ? 0 : 1,
    transform: open ? 'translateX(-16px)' : 'none'
  }),
  lineBottom: {
    transform: ({ open }: MenuButtonStylesProps) => (open ? 'translate(-2px, -1px) rotate(-45deg)' : 'none'),
    transformOrigin: 'top left',
    marginTop: '5px'
  }
}))
