import * as React from 'react'

// COMMON
import styles from './styles.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      {process.env.REACT_APP_GENOMYOU !== 'true' ? (
        <>
          <p>Your genome securely stored online</p>
          <p>Powered by: GenomSys</p>
        </>
      ) : (
        <>
          <p style={{ textAlign: 'center', width: '100%' }}>&copy; 2023 GenomSys SA. All rights reserved.</p>
        </>
      )}
    </footer>
  )
}

export default Footer
