import { CancelTokenSource } from 'axios'
import { END, eventChannel } from 'redux-saga'
import { uploadFileRequest } from './uploadFileRequest'

export function createUploader(
  payload: File,
  uuid: string,
  cancelToken: CancelTokenSource,
  url: string,
  uploadedSize = 0,
  totalSize: number | null = null
) {
  const tempTotalSize = totalSize || payload.size
  let emit: (input: unknown) => void
  const chan = eventChannel(emitter => {
    emit = emitter
    return () => {}
  })
  let progress = 0
  const uploadPromise = uploadFileRequest(payload, cancelToken, url, (event: ProgressEvent) => {
    const temp = Math.ceil(((event.loaded + uploadedSize) / tempTotalSize) * 100)
    if (temp > progress) {
      progress = temp
      emit({ progress, uuid: uuid })
    }
    if (event.loaded / event.total === 1) {
      emit(END)
    }
  })
  return [uploadPromise, chan]
}