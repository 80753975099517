import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect, StrictEffect,
  takeEvery
} from 'redux-saga/effects'

import apolloClient from '../../../apolloClient/userFile'

import {
  GET_FOLDER_ID_BY_FOLDER_FULL_PATH,
  GET_FOLDERS_IN_FOLDER,
} from '../../../graphql/queries/folderList'

import {GET_FILES_IN_FOLDER} from '../../../graphql/queries/fileList'

import {
  LOAD_FILES_LIST,
  LOAD_FILES_LIST_FAILURE,
  LOAD_FILES_LIST_SUCCESS,
  LOAD_FOLDER_LIST,
  LOAD_FOLDER_LIST_FAILURE,
  LOAD_FOLDER_LIST_SUCCESS,
  TALoadFilesListSaga,
  TALoadFilesListSuccess,
  TALoadFolderListSaga,
  TALoadFolderListSuccess,

} from '../../redux/dataManagement/listData'
import {TNotifyActions} from '../../redux/notify/notify'
import {
  GetDirectoryByFolderFullPath,
  GetDirectoryByFolderFullPathVariables
} from '../../../types/generated/GetDirectoryByFolderFullPath'
import {
  GetAllDirectoriesInFolder,
  GetAllDirectoriesInFolderVariables
} from '../../../types/generated/GetAllDirectoriesInFolder'
import {
  AllFilesInDirectory,
  AllFilesInDirectoryVariables
} from '../../../types/generated/AllFilesInDirectory'
import {CommonDataManagementGeneratorActions} from './index'
import {ApolloQueryResult} from '@apollo/client'

type GetFolderList = CommonDataManagementGeneratorActions | PutEffect<TNotifyActions> | StrictEffect

function* getParentUuid(currentPath: string | null): Generator<GetFolderList, void, any> {
  if (!currentPath) throw Error('Current path is necessary')
  return yield apolloClient.query<GetDirectoryByFolderFullPath,
    GetDirectoryByFolderFullPathVariables>({
    query: GET_FOLDER_ID_BY_FOLDER_FULL_PATH,
    variables: {
      fullPath: currentPath
    }
  })
}


function* loadFilesList({ payload }: TALoadFilesListSaga): Generator<GetFolderList, void, any> {
  try {

    const { data } = yield call(getParentUuid, payload.currentPath)
    const parentUuid = data?.directoryByFullPath?.uuid
    if (!parentUuid) throw Error('Parent UUID is necessary')
    const files: ApolloQueryResult<AllFilesInDirectory> = yield apolloClient.query<
      AllFilesInDirectory,
      AllFilesInDirectoryVariables
      >({
      query: GET_FILES_IN_FOLDER,
      variables: {
        uuid: parentUuid,
        first: payload.pagination.offset,
        offset: payload.pagination.offset * (payload.pagination.page),
        orderBy: `${payload.pagination.order === 'asc' ? '-'+payload.pagination.orderBy : payload.pagination.orderBy}`
      }
    })

    const dataList = {
      totalCount: files.data.files ? files.data.files.totalCount : 0,
      fileList: files.data.files ? files.data.files.edges.map(x => x?.node) : []
    }

    yield put<TALoadFilesListSuccess>({
      type: LOAD_FILES_LIST_SUCCESS,
      // TODO TS problem. Check if time
      // @ts-ignore
      payload: dataList
    })

  } catch (error) {
    yield put({
      type: LOAD_FILES_LIST_FAILURE,
      payload: {
        error: `${error}`
      }
    })
    // yield put({
    //   type: SHOW_NOTIFY,
    //   payload: {
    //     message: `${error.message}`,
    //     target: ERROR
    //   }
    // })
  }
}


function* loadFolderList({ payload }: TALoadFolderListSaga ): Generator<GetFolderList, void, any> {
  try {

    const { data } = yield call(getParentUuid, payload.currentPath)
    const parentUuid = data?.directoryByFullPath?.uuid

    if (!parentUuid) throw Error('Parent UUID is necessary')

    const folders: ApolloQueryResult<GetAllDirectoriesInFolder> = yield apolloClient.query<
      GetAllDirectoriesInFolder,
      GetAllDirectoriesInFolderVariables
      >({
      query: GET_FOLDERS_IN_FOLDER,
      variables: {
        uuid: parentUuid,
        first: payload.pagination.offset,
        offset: payload.pagination.offset * (payload.pagination.page),
        orderBy: `${payload.pagination.order === 'asc' ? '-'+payload.pagination.orderBy : payload.pagination.orderBy}`
      }
    })

    const dataList = {
      totalCount: folders.data.directories ? folders.data.directories.totalCount : 0,
      parentId: parentUuid,
      folderList: folders.data.directories ? folders.data.directories.edges.map(x => x?.node) : [],
    }

    yield put<TALoadFolderListSuccess>({
      type: LOAD_FOLDER_LIST_SUCCESS,
      // TODO TS problem. Check if time
      // @ts-ignore
      payload: dataList
    })
  } catch (error) {
    yield put({
      type: LOAD_FOLDER_LIST_FAILURE,
      payload: {
        error: `${error}`
      }
    })
  }
}



export default function* watchListData(): Generator<ForkEffect<TALoadFilesListSaga | TALoadFolderListSaga | CallEffect>, void, any> {
  yield takeEvery(LOAD_FOLDER_LIST, loadFolderList)
  yield takeEvery(LOAD_FILES_LIST, loadFilesList)
}
