// @flow
export const USER_CREATE: 'userEdit/USER_CREATE' = 'userEdit/USER_CREATE';
export const USER_CREATE_GET_SUCCESS: 'userEdit/USER_CREATE_GET_SUCCESS' =
  'userEdit/USER_CREATE_GET_SUCCESS';
export const USER_CREATE_GET_FAILURE: 'userEdit/USER_CREATE_GET_FAILURE' =
  'userEdit/USER_CREATE_GET_FAILURE';

export const CHANGE_ACCOUNT_ACTIVE_STATE: 'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE' =
  'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE';
export const CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE: 'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE' =
  'userEdit/CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE';

type UserInfoT = {
  id: number,
  role: string,
};

type UserInfo = {
  type: typeof USER_CREATE,
};

export type UserInfoGetSuccess = {
  type: typeof USER_CREATE_GET_SUCCESS,
  payload: any,
};

type UserInfoGetFailure = {
  type: typeof USER_CREATE_GET_FAILURE,
  payload: any,
};

type ChangeAccountActiveState = {
  type: typeof CHANGE_ACCOUNT_ACTIVE_STATE,
};

export type ChangeAccountActiveStateFailure = {
  type: typeof CHANGE_ACCOUNT_ACTIVE_STATE_FAILURE,
  payload: any,
};

type ActionType =
  | UserInfo
  | UserInfoGetSuccess
  | UserInfoGetFailure
  | ChangeAccountActiveState
  | ChangeAccountActiveStateFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  user: null,
  error: null,
};

export default function userReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case USER_CREATE:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case USER_CREATE_GET_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        loading: false,
        error: null,
      };

    case USER_CREATE_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function createUser(data: UserInfoT) {
  return {
    type: USER_CREATE,
    payload: data,
  };
}
