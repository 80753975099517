import React from 'react'
import logo from 'src/assets/images/logo_genomsys.svg'
import genomyouLogo from 'src/assets/images/genomyou/logo_genomyou.png'
// import alitheaLogo from 'src/assets/images/alithea/logo_alithea_poweredby.png'
import alitheaLogo from 'src/assets/images/alithea/logo_alithea.jpg'

const MenuLogo = () => {
  return process.env.REACT_APP_GENOMYOU === 'true' ? (
    <img style={{ height: '100%', padding: '12px' }} src={genomyouLogo} alt="logo" />
  ) : process.env.REACT_APP_ALITHEA_PROJECT === 'true' ? (
    <img style={{ height: '100%', padding: '12px' }} src={alitheaLogo} alt="logo" />
  ) : (
    <img style={{ height: '100%', padding: '12px' }} src={logo} alt="logo" />
  )
}

export default MenuLogo
