import { CancelTokenSource } from 'axios'

export const START_NOTIFICATION: string = 'rightBottom/START_NOTIFICATION'
export const UPDATE_NOTIFICATION: string = 'rightBottom/UPDATE_NOTIFICATION'
export const SET_STATUS_NOTIFICATION: string = 'rightBottom/SET_STATUS_NOTIFICATION'
export const RESET_NOTIFICATION: string = 'rightBottom/RESET_NOTIFICATION'
export const UPDATE_CANCEL_TOKEN: string = 'rightBottom/UPDATE_CANCEL_TOKEN'

export type TRightBottomNotifyData = {
  name?: string
  progress?: number
  status?: string
  uuid: string
  cancelToken?: CancelTokenSource
}

export type TARightBottomStartNotification = {
  type: typeof START_NOTIFICATION
  payload: {
    target: string // TODO ENUM FOR TARGET RIGHT BOTTOM NOTIFY
    title?: string
    data: TRightBottomNotifyData
  }
}

export type TARightBottomUpdateNotification = {
  type: typeof UPDATE_NOTIFICATION
  payload: TRightBottomNotifyData
}

export type TARightBottomUpdateToken = {
  type: typeof UPDATE_CANCEL_TOKEN
  payload: TRightBottomNotifyData
}

export type TARightBottomSetStatusNotification = {
  type: typeof SET_STATUS_NOTIFICATION
  payload: TRightBottomNotifyData
}

export type TARightBottomResetNotification = {
  type: typeof RESET_NOTIFICATION
  payload: any
}

export type TRightBottomNotifyActions =
  | TARightBottomStartNotification
  | TARightBottomUpdateNotification
  | TARightBottomSetStatusNotification
  | TARightBottomResetNotification
  | TARightBottomUpdateToken

export type TRightBottomNotifyState = {
  isShow: boolean
  target: string | null
  title: string | null
  data: Array<TRightBottomNotifyData>
}

const initialState: TRightBottomNotifyState = {
  isShow: false,
  target: null,
  title: null,
  data: []
}

export type TRightBottomNotifyResetSaga = {
  type: typeof RESET_NOTIFICATION
}

export default function mySourcesReducer(
  state = initialState,
  action: TRightBottomNotifyActions
): TRightBottomNotifyState {
  switch (action.type) {
    case START_NOTIFICATION:
      return {
        ...state,
        isShow: true,
        title: action.payload.title,
        target: action.payload.target,
        data: [...state.data, action.payload.data]
      }

    case UPDATE_NOTIFICATION: {
      const { progress, uuid } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                progress
              }
            : item
        )
      }
    }
    case UPDATE_CANCEL_TOKEN: {
      const { cancelToken, uuid } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                cancelToken
              }
            : item
        )
      }
    }
    case SET_STATUS_NOTIFICATION: {
      const { uuid, status } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                status
              }
            : item
        )
      }
    }

    case RESET_NOTIFICATION:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export function resetNotification(): TRightBottomNotifyResetSaga {
  return {
    type: RESET_NOTIFICATION
  }
}
