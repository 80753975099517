import { ERROR, SUCCESS } from '../../../constants/notfiyTypes'

export const SHOW_NOTIFY = 'notify/SHOW_NOTIFY'

export type TAShowNotify = {
  type: typeof SHOW_NOTIFY
  payload: {
    target: string
    message: string
    config?: object
  }
}

export type TNotifyActions = TAShowNotify

export type TNotifyState = {
  target: string | null
  message: string | null
  config: object
}

const initialState: TNotifyState = {
  target: null,
  message: null,
  config: {}
}

export default function notifyReducer(state = initialState, action: TNotifyActions): TNotifyState {
  switch (action.type) {
    case SHOW_NOTIFY: {
      const { target, message, config } = action.payload
      return {
        ...state,
        target: target,
        message: message,
        config: config || {}
      }
    }
    default:
      return state
  }
}

export const notifySuccess = (message: string): TAShowNotify => {
  return {
    type: SHOW_NOTIFY,
    payload: {
      target: SUCCESS,
      message
    }
  }
}

export const notifyError = (message: string): TAShowNotify => {
  return {
    type: SHOW_NOTIFY,
    payload: {
      target: ERROR,
      message
    }
  }
}
