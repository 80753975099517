import axios from 'axios'
import { takeEvery, put, call, StrictEffect } from 'redux-saga/effects'

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  TChangePasswordData,
  TChangePasswortSaga,
  TChangePasswordFailure,
  TChangePasswordSuccess
} from '../../redux/profile/changePassword'
import * as endpoints from '../../../constants/api'

export function* changePassword({ payload }: { payload: TChangePasswordData }): Generator<StrictEffect, void, any> {
  const { email, old_password, new_password } = payload
  const url = `${process.env.REACT_APP_BASE_API_URL}${endpoints.CHANGE_PASSWORD.replace('{email}', email)}`

  try {
    const response = yield call(axios.put, url, {
      old_password: old_password,
      new_password: new_password
    })

    yield put<TChangePasswordSuccess>({
      type: CHANGE_PASSWORD_SUCCESS
    })
  } catch (error) {
    yield put<TChangePasswordFailure>({
      type: CHANGE_PASSWORD_FAILURE,
      payload: {
        error: error
      }
    })
  }
}

export default function* watchChangePassword(): Generator<StrictEffect, void, any> {
  yield takeEvery<TChangePasswortSaga>(CHANGE_PASSWORD, changePassword)
}
