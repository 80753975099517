import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { defaultOptions, authLink, errorLink } from './config'
import * as endpoints from '../constants/api'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GS_FILE_DB || process.env.REACT_APP_BASE_API_URL}${endpoints.GRAPHQL_ENDPOINT}`
})

const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
  defaultOptions
})
export default apolloClient
