import React, { useState } from 'react'
import { Snackbar, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import * as targetTypes from '../../constants/rightBottomNotificationTypes'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '../IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextWrapper from '../TextWrapper/TextWrapper'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Collapse from '@material-ui/core/Collapse'
import FileUploadNotificationContent, {
  closeAction as fileUploadCloseAction
} from '../../pages/Profile/tabs/Data/components/BottomNotification/BottomNotification'
import i18n from '../../locales/i18next'
import ConfirmationModal from '../ConfirmationModal'
import { resetNotification } from '../../store/redux/notifications/rightBottom'
import { useAppSelector } from '../../utils/useAppSelector'

export const styles = (theme: Theme) => ({
  content: {
    minWidth: 'auto',
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  message: {
    padding: 0
  }
})

const useStyles = makeStyles(styles)

type TConfirmationMessage = {
  title: string
  subtitle: string | null
  onConfirm: () => void
}

const RightBottomNotification = () => {
  const cl = useStyles()
  const [expanded, setExpanded] = useState(true)
  const isShow = useAppSelector(state => state.notifications.rightBottom.isShow)
  const target = useAppSelector(state => state.notifications.rightBottom.target)
  const data = useAppSelector(state => state.notifications.rightBottom.data)
  const [confirmationMessageData, setConfirmationMessageData] = useState<TConfirmationMessage | null>(null)
  const [isConfirmationMessage, setIsConfirmationMessage] = useState(false)
  const dispatch = useDispatch()

  const renderTitleDependsOnType = () => {
    switch (target) {
      case targetTypes.FILE_UPLOAD:
        return i18n.t('rightBottomNotification.uploadFile.title')
      default:
        return null
    }
  }

  const renderDependsOnType = () => {
    switch (target) {
      case targetTypes.FILE_UPLOAD:
        return <FileUploadNotificationContent data={data} />
      default:
        return null
    }
  }

  const closeActionDependsOnType = () => {
    switch (target) {
      case targetTypes.FILE_UPLOAD: {
        if (data.some(item => item.status === 'pending' || item.status === 'waiting')) {
          setConfirmationMessageData({
            title: i18n.t('rightBottomNotification.uploadFile.confirmTitle'),
            subtitle: i18n.t('rightBottomNotification.uploadFile.confirmSubtitle'),
            onConfirm: () => {
              fileUploadCloseAction(data)
              setIsConfirmationMessage(false)
              dispatch(resetNotification())
            }
          })
          setIsConfirmationMessage(true)
        } else {
          dispatch(resetNotification())
        }
        return null
      }
      default:
        return null
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isShow}
        onClose={() => {}}
        ContentProps={{
          className: cl.content,
          classes: { message: cl.message }
        }}
        message={
          <div>
            <Grid container alignItems={'center'} justify={'space-between'} style={{ padding: 10 }}>
              <TextWrapper fontWeight={'bold'}>{renderTitleDependsOnType()}</TextWrapper>
              <div>
                <IconButton
                  size="small"
                  onClick={() => setExpanded(prev => !prev)}
                  aria-expanded={expanded}
                  tooltip={expanded ? 'Minimize' : 'Maximize'}
                  color="inherit"
                  style={{ marginLeft: 10 }}>
                  {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
                <IconButton size="small" tooltip={'Close'} color="inherit" onClick={() => closeActionDependsOnType()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
            <Collapse in={expanded}>
              <div style={{ backgroundColor: 'white' }}>{renderDependsOnType()}</div>
            </Collapse>
          </div>
        }
        key={'test'}
      />
      {isConfirmationMessage && confirmationMessageData && (
        <ConfirmationModal
          open={isConfirmationMessage}
          confirmButtonColor={'#b3281e'}
          title={confirmationMessageData.title}
          subtitle={confirmationMessageData.subtitle!}
          onClose={() => {
            setIsConfirmationMessage(false)
          }}
          onConfirm={confirmationMessageData.onConfirm}
        />
      )}
    </>
  )
}

export default RightBottomNotification
