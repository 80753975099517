import React, { lazy } from 'react'
import { Switch } from 'react-router'
import * as routes from '../constants/routes'
import AuthRoute from './components/AuthRoute'
import PublicRoute from './components/PublicRoute'

const GenomYou = lazy(() => import('../pages/GenomYou/GenomYouPage'))
const Login = lazy(() => import('../pages/Login'))
const ResetPassword = lazy(() => import('../pages/ResetPassword'))

type TGenomSysRoutes = {
  isAuth: boolean
  is2faRequired: boolean
  isAccountActivated: boolean
}

const GenomYouRoutes: React.FC<TGenomSysRoutes> = props => {
  const { isAuth, is2faRequired, isAccountActivated } = props
  return (
    <Switch>
      <PublicRoute exact path={routes.LOGIN} isAuth={isAuth} is2faRequired={is2faRequired} component={Login} />
      <PublicRoute
        exact
        path={routes.RESET_PASSWORD}
        isAuth={isAuth}
        is2faRequired={is2faRequired}
        component={ResetPassword}
      />

      <AuthRoute
        exact
        path={routes.HOME}
        isAuth={isAuth}
        isAccountActivated={isAccountActivated}
        component={GenomYou}
      />
    </Switch>
  )
}

export default GenomYouRoutes
