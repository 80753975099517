import { CancelTokenSource } from 'axios'
import { TRightBottomNotifyData } from '../notifications/rightBottom'

export const GENOMYOU_UPLOAD_FILES = 'GENOMYOU_UPLOAD_FILES'
export const GENOMYOU_UPLOAD_FILES_START = 'GENOMYOU_UPLOAD_FILES_START'
export const GENOMYOU_UPLOAD_FILES_UPDATE = 'GENOMYOU_UPLOAD_FILES_UPDATE'
export const GENOMYOU_UPLOAD_FILES_SET_STATUS = 'GENOMYOU_UPLOAD_FILES_SET_STATUS'
export const GENOMYOU_UPLOAD_FILES_SUCCESS = 'GENOMYOU_UPLOAD_FILES_SUCCESS'
export const GENOMYOU_UPLOAD_FILES_FAILURE = 'GENOMYOU_UPLOAD_FILES_FAILURE'
export const GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN = 'GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN'

export type TGenomYouUploadFilesData = {
  forceOverride: boolean
  blob: File
  fileData: {
    name: string
    cancelToken: any
  }
  species: string | null | undefined
}

export type TGenomYouUploadFilesPayload = {
  data: TGenomYouUploadFilesData[]
}

export type TUploadFiles = {
  type: typeof GENOMYOU_UPLOAD_FILES
}

export type TUploadFilesStart = {
  type: typeof GENOMYOU_UPLOAD_FILES_START
  payload: TRightBottomNotifyData[]
}

export type TUploadFilesUpdate = {
  type: typeof GENOMYOU_UPLOAD_FILES_UPDATE
  payload: {
    uuid: string
    progress: number
  }
}

export type TUpdateToken = {
  type: typeof GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN
  payload: {
    uuid: string
    cancelToken: CancelTokenSource
  }
}

export type TUploadFilesStatus = {
  type: typeof GENOMYOU_UPLOAD_FILES_SET_STATUS
  payload: {
    uuid: string
    status: string
  }
}

export type TUploadFilesSuccess = {
  type: typeof GENOMYOU_UPLOAD_FILES_SUCCESS
}

export type TUploadFilesFailure = {
  type: typeof GENOMYOU_UPLOAD_FILES_FAILURE
  payload: string
}

export type TUploadGenomYouActions =
  | TUploadFiles
  | TUploadFilesFailure
  | TUploadFilesSuccess
  | TUploadFilesStart
  | TUploadFilesUpdate
  | TUploadFilesStatus
  | TUpdateToken

export type TGenomYouState = {
  status: 'INITIAL' | 'PENDING' | 'SUCCESS' | 'FAILURE'
  data: TRightBottomNotifyData[]
  error: string | null
}

const initialState: TGenomYouState = {
  status: 'INITIAL',
  data: [],
  error: null
}

export type TGenomYouUploadSaga = {
  type: typeof GENOMYOU_UPLOAD_FILES
  payload: TGenomYouUploadFilesPayload
}

const reducer = (state: TGenomYouState = initialState, action: TUploadGenomYouActions): TGenomYouState => {
  switch (action.type) {
    case GENOMYOU_UPLOAD_FILES: {
      return {
        ...initialState,
        status: 'PENDING'
      }
    }
    case GENOMYOU_UPLOAD_FILES_START: {
      return {
        ...state,
        data: action.payload
      }
    }
    case GENOMYOU_UPLOAD_FILES_UPDATE: {
      const { progress, uuid } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                progress
              }
            : item
        )
      }
    }
    case GENOMYOU_UPLOAD_UPDATE_CANCEL_TOKEN: {
      const { cancelToken, uuid } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                cancelToken
              }
            : item
        )
      }
    }
    case GENOMYOU_UPLOAD_FILES_SET_STATUS: {
      const { uuid, status } = action.payload
      return {
        ...state,
        data: state.data.map(item =>
          item.uuid === uuid
            ? {
                ...item,
                status
              }
            : item
        )
      }
    }
    case GENOMYOU_UPLOAD_FILES_SUCCESS: {
      return {
        ...state,
        status: 'SUCCESS'
      }
    }
    case GENOMYOU_UPLOAD_FILES_FAILURE: {
      return {
        ...state,
        status: 'FAILURE',
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export function genomYouUploadFiles(payload: TGenomYouUploadFilesPayload): TGenomYouUploadSaga {
  return {
    type: GENOMYOU_UPLOAD_FILES,
    payload
  }
}

export default reducer
