import axios, { CancelTokenSource } from 'axios'
import { call, fork, PutEffect, StrictEffect } from 'redux-saga/effects'
import { UPLOAD_TIMEOUT } from '../../constants/uploadConstants'
import {
  TRightBottomNotifyActions,
  TRightBottomNotifyData,
} from '../../store/redux/notifications/rightBottom'
import { createUploader } from './createUploader'

export type TWatchOnProgressGenerator = PutEffect<TRightBottomNotifyActions> | StrictEffect

export function* uploadWrapper(
  isNewCancelTokenGenerate: boolean,
  cancelTokenValue: CancelTokenSource,
  tempData: TRightBottomNotifyData,
  blob: File,
  start: number,
  chunkSize: number,
  url: string,
  uploadedSize: number,
  watchOnProgressGenerator: any,
  onGenerateToken: any
): Generator<TWatchOnProgressGenerator, void, any> {
  if (isNewCancelTokenGenerate) {
    cancelTokenValue = axios.CancelToken.source()
    yield call(onGenerateToken, cancelTokenValue, tempData.uuid)
    isNewCancelTokenGenerate = false
  }

  let timeout = setTimeout(() => {
    cancelTokenValue.cancel('TimeoutError')
  }, UPLOAD_TIMEOUT)

  const chunk = blob.slice(start, start + chunkSize)
  const [uploadPromise, chan] = createUploader(
    chunk as File,
    tempData.uuid,
    cancelTokenValue!,
    url,
    uploadedSize,
    blob.size
  )
  yield fork(watchOnProgressGenerator, chan)
  const response = yield call(() => uploadPromise)

  if (timeout) clearTimeout(timeout)
  return response.headers.etag
}
