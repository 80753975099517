import { ApolloQueryResult, FetchResult } from '@apollo/client'
import axios from 'axios'
import apolloClient from '../../apolloClient/userFile'
import { TCreateFile } from '../../store/redux/dataManagement/createFiles'
import { TRightBottomNotifyData } from '../../store/redux/notifications/rightBottom'
import { UploadMultiFile, UploadMultiFileVariables } from '../../types/generated/UploadMultiFile'
import {
  UPLOAD_MULTIPART_FILE as UPLOAD_MULTIPART_FILE_GQL
} from '../../graphql/mutations/fileManagement'
import { CHUNK_DEFAULT_SIZE } from '../../constants/uploadConstants'

type TCreateDatabaseFileGenerator = Promise<FetchResult>

export type CreatedFileData = {
  tempData: TRightBottomNotifyData
  url?: string
  urls?: Array<string>
  blob: File
  uploadId?: string
  chunkSize: number
}

export function* createDatabaseFile({
  fileData,
  forceOverride,
  blob
}: TCreateFile): Generator<TCreateDatabaseFileGenerator, CreatedFileData, any> {
  const cancelTokenSource = axios.CancelToken.source()
  const isOver10k = CHUNK_DEFAULT_SIZE * 10000 < blob.size

  const chunkSize = isOver10k ? Math.ceil(blob.size / 10000) : CHUNK_DEFAULT_SIZE
  let numberOfParts = isOver10k ? 10000 : Math.ceil(blob.size / CHUNK_DEFAULT_SIZE)

  const { data }: ApolloQueryResult<UploadMultiFile> = yield apolloClient.mutate<
    UploadMultiFile,
    UploadMultiFileVariables
  >({
    mutation: UPLOAD_MULTIPART_FILE_GQL,
    variables: {
      fileData: fileData,
      forceOverride: forceOverride,
      numberOfParts: numberOfParts
    }
  })

  const uuid = data?.uploadFile?.resFile?.uuid
  const urls = data?.uploadFile?.uploadUrls
  const uploadId = data?.uploadFile?.uploadId
  if (!uuid || !Array.isArray(urls) || !uploadId) throw new Error('Something goes wrong with data fetching')

  return {
    tempData: {
      name: fileData.name,
      progress: 0,
      status: 'waiting',
      uuid,
      cancelToken: cancelTokenSource
    },
    urls,
    blob,
    uploadId,
    chunkSize
  }
}