// @flow
export const PROFILE_INFO: 'patients/PROFILE_INFO' = 'patients/PROFILE_INFO';
export const PROFILE_INFO_GET_SUCCESS: 'patients/PROFILE_INFO_GET_SUCCESS' =
  'patients/PROFILE_INFO_GET_SUCCESS';
export const PROFILE_INFO_GET_FAILURE: 'patients/PROFILE_INFO_GET_FAILURE' =
  'patients/PROFILE_INFO_GET_FAILURE';

type ProfileInfoT = {
  id: number,
  role: string,
};

type ProfileInfo = {
  type: typeof PROFILE_INFO,
};

export type ProfileInfoGetSuccess = {
  type: typeof PROFILE_INFO_GET_SUCCESS,
  payload: any,
};

type ProfileInfoGetFailure = {
  type: typeof PROFILE_INFO_GET_FAILURE,
  payload: any,
};

type ActionType = ProfileInfo | ProfileInfoGetSuccess | ProfileInfoGetFailure;

export type State = {
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  loading: false,
  user: null,
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case PROFILE_INFO:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case PROFILE_INFO_GET_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        loading: false,
        error: null,
      };

    case PROFILE_INFO_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function profileInfo(data: ProfileInfoT) {
  return {
    type: PROFILE_INFO,
    payload: data,
  };
}
