import { gql } from '@apollo/client'

const GET_ANALYSIS_CREATE_FOLDER_DATA = gql`
  query GetAnalysisNecessaryData {
    analysesFolder: directoryByFullPath(fullPath: "/analyses") {
      uuid
    }
    rootFolder: directoryByFullPath(fullPath: "/") {
      uuid
    }
  }
`

const GET_ANALYSIS_UUID = gql`
  query AnalysesByRefGenome($refGenome: String) {
    analysesByRefGenome(refGenome: $refGenome) {
      uuid
      name
    }
  }
`

const GET_ANALYSIS_RUNS = gql`
  query getAllAnalysisRuns($filter: AnalysisRunFilter) {
    analysisRuns(filter: $filter) {
      edges {
        node {
          uuid
          description
          name
          analysis {
            uuid
            name
          }
          directoryUuid
          directory {
            name
            fullPath
          }
        }
      }
    }
  }
`

export { GET_ANALYSIS_CREATE_FOLDER_DATA, GET_ANALYSIS_UUID, GET_ANALYSIS_RUNS }
