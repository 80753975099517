import { FetchResult } from '@apollo/client'
import axios, { CancelTokenSource } from 'axios'

export function uploadFileRequest(
  payload: File,
  cancelToken: CancelTokenSource,
  url: string,
  onProgress: (event: ProgressEvent) => void
): Promise<FetchResult> {
  const instance = axios.create()
  delete instance.defaults.headers
  const config = {
    onUploadProgress: onProgress,
    headers: {
      'Content-Type': payload.type
    },
    cancelToken: cancelToken.token
  }
  return instance.put(url, payload, config)
}