export const REGISTER_USER: 'auth/REGISTER_USER' = 'auth/REGISTER_USER'
export const REGISTER_USER_FAILURE: 'auth/REGISTER_USER_FAILURE' = 'auth/REGISTER_USER_FAILURE'
export const RESEND_EMAIL: 'auth/RESEND_EMAIL' = 'auth/RESEND_EMAIL'
export const RESEND_EMAIL_SUCCESS: 'auth/RESEND_EMAIL_SUCCESS' = 'auth/RESEND_EMAIL_SUCCESS'
export const RESEND_EMAIL_FAILURE: 'auth/RESEND_EMAIL_FAILURE' = 'auth/RESEND_EMAIL_FAILURE'
export const REGISTER_USER_SUCCESS: 'auth/REGISTER_USER_SUCCESS' = 'auth/REGISTER_USER_SUCCESS'
export const CLEAR_VALUES: 'auth/CLEAR_VALUES' = 'auth/CLEAR_VALUES'

export type TRegisterUserData = {
  email: string
  first_name: string
  last_name: string
  password: string
}

export type TUserRegistrationModel = {
  id: string
  email: string
  first_name: string
  last_name: string
}

export type TRegisterUser = {
  type: typeof REGISTER_USER
  payload: TRegisterUserData
}

export type TRegisterUserSuccess = {
  type: typeof REGISTER_USER_SUCCESS
  payload: {
    user: TUserRegistrationModel
  }
}

type TErrorsModel = {
  [key in keyof TRegisterUserData]: Array<string>
}

export type TRegisterUserFailure = {
  type: typeof REGISTER_USER_FAILURE
  payload: {
    errors: TErrorsModel
    error: string | null | undefined
  }
}

export type TResendEmail = {
  type: typeof RESEND_EMAIL
  payload: string
}

export type TResendEmailSuccess = {
  type: typeof RESEND_EMAIL_SUCCESS
}

export type TResendEmailFailure = {
  type: typeof RESEND_EMAIL_FAILURE
  payload: {
    error: string | null
  }
}

export type TClearValues = {
  type: typeof CLEAR_VALUES
}

type ActionType =
  | TRegisterUser
  | TRegisterUserSuccess
  | TRegisterUserFailure
  | TResendEmail
  | TResendEmailSuccess
  | TResendEmailFailure
  | TClearValues

export type TRegisterState = {
  loading: boolean
  error: string | null
  isRegisterSuccess: boolean
  resending: boolean
  isResendingSuccess: boolean
  user: Object | null
  errors: Object | null
}

const initialState: TRegisterState = {
  loading: false,
  isRegisterSuccess: false,
  resending: false,
  isResendingSuccess: false,
  user: null,
  error: null,
  errors: {
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    passwordConfirm: null
  }
}

export default function authReducer(state = initialState, action: ActionType): TRegisterState {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...initialState,
        loading: true,
        error: null,
        errors: null
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...initialState,
        loading: false,
        isRegisterSuccess: true,
        error: null,
        errors: null,
        user: action.payload.user
      }
    case REGISTER_USER_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error || null,
        errors: action.payload.errors || null
      }
    case RESEND_EMAIL:
      return {
        ...initialState,
        resending: true,
        isResendingSuccess: false
      }
    case RESEND_EMAIL_SUCCESS:
      return {
        ...initialState,
        resending: false,
        isResendingSuccess: true
      }
    case RESEND_EMAIL_FAILURE:
      return {
        ...initialState,
        resending: false,
        error: action.payload.error
      }
    case CLEAR_VALUES:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export function registerUserAuth(data: TRegisterUserData): TRegisterUser {
  return {
    type: REGISTER_USER,
    payload: data
  }
}

export function clearValues(): TClearValues {
  return {
    type: CLEAR_VALUES
  }
}

export function resendEmail(email: string): TResendEmail {
  return {
    type: RESEND_EMAIL,
    payload: email
  }
}
