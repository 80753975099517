// @flow
export const SUBSCRIPTIONS_LIST_FETCH: string = 'notifications/SUBSCRIPTION_LIST_FETCH';
export const SUBSCRIPTIONS_LIST_GET_SUCCESS: string = 'notifications/SUBSCRIPTIONS_LIST_GET_SUCCESS';
export const SUBSCRIPTIONS_LIST_GET_FAILURE: string = 'notifications/SUBSCRIPTIONS_LIST_GET_FAILURE';
export const CREATE_USER_SUBSCRIPTION_START: string = 'notifications/CREATE_USER_SUBSCRIPTION_START';
export const CREATE_USER_SUBSCRIPTION_SUCCESS: string = 'notifications/CREATE_USER_SUBSCRIPTION_SUCCESS';
export const CREATE_USER_SUBSCRIPTION_FAILURE: string = 'notifications/CREATE_USER_SUBSCRIPTION_FAILURE';
export const UPDATE_USER_SUBSCRIPTION_START: string = 'notifications/UPDATE_USER_SUBSCRIPTION_START';
export const UPDATE_USER_SUBSCRIPTION_SUCCESS: string = 'notifications/UPDATE_USER_SUBSCRIPTION_SUCCESS';
export const UPDATE_USER_SUBSCRIPTION_FAILURE: string = 'notifications/UPDATE_USER_SUBSCRIPTION_FAILURE';
export const DELETE_USER_SUBSCRIPTION_START: string = 'notifications/DELETE_USER_SUBSCRIPTION_START';
export const DELETE_USER_SUBSCRIPTION_SUCCESS: string = 'notifications/DELETE_USER_SUBSCRIPTION_SUCCESS';
export const DELETE_USER_SUBSCRIPTION_FAILURE: string = 'notifications/DELETE_USER_SUBSCRIPTION_FAILURE';

export type EventType = {
  id: string,
  name: string
}

export type  SubscriptionT= {
  id: string,
  userUuid: string,
  eventSource: {
    id: string,
    name: string
  },
  eventType: EventType,
  channels: Array<string>,
  details: Array<string>,
}

export type SubscriptionsList = Array<SubscriptionT>

export type SubscriptionsFetch = {
  type: typeof SUBSCRIPTIONS_LIST_FETCH,
};

export type SubscriptionsGetSuccess = {
  type: typeof SUBSCRIPTIONS_LIST_GET_SUCCESS,
  payload: {
    subscriptions: SubscriptionsList
  },
};

export type SubscriptionsGetFailure = {
  type: typeof SUBSCRIPTIONS_LIST_GET_FAILURE,
  payload: any,
};

export type ActionDataType = SubscriptionsFetch | SubscriptionsGetSuccess | SubscriptionsGetFailure;


export type DataState = {
  loading: boolean,
  list: SubscriptionsList,
  error: ?string,
};

const initialState: DataState = {
  loading: false,
  list: [],
  error: null,
};

export default function mySubscriptionReducer(
  state: DataState = initialState,
  action: ActionDataType,
): State {
  switch (action.type) {
    case SUBSCRIPTIONS_LIST_FETCH:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case SUBSCRIPTIONS_LIST_GET_SUCCESS:
      return {
        ...initialState,
        list: action.payload.subscriptions,
        loading: false,
        error: null,
      };

    case SUBSCRIPTIONS_LIST_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };
    case CREATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        list: [...state.list, action.payload],
        loading: false,
        error: null
      }
    case UPDATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        list: state.list.map(subscription => subscription.uuid === action.payload.uuid ? action.payload : subscription),
        loading: false,
        error: null
      }
    case DELETE_USER_SUBSCRIPTION_SUCCESS:
      return {
        list: state.list.filter(subscription => subscription.uuid !== action.payload ),
        loading: false,
        error: null
      }

    default:
      return state;
  }
}

export const getSubscriptionsList = () => ({
  type: SUBSCRIPTIONS_LIST_FETCH
})

export const deleteUserSubscription = (subscription) => ({
  type: DELETE_USER_SUBSCRIPTION_START,
  payload: subscription
})

export const createUserSubscription = (subscription) => ({
  type: CREATE_USER_SUBSCRIPTION_START,
  payload: subscription
})

export const updateUserSubscription = (subscription) => ({
  type: UPDATE_USER_SUBSCRIPTION_START,
  payload: subscription
})
