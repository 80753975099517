export const LOGIN_USER = 'auth/LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'auth/LOGIN_USER_FAILURE'
export const LOGIN_USER_CLEAN = 'auth/LOGIN_USER_CLEAN'
export const LOGIN_USER_CLEAN_FAILURE = 'auth/LOGIN_USER_CLEAN_FAILURE'
export const LOGIN_USER_BY_2FA = 'auth/LOGIN_USER_BY_2FA'
export const REMOVE_2FA = 'profile/REMOVE_2FA'
export const CONNECTED_2FA = 'profile/CONNECTED_2FA'
export const LOGIN_USER_BY_2FA_SUCCESS = 'profile/LOGIN_USER_BY_2FA_SUCCESS'
export const TOKEN_EXPIRED = 'auth/TOKEN_EXPIRED'

export type TUser = {
  token: string
  uuid: string
  isActive2fa: boolean | null
}

export type TLoginUser = {
  type: typeof LOGIN_USER
}

export type TLoginUserSuccess = {
  type: typeof LOGIN_USER_SUCCESS
  payload: {
    user: TUser
  }
}

export type TLoginUserFailure = {
  type: typeof LOGIN_USER_FAILURE
  payload: {
    error: string
  }
}

export type TLoginUserBy2Fa = {
  type: typeof LOGIN_USER_BY_2FA
  payload: TUser
}

export type TLoginUserBy2FaSuccess = {
  type: typeof LOGIN_USER_BY_2FA_SUCCESS
  payload: TUser
}

export type TConnected2Fa = {
  type: typeof CONNECTED_2FA
}

export type TRemove2Fa = {
  type: typeof REMOVE_2FA
}

export type TLoginUserClean = {
  type: typeof LOGIN_USER_CLEAN
}

export type TLoginUserCleanFailure = {
  type: typeof LOGIN_USER_CLEAN_FAILURE
  payload: {
    error: string
  }
}

export type TLoginAction =
  | TLoginUser
  | TLoginUserSuccess
  | TLoginUserFailure
  | TLoginUserBy2Fa
  | TConnected2Fa
  | TRemove2Fa
  | TLoginUserClean
  | TLoginUserCleanFailure

export type TLoginUserData = {
  username: string
  password: string
}

export type TLoginState = {
  loading: boolean
  error: string | null
  isAuth: boolean
  is2faActive: boolean
  user: TUser
  is2faRequired: boolean
}

const initialState: TLoginState = {
  loading: false,
  isAuth: false,
  user: {
    isActive2fa: false,
    token: '',
    uuid: ''
  },
  error: null,
  is2faRequired: false,
  is2faActive: false
}

const loginReducer = (state = initialState, action: TLoginAction): TLoginState => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...initialState,
        loading: true
      }

    case LOGIN_USER_BY_2FA:
      return {
        ...initialState,
        loading: false,
        error: null,
        is2faRequired: true,
        isAuth: false,
        user: action.payload
      }

    case CONNECTED_2FA:
      return {
        ...state,
        user: { ...state.user, isActive2fa: true }
      }

    case REMOVE_2FA:
      return {
        ...state,
        user: { ...state.user, isActive2fa: false }
      }

    case LOGIN_USER_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        isAuth: true,
        loading: false,
        error: null
      }

    case LOGIN_USER_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error
      }

    case LOGIN_USER_CLEAN:
      return {
        ...initialState,
        user: { ...initialState.user }
      }

    case LOGIN_USER_CLEAN_FAILURE:
      return {
        ...initialState,
        error: action.payload.error
      }
    default:
      return state
  }
}

export type TLoginUserSaga = {
  type: typeof LOGIN_USER
  payload: TLoginUserData
}

export function loginUserAuth(data: TLoginUserData): TLoginUserSaga {
  return {
    type: LOGIN_USER,
    payload: data
  }
}

export function loginUserBy2faAuthSuccess(data: TUser): TLoginUserBy2FaSuccess {

  return {
    type: LOGIN_USER_BY_2FA_SUCCESS,
    payload: data
  }
}

export function loginUserAuthClean(): TLoginUserClean {
  return {
    type: LOGIN_USER_CLEAN
  }
}

export const handleExpiredToken = () => {
  return {
    type: TOKEN_EXPIRED
  }
}

export default loginReducer
