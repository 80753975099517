import { GetUserProfileByUserUuidVariables } from '../../../types/generated/GetUserProfileByUserUuid'

export const USER_DETAILS: 'userManagement/USER_DETAILS' = 'userManagement/USER_DETAILS'
export const USER_DETAILS_GET_SUCCESS: 'userManagement/USER_DETAILS_GET_SUCCESS' =
  'userManagement/USER_DETAILS_GET_SUCCESS'
export const USER_DETAILS_GET_FAILURE: 'userManagement/USER_DETAILS_GET_FAILURE' =
  'userManagement/USER_DETAILS_GET_FAILURE'

export type TLoadUserDetails = TUserDetails & {
  payload: GetUserProfileByUserUuidVariables
}

type TUserDetails = {
  type: typeof USER_DETAILS
}

export type TUserDetailsGetSuccess = {
  type: typeof USER_DETAILS_GET_SUCCESS
  payload: any
}

type TUserDetailsGetFailure = {
  type: typeof USER_DETAILS_GET_FAILURE
  payload: any
}

type TActionType = TUserDetails | TUserDetailsGetSuccess | TUserDetailsGetFailure

export type TState = {
  userDetails: {}
  loading: boolean
  error: string | null
}

const initialState: TState = {
  userDetails: {},
  loading: false,
  error: null
}

export default function userReducer(state: TState = initialState, action: TActionType): TState {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...initialState,
        loading: true,
        error: null
      }

    case USER_DETAILS_GET_SUCCESS:
      return {
        ...initialState,
        userDetails: action.payload,
        loading: false,
        error: null
      }

    case USER_DETAILS_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error
      }

    default:
      return state
  }
}

export function loadUserDetails(data: GetUserProfileByUserUuidVariables): TLoadUserDetails {
  return {
    type: USER_DETAILS,
    payload: data
  }
}
