import axios from 'axios'
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects'
import { RESET_PASSWORD, RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, TResetPasswordData, TResetPasswordFailure, TResetPasswordSuccess } from 'src/store/redux/auth/reset'
import * as endpoints from '../../../constants/api'

export type TResetPasswordSaga = {
  type: typeof RESET_PASSWORD
  payload: TResetPasswordData
}

function* resetPassword(action: TResetPasswordSaga): Generator<StrictEffect, void, any> {
  const email = action.payload.username

  try {
    yield call(
      axios.post,
      `${process.env.REACT_APP_GS_AUTH || process.env.REACT_APP_BASE_API_URL}${endpoints.RESET}`,
      { email },
      {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json'
        }
      }
    )
    yield put<TResetPasswordSuccess>({
      type: RESET_PASSWORD_SUCCESS
    })
  } catch (error) {
    console.error(error.response.status)
    console.log(error)
    yield put<TResetPasswordFailure>({
      type: RESET_PASSWORD_FAILURE,
      payload: {
        error: 'Reset password failed'
      }
    })
  }
}

export default function* watchReset(): Generator<StrictEffect, void, any> {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}
