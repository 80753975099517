// @flow
export const DATA_LIST_PATIENT: 'patients/DATA_LIST_PATIENT' =
  'patients/DATA_LIST_PATIENT';
export const DATA_LIST_SUCCESS: 'patients/DATA_LIST_SUCCESS' =
  'patients/DATA_LIST_SUCCESS';
export const DATA_LIST_FAILURE: 'patients/DATA_LIST_FAILURE' =
  'patients/DATA_LIST_FAILURE';

export type DataListT = {
  params: string,
};

export type List = {
  type: typeof DATA_LIST_PATIENT,
};

export type DataGetSuccess = {
  type: typeof DATA_LIST_SUCCESS,
  payload: any,
};

export type DataGetFailure = {
  type: typeof DATA_LIST_FAILURE,
  payload: any,
};

export type ActionDataType = List | DataGetSuccess | DataGetFailure;

export type DataState = {
  loading: boolean,
  error: ?string,
};

const initialState: DataState = {
  loading: false,
  data: null,
  error: null,
};

export default function profileReducer(
  state: DataState = initialState,
  action: ActionDataType,
): DataState {
  switch (action.type) {
    case DATA_LIST_PATIENT:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case DATA_LIST_SUCCESS:
      return {
        ...initialState,
        data: action.payload.data,
        loading: false,
        error: null,
      };

    case DATA_LIST_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function dataList(params: DataListT) {
  return {
    type: DATA_LIST_PATIENT,
    payload: params,
  };
}
