const genomYou = {
  infoSection: {
    mainText: `Convert your previously sequenced DNA into the MPEG-G standard format to make it fit on your phone.`,
    secondaryTextPart1: 'Through this page you can upload your previously sequenced DNA. Only',
    secondaryTextHighlight: 'FASTQ',
    secondaryTextPart2: 'files (typically a pair) are supported. After upload we will convert them into MPEG-G.',
    thirdTextPart1: `Here is an example of the file name format for a pair of FASTQ files: "HG001_S1_L001_R1_001.fastq.gz" and
          “HG001_S1_L001_R2_001.fastq.gz”`,
    thirdTextPart2: `Each file can be multiple Gigabytes large, depending on your internet connection the
          upload might take some time.`
  },
  errorInModule: 'Error in GenomYou upload module.',
  faqSection: {
    whyDesktop: 'Why cannot I directly upload my DNA files in the app?',
    whyDesktopContent: `Genomic datasets are usually quite sizeable (multiple Gigabytes) and do not fit on most smartphones, unless you use the compressed
                MPEG-G format. Most likely your sequenced DNA files are on your desktop anyway. We need files to be converted into MPEG-G (ISO/IEC 23092 standard)
                 before you can download them into the phone.`,
    whyFastq: 'Why FASTQ, only?',
    whyFastqContent: ` FASTQ files contain all the raw data produced by the sequencing machine, which allow our GenomYou app to deliver the most reliable results from the analyses. Working on other 
    formats (such as BAM, VCF, CRAM which have been already processed by third parties) would not let us provide the same end-to-end quality control.`
  },
  uploadDialog: {
    title: 'Upload your paired FASTQ files',
    text: `Commonly FASTQ are multiple Gigabytes, the upload could take hours depending on your internet connection`,
    afterClickText: `Please select a pair of matching FASTQ files and retry the upload. In case of doubts, contact us at`
  },
  login: {
    textPart1: 'Please login with the same credentials',
    textPart2: 'as in the GenomYou app to upload your previously sequenced DNA.'
  }
}

export default genomYou
