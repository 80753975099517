import { TPayloadError } from '../../../types';
import {TPagination} from "./listData";

export const CREATE_FOLDER = 'folderManagement/CREATE_FOLDER'
export const CREATE_FOLDER_SUCCESS = 'folderManagement/CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAILURE = 'folderManagement/CREATE_FOLDER_FAILURE'
export const RESET_CREATE_FOLDER = 'folderManagement/RESET_CREATE_FOLDER'

export type TACreateFolder = {
  type: typeof CREATE_FOLDER
}

export type TACreateFolderSuccess = {
  type: typeof CREATE_FOLDER_SUCCESS
}

export type TACreateFolderFailure = {
  type: typeof CREATE_FOLDER_FAILURE
  payload: TPayloadError
}

export type TAResetCreateFolder = {
  type: typeof RESET_CREATE_FOLDER
}

export type TCreateFolderActions = TACreateFolder | TACreateFolderSuccess | TACreateFolderFailure | TAResetCreateFolder

export type TCreateFolderData = {
  parentUuid: string
  name: string
  description?: string
}

export type TCreateFolder = {
  folderData: TCreateFolderData
  currentPath: string
  pagination: TPagination
}

export type TCreateFolderState = {
  loading: boolean
  success: boolean
  error: string | null
}

export type TCreateFolderSaga = {
  type: typeof CREATE_FOLDER
  payload: TCreateFolder
}

export const initialState: TCreateFolderState = {
  loading: false,
  success: true,
  error: null
}

export default function folderReducer(state = initialState, action: TCreateFolderActions): TCreateFolderState {
  switch (action.type) {
    case CREATE_FOLDER:
      return {
        ...state,
        success: false,
        loading: true
      }

    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      }

    case CREATE_FOLDER_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload.error
      }

    case RESET_CREATE_FOLDER:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export function createFolder(payload: TCreateFolder): TCreateFolderSaga {
  return {
    type: CREATE_FOLDER,
    payload
  }
}

export function resetCreateFolder() {
  return {
    type: RESET_CREATE_FOLDER
  }
}
