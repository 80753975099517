import { gql } from '@apollo/client'

// const GET_ALL_USERS = gql`
//   query AllUsers {
//     allUsers {
//       email
//       firstName
//       lastName
//       uuid
//     }
//   }
// `;

const GET_USER_DETAILS = gql`
  query GetUserProfileByUserUuid($userUuid: UUID) {
    userProfileByUserUuid(userUuid: $userUuid) {
      firstName
      lastName
      uuid
      phoneConfig {
        name
        phoneNumber
      }
    }
  }
`

// const GET_USER_DETAILS = gql`
//   query GetUserProfileByUserUuid($userUuid: UUID) {
//     userProfileByUserUuid(userUuid: $userUuid) {
//       address
//       birthDate
//       email
//       firstName
//       gender
//       lastName
//       uuid
//       phoneConfig {
//         name
//         phoneNumber
//         activeForSms
//       }
//     }
//   }
// `

// const GET_USER_UUID_BY_EMAIL = gql`
//   query GetUserUuidByEmail($email: String!) {
//     userUuidByEmail(email: $email)
//   }
// `

const GET_USER_IS_ACTIVE_2FA = gql`
  query GetUserIsActive2fa($userUuid: UUID) {
    isActive2fa(userUuid: $userUuid)
  }
`

export { GET_USER_DETAILS, GET_USER_IS_ACTIVE_2FA }
