import { combineReducers } from 'redux'
import notify, { TNotifyState } from './notify'

export type TNotify = {
  notify: TNotifyState
}

export default combineReducers<TNotify>({
  notify
})
