// @flow
export const USER_LIST: 'userManagement/USER_LIST' = 'userManagement/USER_LIST';
export const USER_LIST_GET_SUCCESS: 'userManagement/USER_LIST_GET_SUCCESS' =
  'userManagement/USER_LIST_GET_SUCCESS';
export const USER_LIST_GET_FAILURE: 'userManagement/USER_LIST_GET_FAILURE' =
  'userManagement/USER_LIST_GET_FAILURE';

type UserListT = {
  search?: string,
  offset: number,
};
type UserList = {
  type: typeof USER_LIST,
};

export type UserListGetSuccess = {
  type: typeof USER_LIST_GET_SUCCESS,
  payload: any,
};

type UserListGetFailure = {
  type: typeof USER_LIST_GET_FAILURE,
  payload: any,
};

type ActionType = UserList | UserListGetSuccess | UserListGetFailure;

export type State = {
  userList: Array,
  loading: boolean,
  error: ?string,
};

const initialState: State = {
  userList: [],
  loading: false,
  error: null,
};

export default function profileReducer(
  state: State = initialState,
  action: ActionType,
): State {
  switch (action.type) {
    case USER_LIST:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case USER_LIST_GET_SUCCESS:
      return {
        ...initialState,
        userList: action.payload,
        loading: false,
        error: null,
      };

    case USER_LIST_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function loadUserList(data: UserListT) {
  return {
    type: USER_LIST,
    payload: data,
  };
}
