import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';

import {
  DATA_LIST_PATIENT,
  DATA_LIST_SUCCESS,
  DATA_LIST_FAILURE,
} from '../../redux/profile/data';
import {
  SHARE_TO,
  SHARE_TO_SUCCESS,
  SHARE_TO_FAILURE,
} from '../../redux/profile/share';
import * as endpoints from '../../../constants/api';
import {
  DATA_LIST_PATIENT_MODAL,
  DATA_LIST_SUCCESS_MODAL,
} from '../../redux/profile/dataModal';

export function* dataListPatient(action) {
  const { limit, offset, id, role, current } = action.payload;

  try {
    const url =
      role !== 'doctor'
        ? `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.MY_DATA.replace(
            '{id}',
            id,
          )}/?limit=${limit}&offset=${offset}`
        : `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PATIENT_DATA.replace(
            '{id}',
            id,
          )}/?limit=${limit}&offset=${offset}`;

    const result = yield axios.get(url);
    const { data } = result;

    yield put({
      type:
        action.type === DATA_LIST_PATIENT_MODAL
          ? DATA_LIST_SUCCESS_MODAL
          : DATA_LIST_SUCCESS,
      payload: {
        data: { ...data, current, offset },
      },
    });
  } catch (error) {
    yield put({
      type: DATA_LIST_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

function* shareToSaga(action) {
  const { data } = action.payload;

  try {
    const url = `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.SHARE_TO_DATA}/`;

    yield axios.post(url, data);

    yield put({
      type: SHARE_TO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: SHARE_TO_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

export default function* watchData() {
  yield takeEvery(DATA_LIST_PATIENT, dataListPatient);
  yield takeEvery(DATA_LIST_PATIENT_MODAL, dataListPatient);
  yield takeEvery(SHARE_TO, shareToSaga);
}
