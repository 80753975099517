const myData = {
  title: 'My Data',
  emptyData: 'No datasets at the moment',
  emptySharing: 'No sharing requests at the moment',
  analysesStatus: 'Analysis state',
  tabs: {
    myData: 'My data',
    sharing: 'Sharing Requests',
    sharedFiles: 'Shared files'
  },
  table: {
    headData: {
      name: 'Data set name',
      date: 'Date',
      doctors: 'Doctors',
      description: 'Description'
    },
    headSharing: {
      name: 'Data set name',
      type: 'Type',
      patient: 'Patient',
      doctor: 'Doctor',
      description: 'Description'
    }
  },
  inputs: {
    attach: 'Attach file',
    dataSet: 'Choose dataset',
    name: 'Name',
    description: 'Description'
  },
  placeholder: {
    name: 'Enter name',
    description: 'Enter description'
  },
  buttons: {
    continueUpload: 'Continue uploading',
    cancelUpload: 'Cancel uploading',
    newData: 'New data',
    openWorkspace: 'Open workspace',
    createSet: 'Create dataset',
    choose: 'Choose',
    createData: 'Create new dataset',
    cancel: 'Cancel',
    uploadFile: 'Upload file',
    createFolder: 'Create folder'
  }
}

export default myData
