import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';
import * as endpoints from '../../../constants/api';
import {
  PROFILE_INFO,
  PROFILE_INFO_GET_SUCCESS,
  PROFILE_INFO_GET_FAILURE,
} from '../../redux/profile/detail';

import {
  ME_INFO,
  ME_INFO_SUCCESS,
  ME_INFO_FAILURE,
} from '../../redux/profile/me';

function* profileInfo(action) {
  const { id, role } = action.payload;
  try {
    const url =
      role !== 'doctor'
        ? `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PROFILE_PATIENT.replace(
            '{id}',
            id,
          )}/`
        : `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PATIENT_INFO.replace(
            '{id}',
            id,
          )}/`;
    const result = yield axios.get(url);
    const { data } = result;

    yield put({
      type: PROFILE_INFO_GET_SUCCESS,
      payload: {
        user: data,
      },
    });
  } catch (error) {
    yield put({
      type: PROFILE_INFO_GET_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}

function* meInfo(action) {
  const { id, role } = action.payload;

  try {
    const url =
      role === 'doctor'
        ? `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PROFILE_DOCTOR.replace(
            '{id}',
            id,
          )}/`
        : `${process.env.REACT_APP_API || process.env.REACT_APP_BASE_API_URL}${endpoints.PROFILE_PATIENT.replace(
            '{id}',
            id,
          )}/`;
    const result = yield axios.get(url);
    const { data } = result;

    yield put({
      type: ME_INFO_SUCCESS,
      payload: {
        me: data,
      },
    });
  } catch (error) {
    yield put({
      type: ME_INFO_FAILURE,
      payload: {
        error: error.response.data.detail,
      },
    });
  }
}
export default function* watchDetail() {
  yield takeEvery(PROFILE_INFO, profileInfo);
  yield takeEvery(ME_INFO, meInfo);
}
