import { gql } from '@apollo/client';

const GET_USER_SUBSCRIPTION = gql`
  query UserSubscriptions {
    subscriptions {
      edges {
        node {
          userUuid,
          uuid,
          eventSource {
            uuid,
            name
          },
          eventType {
            uuid,
            name
          }
          channels,
          details
        }
      }
    }
  }
`;

const GET_EVENT_SOURCES = gql`
  query EventSources {
    eventSources {
      edges {
        node {
          name,
          uuid,
          eventTypes {
            edges {
              node {
                uuid,
                name
              }
            }
          }
        }
      }
    }
  }
`;


export { GET_USER_SUBSCRIPTION, GET_EVENT_SOURCES };
