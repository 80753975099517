import { TPayloadError } from '../../../types';

export const LIST = 'listAllFolders/LIST'
export const LIST_SUCCESS = 'listAllFolders/LIST_SUCCESS'
export const LIST_FAILURE = 'listAllFolders/LIST_FAILURE'

export type TShortFolderData = {
  name: string,
  uuid: MyTypeUUID
}

export type TAListAllFolders = {
  type: typeof LIST
}

export type TAListAllFoldersSuccess = {
  type: typeof LIST_SUCCESS
  payload: TShortFolderData[]
}

export type TAListAllFoldersFailure = {
  type: typeof LIST_FAILURE
  payload: TPayloadError
}

export type TListAllFoldersActions = TAListAllFolders | TAListAllFoldersSuccess | TAListAllFoldersFailure

export type TListAllFolders = {
  uuid: string
}

export type TListAllFoldersState = {
  folderList: TShortFolderData[]
  loading: boolean
  error: string | null
}

const initialState: TListAllFoldersState = {
  folderList: [],
  loading: false,
  error: null
}

export type TListAllFoldersSaga = {
  type: typeof LIST
  payload: TListAllFolders
}

export default function folderReducer(state = initialState, action: TListAllFoldersActions): TListAllFoldersState {
  switch (action.type) {
    case LIST:
      return {
        ...initialState,
        loading: true,
        error: null
      }

    case LIST_SUCCESS:
      return {
        ...initialState,
        folderList: action.payload,
        loading: false,
        error: null
      }

    case LIST_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error
      }

    default:
      return state
  }
}

export function loadAllFolders(data: TListAllFolders): TListAllFoldersSaga {
  return {
    type: LIST,
    payload: data
  }
}
