// @flow
export const DATA_LIST_DOCTOR: 'data/DATA_LIST' = 'data/DATA_LIST';
export const DATA_LIST_GET_SUCCESS: 'data/DATA_LIST_GET_SUCCESS' =
  'data/DATA_LIST_GET_SUCCESS';
export const DATA_LIST_GET_FAILURE: 'data/DATA_LIST_GET_FAILURE' =
  'data/DATA_LIST_GET_FAILURE';

export type DataListT = {
  id: string,
};

export type List = {
  type: typeof DATA_LIST_DOCTOR,
};

export type DataGetSuccess = {
  type: typeof DATA_LIST_GET_SUCCESS,
  payload: any,
};

export type DataGetFailure = {
  type: typeof DATA_LIST_GET_FAILURE,
  payload: any,
};

export type ActionDataType = List | DataGetSuccess | DataGetFailure;

export type DataState = {
  loading: boolean,
  error: ?string,
};

const initialState: DataState = {
  loading: false,
  doctor_data: null,
  error: null,
};

export default function myDataReducer(
  state: DataState = initialState,
  action: ActionDataType,
): State {
  switch (action.type) {
    case DATA_LIST_DOCTOR:
      return {
        ...initialState,
        loading: true,
        error: null,
      };

    case DATA_LIST_GET_SUCCESS:
      return {
        ...initialState,
        doctor_data: action.payload.doctor_data,
        loading: false,
        error: null,
      };

    case DATA_LIST_GET_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export function dataListDoctor(params: DataListT) {
  return {
    type: DATA_LIST_DOCTOR,
    payload: params,
  };
}
