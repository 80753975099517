import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { handleExpiredToken } from '../store/redux/auth/login'
import store from '../store'
import { DefaultOptions } from '@apollo/client'

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('success_token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})
//default error handler
export const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message }) => {
      console.log('GQL error', extensions)
      if (extensions?.code === 'FORBIDDEN') {
        store.dispatch(handleExpiredToken())
      }
      if (extensions?.code === 'UNAUTHENTICATED') {
        store.dispatch(handleExpiredToken())
      } else {
        // store.dispatch(notifyError(message))
      }
    })
  }

  if (networkError) {
    delete response?.errors
  }
})
