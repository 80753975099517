import apolloClient from '../../../apolloClient/logger'
import { takeEvery, put } from 'redux-saga/effects'
import { GET_EVENTS as GET_EVENTS_QUERY } from '../../../graphql/queries/logger'
import { GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE } from '../../redux/logger/logger'
import { notifyError } from '../../redux/notify/notify'

function* loadEvents({ payload }) {
  try {
    const { data } = yield apolloClient.query({
      query: GET_EVENTS_QUERY,
      variables: {
        filter: payload.filter,
        ...payload.pagination
      }
    })
    yield put({
      type: GET_EVENTS_SUCCESS,
      payload: {
        events: data.events.edges,
        totalCount: data.events.totalCount
      }
    })
  } catch (error) {
    yield put({
      type: GET_EVENTS_FAILURE,
      payload: {
        error: `${error}`
      }
    })
    yield put(notifyError(`${error}`))
  }
}

export default function* watchLogger() {
  yield takeEvery(GET_EVENTS, loadEvents)
}
